import { Backdrop, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

export const LoaderDialog = (props) => {
  const { open } = props;

  return (
    <Backdrop
      sx={{ color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={() => {}}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

LoaderDialog.propTypes = {
  open: PropTypes.bool,
};
