import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

export const ProductCard = (props) => {
  const { name, isDisabled } = props;
  const theme = useTheme();

  return (
    <Stack direction={"column"} sx={{ width: "100%", px: 1 }}>
      <Stack direction={"row"} sx={{ width: "100%" }}>
        {/* <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          1.
        </Typography> */}
        <Typography
          sx={{
            color: isDisabled
              ? theme.palette.disabledText.main
              : theme.palette.primary.main,
            fontSize: 14,
            fontWeight: 500,
            ml: 1,
          }}
        >
          {name}
        </Typography>
      </Stack>
    </Stack>
  );
};

ProductCard.propTypes = {
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
};

ProductCard.defaultProps = {
  isDisabled: false,
};
