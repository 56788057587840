import {
  Stack,
  Typography,
  Divider,
  useTheme,
  TextField,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";

export const BuyerSettings = (props) => {
  const {
    categories,
    category,
    onCategoryChange,
    terms,
    onTermsChange,
    deposit,
    onDepositChange,
    purchase,
    onPurchaseChange,
    currency,
    isDeleted,
    showButton,
  } = props;
  const theme = useTheme();

  const termsMenu = [
    {
      id: "1",
      label: "Cash",
    },
    {
      id: "2",
      label: "Credit",
    },
  ];

  return (
    <Stack
      direction={"column"}
      sx={{ border: 1, borderColor: theme.palette.line.main, borderRadius: 2 }}
    >
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontSize: 18,
          fontWeight: 500,
          pl: 2,
          pr: 2,
          pt: "14px",
          pb: "14px",
        }}
      >
        Settings
      </Typography>
      <Divider />
      <Stack sx={{ p: 2, gap: 2 }}>
        <Stack direction={"row"} sx={{ gap: 2 }}>
          <TextField
            fullWidth
            select
            label="Category"
            disabled={isDeleted || !showButton}
            inputProps={{
              sx: {
                fontSize: 14,
                fontWeight: 500,
                color: theme.palette.primary.main,
              },
            }}
            required
            onChange={(e) => onCategoryChange(e.target.value)}
            value={category}
          >
            {categories.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="Payment Terms"
            disabled={isDeleted || !showButton}
            inputProps={{
              sx: {
                fontSize: 14,
                fontWeight: 500,
                color: theme.palette.primary.main,
              },
            }}
            required
            onChange={(e) => onTermsChange(e.target.value)}
            value={terms}
          >
            {termsMenu.map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack direction={"row"} sx={{ gap: 2 }}>
          <TextField
            fullWidth
            label="Deposit Amount"
            disabled={isDeleted || !showButton}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color:
                        isDeleted || !showButton
                          ? "rgb(0 0 0 / 38%)"
                          : theme.palette.primary.main,
                    }}
                  >
                    {currency}
                  </Typography>
                </InputAdornment>
              ),
            }}
            inputProps={{
              sx: {
                fontSize: 14,
                fontWeight: 500,
                color: theme.palette.primary.main,
              },
            }}
            sx={{
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            required
            onChange={(e) => {
              let inputValue = e.target.value;
              if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
                onDepositChange(inputValue);
              }
            }}
            value={deposit}
          />
          <TextField
            fullWidth
            label="Purchase Limit"
            disabled={isDeleted || !showButton}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color:
                        isDeleted || !showButton
                          ? "rgb(0 0 0 / 38%)"
                          : theme.palette.primary.main,
                    }}
                  >
                    {currency}
                  </Typography>
                </InputAdornment>
              ),
            }}
            inputProps={{
              sx: {
                fontSize: 14,
                fontWeight: 500,
                color: theme.palette.primary.main,
              },
            }}
            sx={{
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            required
            onChange={(e) => {
              let inputValue = e.target.value;
              if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
                onPurchaseChange(inputValue);
              }
            }}
            value={purchase}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

BuyerSettings.propTypes = {
  categories: PropTypes.array,
  category: PropTypes.string,
  onCategoryChange: PropTypes.func,
  terms: PropTypes.string,
  onTermsChange: PropTypes.func,
  deposit: PropTypes.number,
  onDepositChange: PropTypes.func,
  purchase: PropTypes.number,
  onPurchaseChange: PropTypes.func,
  currency: PropTypes.string,
};
