import { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  useTheme,
  TextField,
  Paper,
  Button,
  Divider,
  InputAdornment,
  Stack,
  MenuItem,
  Menu,
  Box,
  Link,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { logo } from "../../assets";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { countries } from "../../utilities/other";
import Countdown from "react-countdown";
import { useAuth, useToast } from "../../context";
import api from "../../service/api";
import urls from "../../service/urls";
import OtpInput from "react-otp-input";

export const VerifyOtp = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  // const [countryMenuAnchorEl, setCountryMenuAnchorEl] = useState(null);
  // const [countrySelectedMenu, setCountrySelectedMenu] = useState("");
  const [otp, setOtp] = useState("");
  // const [formData, setFormData] = useState({
  //   mobile: "",
  //   password: "",
  // });
  const otpstyle = {
    height: "38px",
    width: "45px",
    padding: "6px",
    fontSize: 24,
    fontWeight: 500,
    borderRadius: 4,
    border: "1px solid #E3E3E3",
    backgroundColor: theme.palette.otpcolor.main,
  };
  const [errors, setErrors] = useState({});
  // const countdownRenderer = ({ minutes, seconds, completed }) => {
  //   if (completed) {
  //     // Render a completed state
  //     return null;
  //   } else {
  //     // Render a countdown
  //     return (
  //       <Box>
  //         <Typography sx={{color:theme.palette.otpcounter.main, fontSize:"12px", fontWeight:600, mt:1}}>
  //           {minutes}:{seconds}
  //         </Typography>
  //       </Box>
  //     );
  //   }
  // };

  // const handleCountryMenuClick = (event) => {
  //   setCountryMenuAnchorEl(event.currentTarget);
  // };

  // const handleCountryMenuClose = () => {
  //   setCountryMenuAnchorEl(null);
  // };

  // const handleCountryMenuItemClick = (value) => {
  //   setCountrySelectedMenu(value);
  //   handleCountryMenuClose();
  // };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  //   // Clear validation errors when the user starts typing in a field
  //   setErrors({
  //     ...errors,
  //     [name]: "",
  //   });
  // };

  const location = useLocation();

  const { login } = useAuth();
  const { showToast } = useToast();

  const [seconds, setSeconds] = useState(120);

  const { phone_code, phone_number } = location.state;

  useEffect(() => {
    const updateTimer = () => {
      setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
    };

    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleOtp = () => {
    if (seconds === 0) {
      const params = {
        phone_code: phone_code,
        phone_number: phone_number,
      };

      api
        .post(urls.sendOtp, params)
        .then((res) => {
          showToast(res.data.message, "success");
          setSeconds(120);
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    } else {
      showToast("Please wait for " + formatTime(seconds), "error");
    }
  };

  const handleLogin = () => {
    const params = {
      phone_code: phone_code,
      phone_number: phone_number,
      otp: otp,
      user_type: 1,
    };

    api
      .post(urls.verifyOtp, params)
      .then((res) => {
        showToast(res.data.message, "success");
        login();
      })
      .catch((err) => {
        showToast(err.response?.data?.message, "error");
      });
  };

  return (
    <Fragment>
      <Grid
        container
        sx={{
          backgroundColor: theme.palette.theme.main,
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Paper
          elevation={5}
          sx={{
            width: "35%",
            borderRadius: 2,
            backgroundColor: theme.palette.white.main,
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              pt: 4,
              pb: 4,
            }}
            direction="column"
            alignItems={"center"}
          >
            <Grid item>
              <img
                src={logo}
                style={{
                  width: 76,
                  height: 35,
                }}
              />
            </Grid>

            <Divider sx={{ width: "100%", mt: 2, mb: 2 }} />
            <Grid item>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 24,
                  fontWeight: 700,
                }}
              >
                OTP Verification
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Enter the OTP you received to
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                {phone_code} {phone_number}
              </Typography>
            </Grid>

            <Grid item sx={{ mt: 3, mb: 3, alignItems: "center" }}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span style={{ margin: 5 }} />}
                renderInput={(props) => <input {...props} />}
                inputStyle={otpstyle}
                //   inputType="number"
                shouldAutoFocus
              />
            </Grid>
            <Grid item sx={{ alignItems: "center" }}>
              <Stack direction={"row"} spacing={1}>
                <Stack>
                  <Typography sx={{ color: theme.palette.otpText.main }}>
                    Didn't receive code?{" "}
                  </Typography>
                </Stack>
                <Stack>
                  <Link
                    // href="#"
                    onClick={handleOtp}
                    underline="none"
                    sx={{
                      color: theme.palette.blue.main,
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    Resend Code
                  </Link>
                </Stack>
              </Stack>
            </Grid>
            {seconds !== 0 && (
              <Grid item sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                >
                  {formatTime(seconds)}
                </Typography>
              </Grid>
            )}

            <Grid item sx={{ width: "100%", pl: 4, pr: 4, pt: 3 }}>
              <Button
                variant="contained"
                disableElevation
                disabled={otp.length !== 6}
                color="theme"
                sx={{
                  marginTop: 1,
                  width: "100%",
                  height: 45,
                  textTransform: "none",
                }}
                // onClick={() => {
                //   navigate("/dashboard");
                // }}
                onClick={handleLogin}
              >
                Verify OTP
              </Button>
            </Grid>
            <Grid item sx={{ mt: "12px" }}>
              <Typography
                onClick={() =>
                  navigate("/login", {
                    replace: true,
                  })
                }
                sx={{
                  cursor: "pointer",
                  color: theme.palette.theme.main,
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                Back to Login
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Fragment>
  );
};
