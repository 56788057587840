import React, { Fragment, useEffect, useRef } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

export default function PurchaseScanningItems(props) {
  const { upc, serialNo, onSetSerialNo, onInBoundScan } = props;

  const theme = useTheme();

  const serialRef = useRef(null);

  useEffect(() => {
    if (serialRef.current) {
      if (upc === "") {
        serialRef.current.blur();
      } else if (upc !== "" && serialNo === "") {
        serialRef.current.focus();
      }
    }
  }, [upc, serialNo]);

  return (
    <Fragment>
      <Stack sx={{ px: 2, pb: 3 }}>
        <Box
          sx={{
            borderRadius: "10px",
            backgroundColor: theme.palette.white.main,
            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.10)",
            padding: "30px",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: theme.palette.black.main,
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              Scanning Items
            </Typography>
          </Box>

          <Box sx={{ mt: 2, mb: 1 }}>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 600,
                color: theme.palette.blue.main,
                display: "inline-block",
                p: 1,
                borderRadius: "4px",
                backgroundColor: theme.palette.popbutton.main,
              }}
            >
              {upc === "" ? "Please Scan UPC No." : "Please Scan Serial No."}
            </Typography>
          </Box>

          <Stack direction="row">
            <Box
              sx={{
                p: 2,
                width: "100%",
                border: 1,
                borderColor:
                  upc === ""
                    ? theme.palette.red.main
                    : theme.palette.white.main,
                borderRadius: 2,
              }}
            >
              <TextField
                id="outlined-basic"
                disabled
                value={`${upc}`}
                label="UPC No."
                variant="outlined"
                fullWidth
                sx={{
                  input: {
                    color: theme.palette.popbuttonselected.main,
                    fontSize: "20px",
                    fontWeight: 600,
                  },
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "& input[type=number]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                p: 2,
                width: "100%",
                border: 1,
                borderColor:
                  upc !== "" && serialNo === ""
                    ? theme.palette.red.main
                    : theme.palette.white.main,
                borderRadius: 2,
              }}
            >
              {console.log("TEST", serialNo)}
              <TextField
                fullWidth
                inputRef={serialRef}
                id="outlined-basic2"
                value={serialNo?.includes("Backspace") ? "" : serialNo}
                disabled={upc === ""}
                //focused={upc !== "" && serialNo === ""}
                onChange={(e) => {
                  //console.log("Onchange", e.target.value);
                  onSetSerialNo(e.target.value);
                }}
                onKeyDown={(e) => {
                  //console.log("Return", e);
                  if (e.repeat) {
                    e.preventDefault();
                  } else if (e.key === "Enter") {
                    onInBoundScan();
                  } else if (e.key === "Backspace") {
                    onSetSerialNo((prev) => prev.slice(0, -1));
                    e.preventDefault(); // Prevents default behavior of Backspace
                  } else if (e.key === "Tab" || e.key === "Shift") {
                    e.preventDefault(); // Prevents adding Tab or Shift to the value
                  }
                }}
                label="Serial No."
                variant="outlined"
                sx={{
                  input: {
                    color: theme.palette.popbuttonselected.main,
                    fontSize: "20px",
                    fontWeight: 600,
                  },
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Fragment>
  );
}
