import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Calendar1, SecurityUser } from "iconsax-react";
import moment from "moment";

export const SellerCompanyTimeStamp = ({ date }) => {
  const theme = useTheme();

  return (
    <Stack
      direction={"column"}
      sx={{
        bgcolor: theme.palette.menuHeader.main,
        p: 2,
        borderRadius: 2,
        gap: 2,
      }}
    >
      <Stack direction={"row"} alignItems={"center"} sx={{ gap: "12px" }}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            width: 40,
            height: 40,
            bgcolor: theme.palette.lightOrange.main,
            borderRadius: 2,
          }}
        >
          <Calendar1
            size="24"
            color={theme.palette.orange.main}
            variant="Outline"
          />
        </Box>
        <Stack direction={"column"}>
          <Typography
            sx={{
              color: theme.palette.tertiary.main,
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            Joined On
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {moment(date).format("DD MMM YYYY")}
          </Typography>
        </Stack>
      </Stack>
      {/* <Divider />
      <Stack direction={"row"} alignItems={"center"} sx={{ gap: "12px" }}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            width: 40,
            height: 40,
            bgcolor: theme.palette.lightTheme.main,
            borderRadius: 2,
          }}
        >
          <SecurityUser
            size="24"
            color={theme.palette.theme.main}
            variant="Outline"
          />
        </Box>
        <Stack direction={"column"}>
          <Typography
            sx={{
              color: theme.palette.tertiary.main,
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            Approved By
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            Danial Lewis
          </Typography>
        </Stack>
      </Stack> */}
    </Stack>
  );
};
