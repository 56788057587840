import { Grid, Stack, useTheme, Button } from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import RequestDetailHeader from "../../components/headers/RequestDetailHeader";
import { Documents } from "../../components/data/Documents";
import { AboutCompany } from "../../components/data/AboutCompany";
import { BuyerAccessCard } from "../../components/cards/BuyerAccessCard";
import { BuyerSettings } from "../../components/data/BuyerSettings";
import { OrderProgressCard } from "../../components/cards/OrderProgressCard";
import { LoaderCard } from "../../components/loaders/LoaderCard";
import { useParams } from "react-router-dom";
import { useAPI } from "../../hooks/useAPI";
import { useAuth, useToast } from "../../context";
import { SellerAccessCard } from "../../components/cards/SellerAccessCard";
import { useRequestsStore } from "../../store/store";
import { userRoles } from "../../utilities";

export const RequestDetailScreen = () => {
  const theme = useTheme();
  const { uid } = useParams();
  const { api, urls, isLoading } = useAPI();
  const { logout, userRole } = useAuth();
  const { showToast } = useToast();
  const [requestDetail, setRequestDetail] = useState({
    user_type: "",
    email: "",
    phone_code: "",
    phone_number: "",
    company_name: "",
    owner_name: "",
    office_landline_code: "",
    landline: "",
    office_address: "",
    city: "",
    country: "",
    purchase_limit: null,
    deposit_amount: null,
    payment_terms: null,
    trade_category: null,
    reference_code: null,
    user_certificates: [],
    user_registration_logs: [],
    registration_status: false,
    is_guest: false,
  });
  const [tradeCategories, setTradeCategories] = useState([]);
  const [accessChanged, setAccessChanged] = useState(null);
  const [settings, setSettings] = useState(null);
  const { setShouldReload } = useRequestsStore((state) => state);
  const getRequestDetails = () => {
    api
      .get(`${urls.registrationRequestDetail}${uid}`)
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        setRequestDetail(res.data.data);
      })
      .catch(handleApiError);
  };

  const getTradeCategories = () => {
    api
      .get(urls.tradeCategoriesList)
      .then((res) => {
        setTradeCategories(res.data.data);
      })
      .catch(handleApiError);
  };

  const getSettings = () => {
    api
      .get(urls.settingsList)
      .then((res) => {
        setSettings(res.data.data.settings);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    getRequestDetails();
    getTradeCategories();
    getSettings();
  }, []);

  useEffect(() => {
    console.log(requestDetail);
  }, [requestDetail]);

  const buyerAccess = () => {
    if (
      requestDetail.registration_status === true &&
      requestDetail.is_guest === false
    ) {
      return "full_access";
    } else if (
      requestDetail.registration_status === true &&
      requestDetail.is_guest === true
    ) {
      return "view_only";
    } else if (requestDetail.registration_status === false) {
      return "deactivate";
    } else if (requestDetail.registration_status === true) {
      return "active";
    }
  };

  const sellerAccess = () => {
    if (requestDetail.registration_status === false) {
      return "deactivate";
    } else if (requestDetail.registration_status === true) {
      return "active";
    }
  };

  const handleAccessChange = (status) => {
    console.log(status);
    if (status === "full_access") {
      setRequestDetail({
        ...requestDetail,
        registration_status: true,
        is_guest: false,
      });
    } else if (status === "view_only") {
      setRequestDetail({
        ...requestDetail,
        registration_status: true,
        is_guest: true,
      });
    } else if (status === "deactivate") {
      setRequestDetail({
        ...requestDetail,
        registration_status: false,
      });
    } else if (status === "active") {
      setRequestDetail({
        ...requestDetail,
        registration_status: true,
      });
    }
    // if (requestDetail.user_type === "Seller") {
    //   handleStatusUpdate(status === "active" ? true : false);
    // }
    setAccessChanged(status);
  };

  useEffect(() => {
    setTimeout(() => {
      if (requestDetail.user_type === "Seller") {
        handleStatusUpdate(requestDetail.registration_status);
      } else if (requestDetail.user_type === "Buyer" && !isInvalid()) {
        handleSubmitClick();
      } else if (requestDetail.user_type === "Buyer" && isInvalid()) {
        showToast("Please fill all required fields in settings", "error");
      }
    }, 500);
  }, [accessChanged]);

  const handleSubmitClick = () => {
    let params = {
      purchase_limit: requestDetail.purchase_limit,
      deposit_amount: requestDetail.deposit_amount,
      payment_terms: requestDetail.payment_terms,
      trade_category: requestDetail.trade_category.uuid,
      registration_status: requestDetail.registration_status,
      is_guest: requestDetail.is_guest,
    };

    api
      .post(urls.approveRejectUser + uid, params)
      .then((res) => {
        showToast(res.data.message, "success");
        getRequestDetails();
      })
      .catch(handleApiError);
  };

  const handleStatusUpdate = (status) => {
    let params = {
      registration_status: status,
    };

    api
      .post(urls.approveRejectUser + uid, params)
      .then((res) => {
        showToast(res.data.message, "success");
        getRequestDetails();
      })
      .catch(handleApiError);
  };

  const isInvalid = () => {
    return (
      !requestDetail.trade_category?.id ||
      !requestDetail.payment_terms ||
      !requestDetail.deposit_amount ||
      !requestDetail.purchase_limit
    );
  };

  return (
    <Fragment>
      <Stack direction={"column"} sx={{ pr: 2, gap: 2 }}>
        <Stack sx={{ pb: 1 }}>
          <RequestDetailHeader title={requestDetail.company_name} />
          {isLoading && !requestDetail.user_type && <LoaderCard />}
        </Stack>
        {requestDetail.user_type && (
          <Grid container direction={"row"} spacing={2} sx={{ pr: 2, pb: 2 }}>
            <Grid item xs={9}>
              <Stack direction={"column"} sx={{ gap: 2 }}>
                <Documents
                  data={requestDetail.user_certificates}
                  userID={requestDetail.id}
                  onChange={() => {
                    getRequestDetails();
                    setShouldReload(true);
                  }}
                  userType={requestDetail.user_type}
                  showButton={userRole === userRoles.superAdmin}
                />
                <AboutCompany data={requestDetail} />
                {requestDetail.user_type === "Buyer" && (
                  <Fragment>
                    <BuyerSettings
                      categories={tradeCategories}
                      category={requestDetail.trade_category?.uuid}
                      onCategoryChange={(id) =>
                        setRequestDetail({
                          ...requestDetail,
                          trade_category: {
                            ...requestDetail.trade_category,
                            uuid: id,
                            id: id,
                          },
                        })
                      }
                      terms={requestDetail.payment_terms}
                      onTermsChange={(terms) =>
                        setRequestDetail({
                          ...requestDetail,
                          payment_terms: terms,
                        })
                      }
                      deposit={requestDetail.deposit_amount}
                      onDepositChange={(deposit) =>
                        setRequestDetail({
                          ...requestDetail,
                          deposit_amount: deposit,
                          purchase_limit: deposit * 10,
                        })
                      }
                      purchase={requestDetail.purchase_limit}
                      onPurchaseChange={(purchase) =>
                        setRequestDetail({
                          ...requestDetail,
                          purchase_limit: purchase,
                        })
                      }
                      currency={settings?.currency}
                    />
                    <Button
                      variant="contained"
                      disableElevation
                      color="theme"
                      onClick={handleSubmitClick}
                      style={{ textTransform: "none" }}
                      sx={{
                        width: 150,
                        height: 45,
                      }}
                      disabled={isInvalid()}
                    >
                      Submit
                    </Button>
                  </Fragment>
                )}
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack direction={"column"} sx={{ gap: 2 }}>
                {requestDetail.user_type === "Buyer" && (
                  <BuyerAccessCard
                    refCode={requestDetail.reference_code}
                    status={buyerAccess()}
                    onStatusChange={handleAccessChange}
                    disableRadio={isInvalid()}
                  />
                )}
                {requestDetail.user_type === "Seller" && (
                  <SellerAccessCard
                    status={sellerAccess()}
                    onStatusChange={handleAccessChange}
                    showButton={userRole === userRoles.superAdmin}
                  />
                )}
                {requestDetail.user_registration_logs.length > 0 && (
                  <OrderProgressCard
                    logs={requestDetail.user_registration_logs}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        )}
      </Stack>
    </Fragment>
  );
};
