import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Crown1 } from "iconsax-react";

export const SerialProductCard = ({ name }) => {
  const theme = useTheme();

  return (
    <Stack>
      <Box>
        <Typography
          sx={{
            color: theme.palette.badgeTheme.main,
            fontSize: 20,
            bgcolor: theme.palette.lightblue.main,
            fontWeight: 600,
            textAlign: "center",
            p: 2,
          }}
        >
          {name}
        </Typography>
      </Box>
    </Stack>
  );
};
