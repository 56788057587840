import {
  Divider,
  Stack,
  Typography,
  useTheme,
  Box,
  Button,
} from "@mui/material";
import { Fragment, useState } from "react";
import SearchField from "../search/SearchField";
import { SimpleDropDown } from "../dropdown/SimpleDropDown";
import SearchSerial from "../search/SearchSerial";
import FindSerial from "../alert/FindSerial";
import { DateDropDown } from "../dropdown/DateDropDown";
import { DatePickerPopover } from "../dropdown/DatePickerPopover";
import { dropDownArrow } from "../../assets";

export const PurchaseHeader = (props) => {
  const { onSetStatus, date, onSetDate, onSetSearch, status, search } = props;

  const theme = useTheme();
  const [searchSerial, setSearchSerial] = useState();
  const [datePickerAnchorEl, setDatePickerAnchorEl] = useState(null);

  const handleClose = () => {
    setSearchSerial(false);
  };
  const handleOpen = () => {
    setSearchSerial(true);
  };

  const filterOptions = [
    {
      id: 1,
      name: "Pending",
    },
    {
      id: 2,
      name: "Completed",
    },
  ];

  return (
    <Fragment>
      <DatePickerPopover
        open={Boolean(datePickerAnchorEl)}
        anchorEl={datePickerAnchorEl}
        onClose={() => setDatePickerAnchorEl(null)}
        dateSelected={date}
        onSetDateSelected={(newValue) => {
          onSetDate(newValue);
        }}
      />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
          Purchase
        </Typography>
        <Stack direction={"row"} alignItems={"center"}>
          {(date || status) && (
            <Typography
              sx={{
                mr: 1,
                fontSize: "14px",
                fontWeight: "500",
                color: "gray",
                cursor: "pointer",
              }}
              onClick={() => {
                onSetDate("");
                onSetStatus("");
              }}
            >
              Clear Filter
            </Typography>
          )}
          <Stack
            container
            direction={"row"}
            sx={{
              mr: "12px",
              height: 45,
              bgcolor: theme.palette.menuAlt.main,
              borderRadius: "2px",
            }}
          >
            <Stack
              width={120}
              height={"100%"}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              onClick={(e) => setDatePickerAnchorEl(e.currentTarget)}
              sx={{ cursor: "pointer", gap: 1 }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: theme.palette.primary.main,
                }}
              >
                {date !== "" ? date.format("DD MMM YYYY") : "Select Date"}
              </Typography>
              <img
                src={dropDownArrow}
                style={{
                  width: "14px",
                  height: "10px",
                }}
              />
            </Stack>
            <Divider orientation="vertical" />
            <SimpleDropDown
              placeholderText={"Filter By"}
              options={filterOptions}
              onChange={(value) => {
                onSetStatus(value);
              }}
              value={status}
            />
          </Stack>
          <Stack>
            <SearchField
              onSearchTextChange={(value) => {
                onSetSearch(value);
              }}
            />
          </Stack>
          <Divider orientation="vertical" />
          <Stack onClick={handleOpen}>
            <SearchSerial onSearchTextChange={() => {}} />
          </Stack>
          <FindSerial open={searchSerial} onClose={handleClose} />
        </Stack>
      </Stack>
    </Fragment>
  );
};
