import React, { useState, useEffect } from "react";
import axios from "axios";
import urls from "../../service/urls";
import { useToast } from "../../context";
import {
  Backdrop,
  Button,
  Dialog,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import api from "../../service/api";
import { useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

export const EditAttributes = (props) => {
  const { openEditAttribute, onClose, editId, editText, fetchData } = props;
  const [item, setItem] = useState(null);
  const { showToast } = useToast();
  const [editedItem, setEditedItem] = useState();
  const [value, setValue] = useState();
  const { id } = useParams();
  const theme = useTheme();

  const ReloadData = () => {
    fetchData();
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setEditedItem({ ...editedItem, [name]: value });
  };
  // const handleInputChange = (event) => {
  //   setEditedItem(event.target.value);
  // };

  const handleUpdate = (e) => {
    //e.preventDefault();
    api
      .put(`${urls.updateAttribute}/${editId}`, editedItem)
      .then((res) => {
        showToast(res.data.message, "success");

        onClose();
        ReloadData();
      })
      .catch((err) => {
        showToast(err.response.data.message, "error");
      });
  };

  // useEffect(() => {
  //   handleUpdate();
  // }, []);

  // if (!item) {
  //   return null;
  // }

  return (
    <Dialog
      open={openEditAttribute}
      onClose={onClose}
      //editId={editId}
      // editText={editText}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          opacity: 0.5,
        },
      }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Edit Attributes
        </Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <TextField
          sx={{
            color: theme.palette.primary.main,
            fontSize: 16,
            fontWeight: 400,
            textAlign: "center",
            pl: 4,
            pr: 4,
            pt: 2,
          }}
          type="text"
          name="name"
          defaultValue={editText}
          onChange={handleInputChange}
        />
        {/* <TextField
          type="text"
          name="description"
          value={editedItem.description}
          onChange={handleInputChange}
        /> */}

        <Stack direction={"row"} sx={{ mt: 2, ml: 4, mr: 4, gap: 2 }}>
          <Button
            onClick={() => onClose()}
            variant="contained"
            disableElevation
            color="lightTheme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
