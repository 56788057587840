import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { imageRoundSmallPlaceholder } from "../../assets";
import { Edit2 } from "iconsax-react";
import { SellerCompanyRadio } from "../radio/SellerCompanyRadio";
import PropTypes from "prop-types";

export const SellerCompany = (props) => {
  const { details, onStatusChange, showButton } = props;
  const theme = useTheme();

  const sellerAccess = () => {
    if (details.registration_status === false) {
      return "deactivate";
    } else if (details.registration_status === true) {
      return "active";
    }
  };

  return (
    <Stack
      direction={"column"}
      sx={{ bgcolor: theme.palette.menuHeader.main, p: 2, borderRadius: 2 }}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          width: "100%",
          height: 135,
          bgcolor: theme.palette.menuHeaderDark.main,
          borderRadius: 2,
        }}
      >
        {!details?.image_url ? (
          <img
            style={{ width: 50, height: 50 }}
            alt="image"
            src={imageRoundSmallPlaceholder}
          />
        ) : (
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: 10,
            }}
            alt="image"
            src={details?.image_url}
          />
        )}
      </Box>
      {/* <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"flex-end"}
        sx={{ mt: -3 }}
      >
        <IconButton
          sx={{
            mr: 2,
            bgcolor: theme.palette.white.main,
            width: 40,
            height: 40,
            ":hover": {
              bgcolor: theme.palette.white.main,
            },
          }}
        >
          <Edit2
            size="20"
            color={theme.palette.primary.main}
            variant="Outline"
          />
        </IconButton>
      </Box> */}
      <Typography
        sx={{
          mt: 1,
          color: theme.palette.primary.main,
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        Seller Access
      </Typography>
      <SellerCompanyRadio
        status={sellerAccess()}
        onStatusChange={onStatusChange}
        showButton={showButton}
        orderFound={details?.active_orders}
      />
    </Stack>
  );
};

SellerCompany.propTypes = {
  details: PropTypes.object,
  onStatusChange: PropTypes.func,
};
