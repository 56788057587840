import {
  Backdrop,
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";

export const AddCategory = (props) => {
  const theme = useTheme();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();

  const { openAddCategoryDialog, onClose, onSubmit, data, isEdit } = props;
  const [nameField, setNameField] = useState(data ? data.name : "");

  const handleCancelClick = () => {
    onClose();
  };

  const handleConfirmClick = () => {
    if (isEdit) {
      let params = { name: nameField };
      api
        .put(urls.updateTradeCategory + data.id, params)
        .then((res) => {
          onClose();
          onSubmit();
          showToast(res.data.message, "success");
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            logout();
          } else {
            showToast(err.response?.data?.message, "error");
          }
        });
    } else {
      let params = { name: nameField, status: true };
      api
        .post(urls.addTradeCategory, params)
        .then((res) => {
          onClose();
          onSubmit();
          showToast(res.data.message, "success");
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            logout();
          } else {
            showToast(err.response?.data?.message, "error");
          }
        });
    }
  };

  return (
    <Dialog
      open={openAddCategoryDialog}
      onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: "rgba(0, 0, 0, 0.2)",
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {`${isEdit ? "Edit " : "Add"} Trade Category`}
        </Typography>
        <TextField
          label="Enter Category Name"
          type="text"
          variant="outlined"
          inputProps={{
            sx: {
              fontSize: 16,
              fontWeight: 500,
              color: theme.palette.primary.main,
            },
          }}
          sx={{ mt: 4, ml: 4, mr: 4 }}
          value={nameField}
          onChange={(e) => setNameField(e.target.value)}
        />
        <Stack direction={"row"} sx={{ mt: 3, ml: 4, mr: 4, gap: 2 }}>
          <Button
            onClick={handleCancelClick}
            variant="contained"
            disableElevation
            color="lightTheme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClick}
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
            disabled={!nameField.trim() || isLoading}
          >
            {isEdit ? "Update" : "Submit"}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

AddCategory.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  isEdit: PropTypes.bool,
};
