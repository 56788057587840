import {
  Typography,
  useTheme,
  Grid,
  Button,
  Stack,
  Divider,
  alpha,
} from "@mui/material";
import { Fragment, useState } from "react";
import { IconColumnData } from "../data/IconColumnData";
import { ArrangeVerticalSquare, ArrowDown, DollarSquare } from "iconsax-react";
import SearchField from "../search/SearchField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import PropTypes from "prop-types";
import { currencyFormatter } from "../../utilities/extensions";

export const LiveOrdersHeader = (props) => {
  const { data, onSearch, isDownloading, onClickDownload, disableBtn } = props;
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadFile = (type) => {
    setAnchorEl(null);
    onClickDownload(type);
  };

  return (
    <Fragment>
      <Grid container direction={"column"}>
        <Grid item>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            Live Orders
          </Typography>
        </Grid>
        <Grid item>
          <Divider sx={{ mt: 2 }} />
        </Grid>
        <Grid item sx={{ mt: 2, mb: 2 }}>
          <Grid
            container
            direction={"row"}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Grid item>
              <Grid container direction={"row"}>
                <Grid item>
                  <IconColumnData
                    icon={
                      <ArrangeVerticalSquare
                        size="30"
                        color={theme.palette.white.main}
                        variant="Outline"
                      />
                    }
                    color={theme.palette.altThemeOne.main}
                    lightColor={theme.palette.altThemeOneLight.main}
                    header={"Total Quantity"}
                    value={data.total_quantity}
                  />
                </Grid>
                <Grid item sx={{ ml: 2 }}>
                  <IconColumnData
                    icon={
                      <DollarSquare
                        size="30"
                        color={theme.palette.white.main}
                        variant="Outline"
                      />
                    }
                    color={alpha(theme.palette.purple.main, 0.5)}
                    lightColor={theme.palette.lightpurple.main}
                    header={"Total Amount"}
                    value={currencyFormatter(data.total_amount, data.currency)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction={"row"}>
                <Grid item>
                  <SearchField onSearchTextChange={onSearch} />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    disableElevation
                    color="theme"
                    style={{ textTransform: "none" }}
                    endIcon={<ArrowDown />}
                    sx={{
                      width: 175,
                      height: 45,
                      ml: 2,
                    }}
                    onClick={handleDownloadClick}
                    disabled={isDownloading || disableBtn}
                  >
                    Download Order
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleDownloadClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem onClick={() => handleDownloadFile("pdf")}>
                      <Stack direction={"row"} spacing={1} px={"5px"}>
                        <PictureAsPdfOutlinedIcon sx={{ width: "20px" }} />
                        <Typography>PDF</Typography>
                      </Stack>
                    </MenuItem>
                    <MenuItem onClick={() => handleDownloadFile("xlsx")}>
                      <Stack direction={"row"} spacing={1} px={"5px"}>
                        <TextSnippetOutlinedIcon sx={{ width: "20px" }} />
                        <Typography>Excel</Typography>
                      </Stack>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

LiveOrdersHeader.propTypes = {
  data: PropTypes.object,
  onSearch: PropTypes.func,
  isDownloading: PropTypes.bool,
  onClickDownload: PropTypes.func,
  disableBtn: PropTypes.bool,
};
