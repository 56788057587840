import {
  Dialog,
  Divider,
  Stack,
  Typography,
  useTheme,
  Backdrop,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import { countries } from "../../utilities/other";

export const AddUser = (props) => {
  const theme = useTheme();
  const { openAddUserDialog, onClose } = props;
  const [countryMenuAnchorEl, setCountryMenuAnchorEl] = useState(null);
  const [countrySelectedMenu, setCountrySelectedMenu] = useState("");

  const dropdownMenu = [
    {
      id: "1",
      label: "Option One",
    },
    {
      id: "2",
      label: "Option Two",
    },
    {
      id: "3",
      label: "Option Three",
    },
    {
      id: "4",
      label: "Option Four",
    },
  ];

  const handleCountryMenuClick = (event) => {
    setCountryMenuAnchorEl(event.currentTarget);
  };

  const handleCountryMenuClose = () => {
    setCountryMenuAnchorEl(null);
  };

  const handleCountryMenuItemClick = (value) => {
    setCountrySelectedMenu(value);
    handleCountryMenuClose();
  };

  const handleCancelClick = () => {
    onClose();
  };

  const handleConfirmClick = () => {
    onClose();
  };

  return (
    <Dialog
      open={openAddUserDialog}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Add User
        </Typography>
        <Divider sx={{ mt: 2 }} />
        <TextField
          label="Name"
          type="text"
          variant="outlined"
          inputProps={{
            sx: {
              fontSize: 16,
              fontWeight: 500,
              color: theme.palette.primary.main,
            },
          }}
          sx={{ mt: 4, ml: 4, mr: 4 }}
        />
        <TextField
          label="Whatsapp Number"
          type="number"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Stack
                  direction={"row"}
                  onClick={handleCountryMenuClick}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: theme.palette.primary.main,
                    }}
                  >
                    +{countrySelectedMenu.phone || 91}
                  </Typography>
                  <ArrowDropDownIcon />
                  <Divider orientation="vertical" flexItem />
                </Stack>
                <CountryMenuDropDown
                  countryMenuAnchorEl={countryMenuAnchorEl}
                  handleCountryMenuClose={handleCountryMenuClose}
                  handleCountryMenuItemClick={handleCountryMenuItemClick}
                />
              </InputAdornment>
            ),
          }}
          inputProps={{
            sx: {
              fontSize: 16,
              fontWeight: 500,
              color: theme.palette.primary.main,
            },
          }}
          sx={{
            mt: 3,
            ml: 4,
            mr: 4,
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          }}
        />
        <TextField
          select
          label="Role"
          defaultValue="1"
          inputProps={{
            sx: {
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.primary.main,
            },
          }}
          sx={{ mt: 3, ml: 4, mr: 4 }}
        >
          {dropdownMenu.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Stack direction={"row"} sx={{ mt: 3, ml: 4, mr: 4, gap: 2 }}>
          <Button
            onClick={handleCancelClick}
            variant="contained"
            disableElevation
            color="lightTheme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClick}
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Add User
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

const CountryMenuDropDown = (props) => {
  const {
    countryMenuAnchorEl,
    handleCountryMenuClose,
    handleCountryMenuItemClick,
  } = props;

  return (
    <Menu
      anchorEl={countryMenuAnchorEl}
      open={Boolean(countryMenuAnchorEl)}
      onClose={handleCountryMenuClose}
    >
      {countries.map((item) => (
        <MenuItem
          key={item.phone}
          onClick={() => handleCountryMenuItemClick(item)}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
};
