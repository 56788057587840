import { Badge, Box, Stack, Typography, useTheme } from "@mui/material";
import { actionMenuHorizontal, userPlaceholder } from "../../assets";
import EditDel from "../popup/EditDel";
import EditDeleteMenu from "../menu/EditDeleteMenu";

export const SubCategoryCard = (props) => {
  const theme = useTheme();
  const { node, isHidden, products, setProducts } = props;

  //const checkIdExists = (item) => levelId.includes(item);

  return (
    <Stack
      direction={"row"}
      spacing={2}
      sx={{
        p: "12px",
        alignItems: "center",
        height: 20,
        // bgcolor: levelId.includes(node.id)
        //   ? theme.palette.menuSelDark.main
        //   : theme.palette.transparent.main,
      }}
    >
      <Typography
        sx={{
          color: isHidden
            ? theme.palette.disabledText.main
            : theme.palette.primary.main,
          fontSize: 14,
          fontWeight: 500,
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          width: "100%",
        }}
      >
        {node.name}
      </Typography>
      <Stack
        direction={"row"}
        sx={{
          width: 50,
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <Badge
          badgeContent={node.badge}
          color="badgeSecondary"
          sx={{ mr: "20px", width: "20px" }}
        />

        {/* <img src={actionMenuHorizontal} width={"18px"} height={"4px"} /> */}
        {/* <Box textAlign={"right"}>
          <EditDeleteMenu alignItems={"center"} />
        </Box> */}
      </Stack>
    </Stack>
  );
};
