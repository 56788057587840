import { Stack, Typography, useTheme } from "@mui/material";

export const NormalColumnData = (props) => {
  const { header, description } = props;
  const theme = useTheme();

  return (
    <Stack direction={"column"}>
      <Typography
        sx={{
          color: theme.palette.tertiary.main,
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {header}
      </Typography>
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        {description}
      </Typography>
    </Stack>
  );
};
