import { Box, Grid, Typography, useTheme } from "@mui/material";

export const IconColumnData = (props) => {
  const { icon, color, lightColor, header, value } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      direction={"row"}
      sx={{
        bgcolor: lightColor,
        width: 280,
        height: 80,
        borderRadius: 2,
        alignItems: "center",
        pl: 2,
        pr: 2,
      }}
    >
      <Grid item>
        <Grid
          container
          sx={{
            bgcolor: color,
            width: 50,
            height: 50,
            borderRadius: 25,
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Grid item sx={{ mt: "4px" }}>
            {icon}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ ml: "12px" }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          {header}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};
