import {
  Backdrop,
  Button,
  Dialog,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { currencies } from "../../helpers";
import PropTypes from "prop-types";
import { useState } from "react";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";

export const EditPlatformCurrency = (props) => {
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const theme = useTheme();
  const { openEditPlatformCurrencyDialog, onClose, currency, onSubmit } = props;
  const [currencyInput, setCurrencyInput] = useState(currency);

  const dropdownMenu = [
    {
      id: "1",
      label: "Option One",
    },
    {
      id: "2",
      label: "Option Two",
    },
    {
      id: "3",
      label: "Option Three",
    },
    {
      id: "4",
      label: "Option Four",
    },
  ];

  const handleCancelClick = () => {
    onClose();
  };

  const handleConfirmClick = () => {
    let params = { key: "currency", value: currencyInput };
    api
      .post(urls.editSettings, params)
      .then((res) => {
        showToast(res.data.message, "success");
        onClose();
        onSubmit();
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  return (
    <Dialog
      open={openEditPlatformCurrencyDialog}
      onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: "rgba(0, 0, 0, 0.2)",
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Edit Platform Currency
        </Typography>
        <TextField
          // select
          label="Enter Currency"
          // defaultValue="1"
          inputProps={{
            sx: {
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.primary.main,
            },
          }}
          sx={{ mt: 4, ml: 4, mr: 4 }}
          // value={currency && currencies.find((c) => c.symbol === currency).code}
          value={currencyInput}
          onChange={(e) => setCurrencyInput(e.target.value)}
        >
          {/* {currencies.map((option) => (
            <MenuItem key={option.code} value={option.code}>
              {`${option.code} - ${option.symbol}`}
            </MenuItem>
          ))} */}
        </TextField>
        <Stack direction={"row"} sx={{ mt: 3, ml: 4, mr: 4, gap: 2 }}>
          <Button
            onClick={handleCancelClick}
            variant="contained"
            disableElevation
            color="lightTheme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClick}
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
            disabled={!currencyInput}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

EditPlatformCurrency.propTypes = {
  openEditPlatformCurrencyDialog: PropTypes.bool,
  onClose: PropTypes.func,
  currency: PropTypes.string,
  onSubmit: PropTypes.func,
};
