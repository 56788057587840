import { Box, Stack, Typography } from "@mui/material";

export const IconColumnDataSmall = (props) => {
  const { icon, bgColor, headerColor, header, valueColor, value } = props;

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: bgColor,
        borderRadius: 2,
        pl: 2,
      }}
    >
      <Box sx={{ height: 40 }}>{icon}</Box>
      <Stack direction={"column"} sx={{ ml: "12px" }}>
        <Typography sx={{ color: headerColor, fontSize: 15, fontWeight: 400 }}>
          {header}
        </Typography>
        <Typography sx={{ color: valueColor, fontSize: 16, fontWeight: 600 }}>
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};
