import { useTheme } from "@emotion/react";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAPI } from "../../hooks/useAPI";
import { useParams } from "react-router-dom";
import { useToast, useAuth } from "../../context";

export default function AddDiscount(props) {
  const { openAddDiscountDialog, onClose, onSubmit, type, currency } = props;
  const theme = useTheme();
  const { api, urls } = useAPI();
  const { id } = useParams();
  const { showToast } = useToast();
  const { logout } = useAuth();
  const [charge, setCharge] = useState({
    order_id: id,
    amount: 0,
    description: "",
    log: "Added rebate",
  });

  const handleCancelClick = () => {
    onClose();
  };
  const handleConfirmClick = () => {
    let url = "";
    let params = { ...charge };
    if (type === "purchase") {
      url = urls.purchaseAddChargeDiscount;
    } else {
      url = urls.sellAddChargeDiscount;
      params.amount = -params.amount;
    }
    api
      .post(url, params)
      .then((res) => {
        onClose();
        onSubmit();
        showToast(res.data.message, "success");
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  return (
    <Dialog
      open={openAddDiscountDialog}
      onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: theme.palette.black.main,
      //     opacity: 0.7,
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Add Discount
        </Typography>
        <Divider sx={{ mt: 2 }} />

        <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
          <TextField
            fullWidth
            id="outlined-basic"
            // type="number"
            label="Discount Price"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{currency}</InputAdornment>
              ),
            }}
            sx={{
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            value={charge.amount}
            onChange={(e) => {
              let inputValue = e.target.value;
              if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
                setCharge((prev) => ({
                  ...prev,
                  amount: +e.target.value,
                }));
              }
            }}
          />
        </Box>
        <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Discount Price Name"
            variant="outlined"
            value={charge.description}
            onChange={(e) => {
              setCharge((prev) => ({
                ...prev,
                description: e.target.value,
              }));
            }}
          />
        </Box>
        <Stack sx={{ mt: 4, ml: 4, mr: 4 }}>
          <Grid container>
            <Grid item xs>
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={handleCancelClick}
                  variant="contained"
                  disableElevation
                  color="lightTheme"
                  sx={{
                    width: "100%",
                    height: 45,
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmClick}
                  variant="contained"
                  disableElevation
                  color="theme"
                  sx={{
                    width: "100%",
                    height: 45,
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                  disabled={
                    charge.amount === "" ||
                    charge.description === "" ||
                    +charge.amount === 0
                  }
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Dialog>
  );
}

AddDiscount.propTypes = {
  openAddDiscountDialog: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  type: PropTypes.string,
  currency: PropTypes.string,
};
