import React, { Fragment, useState } from "react";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { TickCircle } from "iconsax-react";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import SerialNumberView from "../alert/SerialNumberView";
import { PaginationTable } from "../table/PaginationTable";
import { useParams } from "react-router-dom";
import { Img } from "react-image";

export default function PurchaseOrderItems(props) {
  const {
    data,
    paginationData,
    itemsPerPage,
    setItemsPerPage,
    setPageNumber,
    type,
  } = props;

  const theme = useTheme();
  const { id } = useParams();
  const [serialDialog, setSerialDialog] = useState(false);
  const [productID, setProductID] = useState("");
  const [productName, setProductName] = useState("");
  const [itemID, setItemID] = useState("");

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
  };

  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
  };

  return (
    <Fragment>
      <Stack sx={{ px: 2 }}>
        <Box
          sx={{
            borderRadius: "10px",
            backgroundColor: theme.palette.white.main,
            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.10)",
            padding: "30px",
          }}
        >
          <Box>
            <Typography
              sx={{
                paddingBottom: "20px",
                color: theme.palette.black.main,
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              {data?.order_id}
            </Typography>
          </Box>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow sx={{ height: 70 }}>
                  <TableCell sx={headerStyle}>No.</TableCell>
                  <TableCell sx={headerStyle}>Product</TableCell>
                  <TableCell align="right" sx={headerStyle}>
                    Quantity
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.map((item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={itemStyle}>
                      <Typography sx={itemStyle}>
                        {itemsPerPage * (paginationData.page - 1) + index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ verticalAlign: "center", textAlign: "left" }}
                    >
                      <Stack direction={"row"} sx={{ gap: 1 }}>
                        <Stack direction={"column"}>
                          <Typography
                            sx={{
                              color: theme.palette.primary.main,
                              fontSize: 16,
                              fontWeight: 500,
                            }}
                          >
                            {item.name}
                          </Typography>
                          {/* <Typography
                            sx={{
                              color: theme.palette.tertiary.main,
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          >
                            UPC:
                          </Typography> */}
                        </Stack>
                        {item.attributes?.map(
                          (attrItem) =>
                            attrItem.image !== null && (
                              <Img
                                src={attrItem.image}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  borderWidth: "0.5px",
                                  borderStyle: "solid",
                                  borderColor: theme.palette.tertiary.main,
                                }}
                              />
                            )
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack
                        sx={{ float: "right" }}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                      >
                        {item.scanned_product_count === item.quantity && (
                          <TickCircle
                            size="25"
                            color={theme.palette.tickGreen.main}
                            variant="Bold"
                          />
                        )}
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "24px",
                            ml: "4px",
                          }}
                        >
                          {`${item.scanned_product_count}/${item.quantity}`}
                        </Typography>
                        {item.scanned_product_count > 0 && (
                          <Box>
                            <Tooltip
                              title={
                                <Typography
                                  sx={{
                                    color: theme.palette.primary.main,
                                    fontWeight: 500,
                                    fontSize: 12,
                                    cursor: "pointer",
                                  }}
                                >
                                  View Serial No.
                                </Typography>
                              }
                              color={theme.palette.primary.main}
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: theme.palette.lightTheme.main,
                                    "& .MuiTooltip-arrow": {
                                      color: theme.palette.lightTheme.main,
                                    },
                                  },
                                },
                              }}
                            >
                              <ArrowDropDownIcon
                                onClick={() => {
                                  setSerialDialog(true);
                                  setProductID(item.product_id);
                                  setProductName(item.name);
                                  setItemID(item.id);
                                }}
                                sx={{ ml: "2px", mr: "2px", cursor: "pointer" }}
                              />
                            </Tooltip>
                          </Box>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationTable
            page={paginationData.page - 1}
            onSetPage={(page) => setPageNumber(page + 1)} //page start with zero
            rowsPerPage={itemsPerPage}
            onSetRowsPerPage={setItemsPerPage}
            totalCount={paginationData.total_count}
          />
        </Box>
      </Stack>
      {serialDialog && (
        <SerialNumberView
          onClose={() => {
            setSerialDialog(false);
            setProductID("");
            setProductName("");
            setItemID("");
          }}
          orderID={id}
          productID={productID}
          productName={productName}
          openserial={serialDialog}
          type={type}
          itemID={itemID}
        />
      )}
    </Fragment>
  );
}
