import { useTheme } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";

export default function PurchaseSerialItem({ serial, product }) {
  const theme = useTheme();
  const headerstyle = {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.badgeliveOrder.main,
  };
  const item = {
    fontSize: 20,
    fontWeight: 500,
  };
  return (
    <Fragment>
      <Stack sx={{ padding: "20px 0px" }}>
        <Typography sx={headerstyle}>{serial}</Typography>
        <Typography sx={item}>{product}</Typography>
      </Stack>
    </Fragment>
  );
}
