import {
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { dropDownArrow } from "../../assets";

export const MenuMatchDropDown = (props) => {
  const { reload, placeholderText, dropdownMenu, onSelected } = props;

  const theme = useTheme();

  const [simpleMenuAnchorEl, setSimpleMenuAnchorEl] = useState(null);
  const [simpleSelectedMenu, setSimpleSelectedMenu] = useState("");

  const handleSimpleMenuClick = (event) => {
    setSimpleMenuAnchorEl(event.currentTarget);
  };

  const handleSimpleMenuClose = () => {
    setSimpleMenuAnchorEl(null);
  };

  const handleSimpleMenuItemClick = (value) => {
    onSelected(value);
    setSimpleSelectedMenu(value);
    handleSimpleMenuClose();
  };

  useEffect(() => {
    setSimpleSelectedMenu("");
  }, [reload]);

  return (
    <Box>
      <Button
        variant="contained"
        disableElevation
        color="menuBtn"
        onClick={handleSimpleMenuClick}
        sx={{ width: "100%", height: 45, textTransform: "none" }}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography sx={{ fontSize: 16, fontWeight: 500, color: theme }}>
            {simpleSelectedMenu || placeholderText}
          </Typography>
          <img
            src={dropDownArrow}
            style={{
              width: "14px",
              height: "10px",
            }}
          />
        </Stack>
      </Button>
      <SimpleMenuDropDown
        simpleMenuAnchorEl={simpleMenuAnchorEl}
        handleSimpleMenuClose={handleSimpleMenuClose}
        handleSimpleMenuItemClick={handleSimpleMenuItemClick}
        dropdownMenu={dropdownMenu}
      />
    </Box>
  );
};

const SimpleMenuDropDown = (props) => {
  const {
    simpleMenuAnchorEl,
    handleSimpleMenuClose,
    handleSimpleMenuItemClick,
    dropdownMenu,
  } = props;

  return (
    <Menu
      anchorEl={simpleMenuAnchorEl}
      open={Boolean(simpleMenuAnchorEl)}
      onClose={handleSimpleMenuClose}
      PaperProps={{ style: { width: "20%" } }}
    >
      {dropdownMenu?.map((item) => (
        <MenuItem
          key={item.id}
          onClick={() => handleSimpleMenuItemClick(item.title)}
        >
          {item.title}
        </MenuItem>
      ))}
    </Menu>
  );
};
