import { Fragment, useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { sampleBuyersItems } from "../../utilities";
import { useTheme } from "@emotion/react";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import PropTypes from "prop-types";
import { LoaderCard } from "../loaders/LoaderCard";
import { EmptyCard } from "../emptyCard/EmptyCard";
import { currencyFormatter } from "../../utilities/extensions";
import { useUsersStore } from "../../store/store";

const useStyles = makeStyles(() => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  border: {
    borderBottom: "0px !important",
  },
  headertable: {
    fontWeight: 500,
    color: "#000",
  },
  tableitem: {
    color: "#1F1F1F",
  },
}));

export const BuyersTable = (props) => {
  const { onCountChange, catID, searchInput } = props;
  const classes = useStyles();
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const theme = useTheme();
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  // const [buyers, setBuyers] = useState([]);
  // const [totalCount, setTotalCount] = useState(0);
  // const [currency, setCurrency] = useState("");
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    buyers,
    setBuyers,
    totalCount,
    setTotalCount,
    currency,
    setCurrency,
    isFirstLoad,
    setIsFirstLoad,
    shouldReload,
    setShouldReload,
  } = useUsersStore((state) => state);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setShouldReload(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setShouldReload(true);
  };

  const handleClick = (id, name, code) => {
    navigate(`/users/buyer_detail/${id}`, { state: { name, code } });
  };

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
    color: theme.palette.black.main,
  };

  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
  };

  const dynamicItemStyle = (isDeleted) => {
    return {
      fontWeight: 500,
      fontSize: "16px",
      color: isDeleted
        ? theme.palette.disabledText.main
        : theme.palette.black.main,
    };
  };

  const getBuyers = () => {
    let params = {
      page: page + 1,
      limit: rowsPerPage,
      search_key: searchInput,
    };
    if (catID) {
      params.filter_key = catID;
    }
    api
      .get(urls.buyersList, { params: params })
      .then((res) => {
        setBuyers(res.data.data?.buyers ?? []);
        setTotalCount(res.data.data?.pagination?.total_buyers ?? 0);
        onCountChange({
          buyers: res.data.data?.pagination?.total_buyers ?? 0,
          sellers: res.data.data?.pagination?.total_sellers ?? 0,
          admins: res.data.data?.pagination?.total_admins ?? 0,
        });
        setCurrency(res.data.data?.currency ?? "");
        setIsFirstLoad(false);
        setShouldReload(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        }
        showToast(err.response?.data?.message, "error");
      });
  };

  useEffect(() => {
    if (isFirstLoad) {
      getBuyers();
    }
  }, []);

  useEffect(() => {
    if (shouldReload) {
      getBuyers();
    }
  }, [shouldReload]);

  const getSLNo = (i) => {
    return i + 1 + page * rowsPerPage;
  };

  return (
    <Fragment>
      {isLoading && buyers.length === 0 && <LoaderCard />}
      {!isLoading && buyers.length === 0 && <EmptyCard />}
      {buyers.length > 0 && (
        <TableContainer component={Box}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell sx={headerStyle}>No.</TableCell>
                <TableCell sx={headerStyle}>Company</TableCell>
                <TableCell sx={headerStyle}>Buyer ID</TableCell>
                <TableCell sx={headerStyle}>Category</TableCell>
                <TableCell sx={headerStyle}>Total Orders</TableCell>
                <TableCell sx={headerStyle}>Total Sales</TableCell>
                <TableCell sx={headerStyle} align="right">
                  Closing balance
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buyers.map((item, i) => (
                <TableRow
                  key={i}
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    handleClick(item.id, item.company_name, item.buyer_id)
                  }
                >
                  <TableCell sx={itemStyle}>{getSLNo(i)}</TableCell>
                  <TableCell sx={dynamicItemStyle(item.user_deleted)}>
                    {item.company_name}
                  </TableCell>
                  <TableCell sx={dynamicItemStyle(item.user_deleted)}>
                    {item.buyer_id}
                  </TableCell>
                  <TableCell
                    sx={dynamicItemStyle(item.user_deleted)}
                    component="th"
                    scope="row"
                  >
                    {item.category_name}
                  </TableCell>
                  <TableCell sx={dynamicItemStyle(item.user_deleted)}>
                    {item.total_orders_count}
                  </TableCell>
                  <TableCell sx={dynamicItemStyle(item.user_deleted)}>
                    {currencyFormatter(item.total_price_sum, currency)}
                  </TableCell>
                  <TableCell
                    sx={dynamicItemStyle(item.user_deleted)}
                    align="right"
                  >
                    {currencyFormatter(item.closing_balance, currency)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {buyers.length > 0 && (
        <TablePagination
          component="div"
          align="right"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ mt: 1, mb: 1 }}
        />
      )}
    </Fragment>
  );
};

BuyersTable.propTypes = {
  onCountChange: PropTypes.func,
  catID: PropTypes.string,
  searchInput: PropTypes.string,
};
