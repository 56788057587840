import {
  Backdrop,
  Box,
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { CoverUploader } from "../uploaders/CoverUploader";
import { useEffect, useState } from "react";
import api from "../../service/api";
import urls from "../../service/urls";
import { useToast } from "../../context";
import { isBase64Image, convertBse64ToFormData } from "../../helpers";
import PropTypes from "prop-types";

export const EditItem = (props) => {
  const theme = useTheme();
  const { openAddItemDialog, onClose, fetchData, attrID, data } = props;
  const handleAttributeitem = (e) => {
    // setAttributeItem(e.target.value);
    setAttributeItem((prev) => ({
      ...prev,
      name: e.target.value,
      description: e.target.value,
      errors: { name: false },
    }));
  };
  const handleCancelClick = () => {
    onClose();
  };
  // const ReloadData = () => {
  //   fetchData();
  // };
  const [attributeItem, setAttributeItem] = useState({
    // id: null,
    name: data.name,
    description: "",
    status: true,
    image: data.image,
    errors: { name: false },
  });

  // const handleConfirmClick = () => {
  //   onClose();
  // };
  const { showToast } = useToast();
  const handleConfirmClick = () => {
    let invalid = false;
    if (
      // !attributeItem.id &&
      !attributeItem.name &&
      !attributeItem.description
    ) {
      setAttributeItem((prev) => {
        const curr = { ...prev };
        prev.errors = true;
        return curr;
      });
      invalid = true;
    }

    if (!invalid) {
      if (isBase64Image(attributeItem.image)) {
        let blob = convertBse64ToFormData(attributeItem.image);
        let form = new FormData();
        let timestamp = new Date().getTime();
        form.append("file", blob, `brand-logo-${timestamp}.png`);
        api
          .post(urls.fileUpload, form)
          .then((res) => {
            updateItem(res.data.data.file_url);
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        updateItem();
      }
    }
  };

  const updateItem = (url) => {
    let params = { ...attributeItem };
    delete params.errors;
    if (url) {
      params.image = url;
    }
    api
      .put(urls.updateAttributeItem + data.id, params)
      .then((res) => {
        showToast(res.data.message, "success");
        onClose();
        fetchData();
      })
      .catch((err) => {
        console.error(err);
        showToast(err.response?.data?.message, "error");
      });
  };

  useEffect(() => {
    console.log(attributeItem);
  }, [attributeItem]);

  return (
    <Dialog
      open={openAddItemDialog}
      onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: "rgba(0, 0, 0, 0.2)",
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Edit Item
        </Typography>
        <Box sx={{ mt: 3, ml: 4, mr: 4 }}>
          <CoverUploader
            onImageChange={(image) => {
              setAttributeItem((prev) => ({
                ...prev,
                image: image,
              }));
            }}
            image={attributeItem.image}
          />
        </Box>
        <TextField
          label="Attribute Name"
          onChange={handleAttributeitem}
          variant="outlined"
          error={attributeItem.errors.name}
          defaultValue={attributeItem.name}
          inputProps={{
            sx: {
              fontSize: 16,
              fontWeight: 500,
              color: theme.palette.primary.main,
            },
          }}
          sx={{ mt: 4, ml: 4, mr: 4 }}
          helperText={attributeItem.errors.name ? "text is required" : null}
          required
        />
        <Stack direction={"row"} sx={{ mt: 3, ml: 4, mr: 4, gap: 2 }}>
          <Button
            onClick={handleCancelClick}
            variant="contained"
            disableElevation
            color="lightTheme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClick}
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
            disabled={!attributeItem.name.trim()}
          >
            Update
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

EditItem.propTypes = {
  openAddItemDialog: PropTypes.bool,
  onClose: PropTypes.func,
  attrID: PropTypes.number,
  fetchData: PropTypes.func,
  data: PropTypes.object,
};
