import { useTheme } from "@emotion/react";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import BasicDatePicker from "../uploaders/BasicDatePicker";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useAPI } from "../../hooks/useAPI";
import { useToast } from "../../context";

export default function ApproveDocument(props) {
  const { openApproveDialog, onClose, certID, userID, onSubmit } = props;
  const theme = useTheme();
  const { api, urls, isLoading } = useAPI();
  const { showToast } = useToast();
  const [expDate, setExpDate] = useState(null);

  const handleCancelClick = () => {
    onClose();
  };
  const handleConfirmClick = () => {
    let params = {
      user_id: userID,
      expiry_date: expDate,
      approval_status: 3,
    };
    api
      .post(urls.approveRejectCertfcate + certID, params)
      .then((res) => {
        showToast(res.data.message, "success");
        onSubmit();
        onClose();
      })
      .catch((err) => {
        showToast(err.response?.data?.message, "error");
      });
  };

  return (
    <Dialog
      maxWidth="md"
      open={openApproveDialog}
      onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: theme.palette.black.main,
      //     opacity: 0.7,
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 400, pt: 3, pb: 4 }}>
        <Typography
          fullWidth
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          Approve Document
        </Typography>
        <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
          <BasicDatePicker
            label="Select Expiry Date"
            onChange={(d) => setExpDate(dayjs(d).format("YYYY/MM/DD"))}
          />
        </Box>
        <Stack sx={{ mt: 4, ml: 4, mr: 4 }}>
          <Grid container>
            <Grid item xs>
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={handleCancelClick}
                  variant="contained"
                  disableElevation
                  color="lightTheme"
                  sx={{
                    width: "100%",
                    height: 45,
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmClick}
                  variant="contained"
                  disableElevation
                  color="approveDoc"
                  sx={{
                    width: "100%",
                    height: 45,
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                  disabled={dayjs(expDate).isValid() ? false : true}
                >
                  Approve
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Dialog>
  );
}

ApproveDocument.propTypes = {
  openApproveDialog: PropTypes.bool,
  onClose: PropTypes.func,
  certID: PropTypes.number,
  userID: PropTypes.number,
  onSubmit: PropTypes.func,
};
