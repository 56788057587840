import { Fragment, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  useTheme,
  TablePagination,
} from "@mui/material";

import { StatusLabel } from "../../styles/status/StatusLabel";
import { makeStyles } from "@mui/styles";
import { samplePurchaseItems, samplePurchaseSerial } from "../../utilities";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles(() => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  table: {
    minWidth: 650,
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    padding: "16px",
    justifyContent: "left",
  },
  border: {
    borderBottom: "0px !important",
  },
}));

export const PurchaseSerialTable = (props) => {
  const { data } = props;
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const navigateClick = (event) => {
    navigate("/purchase_summary");
  };

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
  };
  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
  };
  const tableborder = {
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    justifyContent: "left",
    padding: "20px",
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Fragment>
      <Box sx={tableborder}>
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow sx={{ height: 70 }}>
                <TableCell sx={headerStyle}>No.</TableCell>
                <TableCell sx={headerStyle}>Date & Time</TableCell>
                {/* <TableCell sx={headerStyle}>Order ID</TableCell> */}
                <TableCell sx={headerStyle}>Seller</TableCell>
                <TableCell sx={headerStyle}>Seller Order ID</TableCell>
                <TableCell sx={headerStyle}>Buyer </TableCell>
                <TableCell sx={headerStyle}>Buyer Order ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, i) => (
                <TableRow
                  key={item.id}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={itemStyle}>{i + 1}</TableCell>
                  <TableCell sx={itemStyle}>
                    {moment(item.scanned_date).format("DD MMM YYYY, hh:mm a")}
                  </TableCell>
                  {/* <TableCell sx={itemStyle}>{item.order_id ?? "--"}</TableCell> */}
                  <TableCell sx={itemStyle}>{item.seller}</TableCell>
                  <TableCell sx={itemStyle}>{item.seller_order_id}</TableCell>
                  <TableCell sx={itemStyle}>{item.buyer}</TableCell>
                  <TableCell sx={itemStyle}>{item.buyer_order_id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 15, 25]}
          component="div"
          align="right"
          count={100}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ mt: "12px", mb: "-4px" }}
        /> */}
      </Box>
    </Fragment>
  );
};
