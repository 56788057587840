import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import { sampleStockItems } from "../../utilities";
import { PriceAlert } from "../alert/PriceAlert";
import { useState, Fragment } from "react";
import { imageRoundSmallPlaceholder } from "../../assets";
import { currencyFormatter } from "../../utilities/extensions";
import { Img } from "react-image";

const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let j = 0; j < 3; j++) {
    const value = (hash >> (j * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};

export const StockTable = (props) => {
  const { data, requestData, paginationData, setRequestData, currency } = props;
  const theme = useTheme();
  const [selectedPriceSlab, setSelectPriceSlab] = useState(null);

  const headerStyle = {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
  };

  const handleOpenPriceDialog = (product, slab) => {
    const data = {
      name: product.product_details.name,
      slabText: slab.display_name,
      productID: product.product_details.id,
      slabId: slab.uuid,
    };
    setSelectPriceSlab(data);
  };

  const handleClosePriceDialog = () => {
    setSelectPriceSlab(null);
  };

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    setRequestData((prev) => ({ ...prev, currentPage: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    let count = parseInt(event.target.value, 10);

    // setPage(0);
    setRequestData((prev) => ({ ...prev, itemsPerPage: count }));
  };

  return (
    <Fragment>
      <TableContainer
        component={Box}
        border={1}
        borderColor={theme.palette.line.main}
        borderRadius={2}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={headerStyle}>
                No.
              </TableCell>
              <TableCell sx={headerStyle}>Product</TableCell>
              <TableCell sx={headerStyle}>Quantity</TableCell>
              <TableCell sx={headerStyle}>Best Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow
                key={item.product_details?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  style={{ verticalAlign: "top", textAlign: "center" }}
                  sx={{ width: 40 }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {requestData.itemsPerPage *
                      (paginationData.currentPage - 1) +
                      index +
                      1}
                  </Typography>
                </TableCell>
                <TableCell style={{ verticalAlign: "top", textAlign: "left" }}>
                  <Stack direction={"column"}>
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      {item.product_details?.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.tertiary.main,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      {`UPC: ${item.product_details?.upc}, Model ID: ${item.product_details?.model_no}`}
                    </Typography>
                    <Stack spacing={1} direction={"row"}>
                      {item.product_details?.attribute?.map(
                        (attribute, attIndex) => {
                          return (
                            <Img
                              key={attIndex}
                              src={
                                attribute.attribute_descriptions?.image ?? ""
                              }
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                borderWidth: "0.5px",
                                borderStyle: "solid",
                                borderColor: theme.palette.tertiary.main,
                              }}
                            />
                          );
                        }
                      )}
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top", textAlign: "left" }}
                  sx={{ width: 150 }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {item.stock}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top", textAlign: "left" }}
                  sx={{ width: 450 }}
                >
                  <Stack direction={"column"}>
                    {item.product_details?.quantity_slabs?.map(
                      (slab, pIndex) => (
                        <Stack
                          direction={"row"}
                          display={"flex"}
                          alignItems={"center"}
                          key={pIndex}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              mt: pIndex === 0 ? "0px" : "4px",
                              mb: "4px",
                              width: "40px",
                              height: "40px",
                              bgcolor: stringToColor(`${slab.display_name}+`),
                              borderRadius: 2,
                              fontSize: 14,
                              fontWeight: 500,
                            }}
                          >
                            <Typography
                              sx={{
                                color: theme.palette.white.main,
                                fontSize: 12,
                                fontWeight: 500,
                              }}
                            >
                              {slab.display_name}+
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              ml: "14px",
                              mr: "4px",
                              color: theme.palette.primary.main,
                              fontSize: 16,
                              fontWeight: 500,
                            }}
                          >
                            {currencyFormatter(
                              slab.product_slabs?.unit_price,
                              currency
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              color: theme.palette.tertiary.main,
                              fontSize: 16,
                              fontWeight: 500,
                            }}
                          >
                            ({slab.seller_stock}
                            {slab.seller_stock === 1 ? " Item" : " Items"})
                          </Typography>
                          {slab.seller_stock > 0 && (
                            <Button
                              onClick={() => handleOpenPriceDialog(item, slab)}
                              variant="text"
                              size="small"
                              style={{ textTransform: "none" }}
                              sx={{
                                ml: "4px",
                                color: theme.palette.theme.main,
                                fontSize: 16,
                                fontWeight: 500,
                              }}
                            >
                              View
                            </Button>
                          )}
                        </Stack>
                      )
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedPriceSlab !== null && (
        <PriceAlert
          openPriceDialog={selectedPriceSlab !== null}
          onClose={handleClosePriceDialog}
          slab={selectedPriceSlab}
        />
      )}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        align="right"
        count={paginationData.totalProducts}
        rowsPerPage={requestData.itemsPerPage}
        page={paginationData.currentPage - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: "12px", mb: "-4px" }}
      />
    </Fragment>
  );
};
