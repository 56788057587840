import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  draft: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.common.draftBg,
    color: "white",
    borderRadius: theme.spacing(2),
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 60,
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  pending: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.badgePending.main,
    color: "white",
    borderRadius: theme.spacing(2),
    paddingTop: "5px",
    paddingBottom: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "auto",
    width: 80,
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  success: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.badgeSuccess.main,
    color: "white",
    borderRadius: theme.spacing(2),
    paddingTop: "5px",
    paddingBottom: "5px",
    // height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  rejected: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.smallIconTextOne.main,
    color: theme.palette.white.main,
    paddingTop: "5px",
    paddingBottom: "5px",
    borderRadius: theme.spacing(2),
    // height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  completed: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.badgeCompleted.main,
    color: "white",
    borderRadius: theme.spacing(2),
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 90,
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  live: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.badgeliveOrder.main,
    color: "white",
    borderRadius: theme.spacing(2),
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  order: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.badgeSuccess.main,
    color: "white",
    borderRadius: theme.spacing(2),
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 90,
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  penalty: {
    color: theme.palette.amountPenalty.main,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  paid: {
    color: theme.palette.amountPaid.main,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500,
    },
  },
}));

export function StatusLabel(props) {
  const classes = useStyles();
  const { children, type } = props;

  return <Typography className={classes[type]}>{children}</Typography>;
}

StatusLabel.propTypes = {
  type: PropTypes.string,
};
