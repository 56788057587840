import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { documentPlaceholder } from "../../assets";
import PropTypes from "prop-types";
import RejectDocuments from "../alert/RejectDocument";
import { useState } from "react";
import ApproveDocument from "../alert/ApproveDocument";
import moment from "moment";
import EditDocument from "../alert/EditDocument";
import { useModal } from "../../hooks";

export const DocumentCard = (props) => {
  const [openRejectDialog, setOpenRejectDialog] = useState();
  const [openApproveDialog, setOpenApproveDialog] = useState();
  const handleOpenReject = () => {
    setOpenRejectDialog(true);
  };
  const handleCloseReject = () => {
    setOpenRejectDialog(false);
  };

  const handleOpenApprove = () => {
    setOpenApproveDialog(true);
  };
  const handleCloseApprove = () => {
    setOpenApproveDialog(false);
  };
  const {
    document,
    showDivider,
    userID,
    onChange,
    userType,
    isDeleted,
    showButton,
  } = props;
  const theme = useTheme();
  const [isOpenEditModal, openEditModal, closeEditModal] = useModal();

  const getHeaderText = (key) => {
    const items = {
      emirates_id: "Emirates ID",
      trade_license:
        userType === "Buyer" ? "Business Certificate" : "Trade License",
      passport: "Passport",
      trn_certificate: "TRN Certificate",
      business_certificate: "Business Certificate",
      owners_passport: "Owners Passport",
    };

    if (items.hasOwnProperty(key)) {
      return items[key];
    } else {
      return key; // Or any other default value or behavior
    }
  };

  const openDocument = () => {
    if (document.url) {
      window.open(document.url, "_blank");
    }
  };

  return (
    <Stack direction={"column"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction={"row"}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              bgcolor: theme.palette.lightTheme.main,
              width: 45,
              height: 50,
              borderRadius: 2,
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              onClick={openDocument}
              src={documentPlaceholder}
              width={20}
              height={20}
            />
          </Box>
          <Stack
            direction={"column"}
            sx={{ ml: "12px" }}
            justifyContent={"center"}
          >
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {getHeaderText(document.certificate_type)}
            </Typography>
            {(document.certificate_status_id === 2 ||
              document.certificate_status_id === 3) && (
              <Typography
                sx={{
                  color:
                    document.certificate_status_id === 2
                      ? theme.palette.error.main
                      : theme.palette.tertiary.main,
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {document.certificate_status_id === 3
                  ? `Expires on ${moment(document.expiry_date).format(
                      "DD/MM/YYYY"
                    )}`
                  : "Rejected"}
              </Typography>
            )}
            {document.certificate_status_id === 0 && (
              <Typography
                sx={{
                  color:
                    document.certificate_status_id === 2
                      ? theme.palette.error.main
                      : theme.palette.tertiary.main,
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                To be uploaded.
              </Typography>
            )}
          </Stack>
        </Stack>
        {document.certificate_status_id === 1 && !isDeleted && showButton ? (
          <Stack direction={"row"} sx={{ ml: 2, gap: 2 }}>
            <Box>
              <Button
                variant="contained"
                disableElevation
                color="altThemeTwo"
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  textTransform: "none",
                  height: 35,
                  width: 80,
                }}
                onClick={handleOpenReject}
              >
                Reject
              </Button>
              {openRejectDialog && (
                <RejectDocuments
                  onClose={handleCloseReject}
                  openRejectDialog={openRejectDialog}
                  certID={document.id}
                  userID={userID}
                  onSubmit={onChange}
                />
              )}
            </Box>
            <Box>
              <Button
                variant="contained"
                disableElevation
                color="approveDoc"
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  textTransform: "none",
                  height: 35,
                  width: 80,
                }}
                onClick={handleOpenApprove}
              >
                Approve
              </Button>
              {openApproveDialog && (
                <ApproveDocument
                  openApproveDialog={openApproveDialog}
                  onClose={handleCloseApprove}
                  certID={document.id}
                  userID={userID}
                  onSubmit={onChange}
                />
              )}
            </Box>
          </Stack>
        ) : document.certificate_status_id === 3 && !isDeleted ? (
          <Stack direction={"row"} sx={{ ml: 2 }}>
            {showButton && (
              <Typography
                sx={{
                  cursor: "pointer",
                  color: alpha(theme.palette.theme.main, 0.7),
                  fontSize: 16,
                  fontWeight: 500,
                }}
                onClick={openEditModal}
              >
                Edit
              </Typography>
            )}
            {isOpenEditModal && (
              <EditDocument
                openApproveDialog={isOpenEditModal}
                onClose={closeEditModal}
                certID={document.id}
                userID={userID}
                onSubmit={onChange}
                date={moment(document.expiry_date).format("YYYY/MM/DD")}
              />
            )}
            {showButton && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ ml: 1, mr: 1, mt: "2px", mb: "2px" }}
              />
            )}
            <Typography
              sx={{
                cursor: "pointer",
                color: theme.palette.theme.main,
                fontSize: 16,
                fontWeight: 500,
              }}
              onClick={() => {
                window.open(document.url, "_blank");
              }}
            >
              View
            </Typography>
          </Stack>
        ) : (
          <Typography
            sx={{
              color: theme.palette.tertiary.main,
              width: 260,
              fontSize: 16,
              fontWeight: 500,
              textAlign: "end",
            }}
          >
            {document.message}
          </Typography>
        )}
      </Stack>
      {showDivider && <Divider sx={{ mt: 2, mb: 2 }} />}
    </Stack>
  );
};

DocumentCard.propTypes = {
  document: PropTypes.any,
  showDivider: PropTypes.bool,
  userID: PropTypes.number,
  onChange: PropTypes.func,
};

DocumentCard.defaultProps = {
  showDivider: true,
};
