import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { TickCircle } from "iconsax-react";

export const ProgressTick = (props) => {
  const theme = useTheme();
  const { isSelected } = props;

  return (
    <Stack direction={"column"} alignItems={"center"} sx={{ width: 25 }}>
      <Box>
        <TickCircle
          size="25"
          color={
            isSelected
              ? theme.palette.tickGreen.main
              : theme.palette.tickGray.main
          }
          variant="Bold"
        />
      </Box>
      <Divider
        orientation="vertical"
        sx={{
          backgroundColor: isSelected
            ? theme.palette.tickGreen.main
            : theme.palette.tickGray.main,
          height: 60,
        }}
      ></Divider>
    </Stack>
  );
};
