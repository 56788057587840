import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Edit2, Trash, Eye, EyeSlash } from "iconsax-react";
import { actionMenuHorizontal } from "../../assets";
import PropTypes from "prop-types";

export default function CategoryEditDeleteMenu(props) {
  const { onEditClick, onDeleteClick, hasChildItem, isHiddenItem } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Box
        aria-describedby={id}
        variant="contained"
        disableElevation
        onClick={handleClick}
        sx={{ marginTop: "-10px", cursor: "pointer" }}
      >
        <img
          src={actionMenuHorizontal}
          width={"18px"}
          height={"4px"}
          // onClick={onOpen}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.white.main,
            padding: "12px 16px",
            margin: "8px",
            borderRadius: "6px",
            // boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.10)",
            zIndex: 1,

            cursor: "pointer",
          }}
        >
          <Box
            sx={{ display: "flex", pt: 1, pb: 2 }}
            onClick={() => {
              onEditClick();
              handleClose();
            }}
          >
            <Edit2 size="20" color="#000000" variant="Outline" />
            <Typography sx={{ padding: "0px 10px", fontWeight: 500 }}>
              Edit
            </Typography>
          </Box>
          {/* <EditUser
            openEditUserDialog={openEditUserDialog}
            onClose={handleEditClose}
          /> */}
          <Box
            sx={{ display: "flex" }}
            onClick={() => {
              onDeleteClick(isHiddenItem);
              handleClose();
            }}
          >
            {!hasChildItem && !isHiddenItem && (
              <Trash
                style={{
                  color: isHiddenItem
                    ? theme.palette.hideButtonOrange.main
                    : theme.palette.amountPenalty.main,
                  fontSize: "20px",
                }}
              />
            )}
            {isHiddenItem && (
              <Eye
                size="20"
                color={theme.palette.hideButtonOrange.main}
                variant="Outline"
              />
            )}
            {hasChildItem && !isHiddenItem && (
              <EyeSlash
                size="20"
                color={theme.palette.hideButtonOrange.main}
                variant="Outline"
              />
            )}
            <Typography
              sx={{
                color:
                  hasChildItem || isHiddenItem
                    ? theme.palette.hideButtonOrange.main
                    : theme.palette.amountPenalty.main,
                padding: "0px 8px",
                fontWeight: 500,
              }}
            >
              {hasChildItem
                ? isHiddenItem
                  ? "Unhide"
                  : "Hide"
                : isHiddenItem
                ? "Unhide"
                : "Delete"}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}

CategoryEditDeleteMenu.propTypes = {
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  hasChildItem: PropTypes.bool,
  isHiddenItem: PropTypes.bool,
};

CategoryEditDeleteMenu.defaultProps = {
  hasChildItem: false,
  isHiddenItem: false,
};
