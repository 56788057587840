import { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import { LoginScreen } from "../pages/authentication/LoginScreen";
import { VerifyOtp } from "../pages/authentication/VerifyOtp";
import { AuthRoute } from "./Redirection";

export function Authentication() {
  return (
    <Fragment>
      <Routes>
        <Route element={<AuthRoute />}>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/otpverify" element={<VerifyOtp />} />
        </Route>
      </Routes>
    </Fragment>
  );
}
