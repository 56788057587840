import {
  AppBar,
  Button,
  Dialog,
  Divider,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import PurchaseScanningItems from "../items/PurchaseScanningItems";
import PurchaseOrderItems from "../items/PurchaseOrderItems";
import PurchaseStartScan from "../items/PurchaseStartScan";
import { useParams } from "react-router-dom";
import api from "../../service/api";
import urls from "../../service/urls";
import { useAuth, useToast } from "../../context";
import useScanDetection from "use-scan-detection";

function BuyerPurchaseScan(props) {
  const { open, onClose, type } = props;

  const theme = useTheme();

  const [showScan, setShowScan] = useState(false);
  const [upc, setUpc] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [orderdetails, setOrderDetails] = useState({});
  const [scannedProducts, setScannedProducts] = useState([]);

  const { id } = useParams();
  const { showToast } = useToast();
  const { logout } = useAuth();

  const [requestData, setRequestData] = useState({
    itemsPerPage: 10,
    currentPage: 1,
  });
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 0,
    total_count: 0,
  });

  const getOrderDetails = () => {
    const { itemsPerPage, currentPage } = requestData;

    api
      .get(`${urls.purchaseOrderDetails}/${id}`, {
        params: {
          page: currentPage,
          limit: itemsPerPage,
        },
      })
      .then((res) => {
        setOrderDetails(res.data.data);
        setPaginationData({
          page: res.data.data.page,
          limit: res.data.data.limit,
          total_count: res.data.data.total_count,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response.data.message, "error");
        }
      });
  };

  const getScannedProducts = () => {
    api
      .get(`${urls.purchaseScannedList}/${id}`)
      .then((res) => {
        setScannedProducts(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response.data.message, "error");
        }
      });
  };

  useEffect(() => {
    if (open) {
      if (type === 2) {
        setShowScan(true);
        if (scannedProducts.length === 0) {
          getScannedProducts();
        }
      }
      getOrderDetails();
    }
  }, [open, requestData]);

  const checkUpc = () => {
    const params = {
      upc: upc,
      seller_order_id: id,
    };

    api
      .get(urls.checkUpc, {
        params: params,
      })
      .then((res) => {
        if (res.data.status !== 1) {
          setUpc("");
          showToast(res.data.message, "error");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          setUpc("");
          showToast(err.response.data.message, "error");
        }
      });
  };

  const removeScannedProducts = ({ productId }) => {
    api
      .delete(`${urls.purchaseRemoveScannedProduct}/${productId}`)
      .then((res) => {
        getOrderDetails();
        getScannedProducts();
        // setScannedProducts(
        //   scannedProducts.filter((item) => item.id !== productId)
        // );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response.data.message, "error");
        }
      });
  };

  useEffect(() => {
    if (upc !== "") {
      checkUpc();
    }
  }, [upc]);

  const inBoundScan = () => {
    api
      .post(urls.inboundScan, {
        upc: upc,
        serial_number: serialNo,
        seller_order_id: id,
      })
      .then((res) => {
        setUpc("");
        setSerialNo("");
        if (res.data.status === 1) {
          getOrderDetails();
          getScannedProducts();
          // const newObject = {
          //   id: res.data.data.id,
          //   product_name: res.data.data.product_name,
          //   serial_no: res.data.data.serial_no,
          //   upc: res.data.data.upc,
          // };
          // setScannedProducts((prevData) => [...prevData, newObject]);
          showToast(res.data.message, "success");
        } else {
          showToast(res.data.message, "error");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          // setUpc("");
          // setSerialNo("");
          showToast(err.response.data.message, "error");
        }
      });
  };

  useScanDetection({
    onComplete: (code) => {
      const refCode = code.replace(/Shift/gi, "");
      if (upc === "") {
        setUpc(refCode);
      } else if (serialNo === "") {
        setSerialNo(refCode);
      }
    },
  });

  const updateRequestData = (newData) => {
    setRequestData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleOnClose = () => {
    setUpc("");
    setSerialNo("");
    setShowScan(false);
    setScannedProducts([]);
    onClose();
  };

  return (
    <Dialog fullScreen open={open} onClose={handleOnClose}>
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: theme.palette.white.main,
          color: theme.palette.black.main,
          fontWeight: 600,
          fontSize: 24,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleOnClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Divider orientation="vertical" sx={{ paddingLeft: "10px" }} />
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Scan Order items
          </Typography>
          {showScan &&
            orderdetails?.seller_order_logs?.some(
              (log) =>
                log.order_status.toLowerCase() !==
                "Inbound Scan Completed".toLowerCase()
            ) && (
              <Button
                onClick={() => {
                  setShowScan(false);
                  setUpc("");
                  setSerialNo("");
                }}
                variant="contained"
                disableElevation
                style={{
                  backgroundColor: theme.palette.popbuttonselected.main,
                  textTransform: "none",
                }}
              >
                Stop Scanning
              </Button>
            )}
        </Toolbar>
      </AppBar>
      <Grid container sx={{ padding: "30px 0px 50px 0px" }}>
        <Grid item xs={8}>
          {showScan && (
            <PurchaseScanningItems
              upc={upc}
              serialNo={serialNo}
              onSetSerialNo={setSerialNo}
              onInBoundScan={inBoundScan}
            />
          )}
          <PurchaseOrderItems
            data={orderdetails}
            paginationData={paginationData}
            itemsPerPage={requestData.itemsPerPage}
            setItemsPerPage={(count) =>
              updateRequestData({ itemsPerPage: count, currentPage: 1 })
            }
            setPageNumber={(page) => updateRequestData({ currentPage: page })}
            type={type === 2 ? "purchase" : "sell"}
          />
        </Grid>
        <Grid item xs={4} sx={{ paddingRight: "10px" }}>
          <PurchaseStartScan
            showScan={showScan}
            onSetShowScan={() => setShowScan(true)}
            scannedProducts={scannedProducts}
            onDelete={(id) => removeScannedProducts({ productId: id })}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default BuyerPurchaseScan;
