import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import { IconColumnDataSmall } from "../../components/data/IconColumDataSmall";
import { Add, EmptyWallet } from "iconsax-react";
import SearchField from "../../components/search/SearchField";
import { PaymentTable } from "../../components/table/PaymentTable";
import { AddPayment } from "../../components/alert/AddPayment";
import { useAPI } from "../../hooks/useAPI";
import { useAuth, useToast } from "../../context";
import { LoaderCard } from "../../components/loaders/LoaderCard";
import { EmptyCard } from "../../components/emptyCard/EmptyCard";
import { currencyFormatter } from "../../utilities/extensions";

export const PaymentScreen = () => {
  const theme = useTheme();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [openAddPaymentDialog, setOpenAddPaymentDialog] = useState();
  const [transactions, setTransactions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageParams, setPageParams] = useState({
    page: 0,
    limit: 10,
  });
  const [searchText, setSearchText] = useState("");
  const [balance, setBlance] = useState(0);
  const [currency, setCurrency] = useState("");

  const handleOpenAddPaymentDialog = () => {
    setOpenAddPaymentDialog(true);
  };

  const handleCloseAddPaymentDialog = () => {
    setOpenAddPaymentDialog(false);
  };

  const getTransactions = () => {
    let params = { ...pageParams };
    params.page = pageParams.page + 1;
    if (searchText) {
      params.search_keyword = searchText;
    }
    api
      .get(urls.transactionsList, { params: params })
      .then((res) => {
        setTransactions(res.data.data.data);
        setTotalCount(res.data.data.pagination.total_count);
        setBlance(res.data.data.closing_balance);
        setCurrency(res.data.data?.currency ?? "");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getTransactions();
  }, [pageParams, searchText]);

  return (
    <Fragment>
      <Grid container direction={"column"} xs sx={{ pr: 2 }}>
        <Grid item>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: 24,
                fontWeight: 600,
              }}
            >
              Transaction
            </Typography>
            <Box sx={{ width: 300, height: 80, ml: 2 }}>
              <IconColumnDataSmall
                icon={
                  <EmptyWallet
                    size={"22"}
                    color={theme.palette.smallIconOne.main}
                  />
                }
                bgColor={theme.palette.altThemeThreeLight.main}
                headerColor={theme.palette.smallIconTextOne.main}
                header={"Balance"}
                valueColor={theme.palette.primary.main}
                value={currencyFormatter(balance, currency)}
              />
            </Box>
          </Stack>
        </Grid>
        {/* {isLoading && transactions.length === 0 && (
          <Grid item>
            <LoaderCard />
          </Grid>
        )}
        {!isLoading && transactions.length === 0 && (
          <Grid item>
            <EmptyCard />
          </Grid>
        )} */}
        <Grid item>
          <Stack
            direction={"column"}
            sx={{
              p: 2,
              mt: 3,
              mb: 2,
              border: 1,
              borderColor: theme.palette.line.main,
              borderRadius: 2,
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ height: 50 }}
            >
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Transactions List
              </Typography>
              <Stack direction={"row"}>
                <SearchField onSearchTextChange={setSearchText} />
                <Button
                  onClick={handleOpenAddPaymentDialog}
                  variant="contained"
                  disableElevation
                  startIcon={<Add />}
                  color="theme"
                  style={{ textTransform: "none" }}
                  sx={{
                    width: 175,
                    height: 45,
                    ml: 2,
                  }}
                >
                  Add Payment
                </Button>
                {openAddPaymentDialog && (
                  <AddPayment
                    openAddPaymentDialog={openAddPaymentDialog}
                    onClose={handleCloseAddPaymentDialog}
                    type={"admin"}
                    onSubmit={getTransactions}
                    currency={currency}
                  />
                )}
              </Stack>
            </Stack>
            <Divider sx={{ mt: "14px" }} />
            {isLoading && transactions.length === 0 && <LoaderCard />}
            {!isLoading && transactions.length === 0 && <EmptyCard />}
            {transactions.length > 0 && (
              <PaymentTable
                totalCount={totalCount}
                data={transactions}
                currPage={pageParams.page}
                limit={pageParams.limit}
                onPageChange={setPageParams}
                currency={currency}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};
