import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Edit2, Eye, EyeSlash } from "iconsax-react";
import { actionMenuHorizontal } from "../../assets";
import PropTypes from "prop-types";

export default function ProductHideUnhideMenu(props) {
  const { onDeleteClick, onEditClick, isHiddenItem } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Box
        aria-describedby={id}
        variant="contained"
        disableElevation
        onClick={handleClick}
        sx={{ marginTop: "-10px", cursor: "pointer" }}
      >
        <img
          src={actionMenuHorizontal}
          width={"18px"}
          height={"4px"}
          // onClick={onOpen}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.white.main,
            padding: "12px 16px",
            margin: "8px",
            borderRadius: "6px",
            zIndex: 1,

            cursor: "pointer",
          }}
        >
          <Box
            sx={{ display: "flex", pt: 1, pb: 2 }}
            onClick={() => {
              onEditClick();
              handleClose();
            }}
          >
            <Edit2 size="20" color="#000000" variant="Outline" />
            <Typography sx={{ padding: "0px 10px", fontWeight: 500 }}>
              Edit
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex" }}
            onClick={() => {
              onDeleteClick(isHiddenItem);
              handleClose();
            }}
          >
            {isHiddenItem && (
              <Eye
                size="20"
                color={theme.palette.hideButtonOrange.main}
                variant="Outline"
              />
            )}
            {!isHiddenItem && (
              <EyeSlash
                size="20"
                color={theme.palette.hideButtonOrange.main}
                variant="Outline"
              />
            )}
            <Typography
              sx={{
                color: theme.palette.hideButtonOrange.main,
                padding: "0px 8px",
                fontWeight: 500,
              }}
            >
              {isHiddenItem ? "Unhide" : "Hide"}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}

ProductHideUnhideMenu.propTypes = {
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  isHiddenItem: PropTypes.bool,
};

ProductHideUnhideMenu.defaultProps = {
  isHiddenItem: false,
};
