import {
  AppBar,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import PurchaseScanningItems from "../items/PurchaseScanningItems";
import PurchaseOrderItems from "../items/PurchaseOrderItems";
import PurchaseStartScan from "../items/PurchaseStartScan";
import PurchaseScannedItems from "../items/PurchaseScannedItems";
import { EmptyData } from "../empty_placeholder/EmptyData";
import ScrollButton from "../../styles/buttons/ScrollButton";

function PurchaseScanInBound(props) {
  const { open, onClose, Transition } = props;
  const theme = useTheme();
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: theme.palette.white.main,
          color: theme.palette.black.main,
          fontWeight: 600,
          fontSize: 24,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Divider orientation="vertical" sx={{ paddingLeft: "10px" }} />
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Scan Order items
          </Typography>
          <Button
            onClick={onClose}
            variant="contained"
            disableElevation
            style={{
              backgroundColor: theme.palette.popbuttonselected.main,
              textTransform: "none",
            }}
          >
            Stop Scanning
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container sx={{ padding: "30px 0px 50px 0px" }}>
        <Grid item xs={8}>
          <PurchaseScanningItems />
          <PurchaseOrderItems />
        </Grid>
        <Grid item xs={4} sx={{ paddingRight: "10px" }}>
          {/* <ScrollButton /> */}
          <PurchaseStartScan />
        </Grid>
      </Grid>
    </Dialog>
  );
}
export default PurchaseScanInBound;
