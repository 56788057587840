import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { TickCircle } from "iconsax-react";
import { ProgressTick } from "../progress/ProgressTick";
import moment from "moment";

export const OrderProgressItemCard = (props) => {
  const theme = useTheme();
  const { documentItem } = props;

  return (
    <Stack
      direction={"row"}
      sx={{
        pr: 1,
        pl: 1,
        gap: 1,
      }}
    >
      <ProgressTick isSelected={true}></ProgressTick>
      <Stack direction={"column"}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          {documentItem.registration_status}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {documentItem.log}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          {moment(documentItem.createdAt).format("DD MMM YYYY, hh:mm a")}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          {documentItem.approved_by}
        </Typography>
      </Stack>
    </Stack>
  );
};
