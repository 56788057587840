import {
  Badge,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { sampleNotificationItems } from "../../utilities";

export const NotScreen = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction={"column"}
      sx={{ width: "100%", height: "100vh", pr: 2 }}
    >
      <Grid item sx={{ width: "100%" }}>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item>
            <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
              Notifications
            </Typography>
          </Grid>
          <Grid item>
            <Box
              sx={{
                bgcolor: theme.palette.badgePrimary.main,
                p: "2px",
                pl: "6px",
                pr: "6px",
                borderRadius: 4,
                ml: 1,
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.white.main,
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                2 new
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Divider sx={{ mt: "4px", ml: -2, mr: -2 }} />
      </Grid>
      <Grid item>
        {sampleNotificationItems.map((item) => (
          <Stack
            direction={"column"}
            sx={{
              bgcolor: item.read
                ? alpha(theme.palette.menuAlt.main, 0.6)
                : theme.palette.menuHeader.main,
              p: 2,
              mt: 2,
              mb: 2,
              borderRadius: 2,
            }}
          >
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {item.notification}
            </Typography>
            <Typography
              sx={{
                color: item.read
                  ? theme.palette.primary.main
                  : theme.palette.badgePrimary.main,
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {item.date}
            </Typography>
          </Stack>
        ))}
      </Grid>
    </Grid>
  );
};
