import {
  Backdrop,
  Box,
  Button,
  Dialog,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { CoverUploader } from "../uploaders/CoverUploader";
import PropTypes from "prop-types";
import { useAPI } from "../../hooks/useAPI";
import { useAuth, useToast } from "../../context";
import { isBase64Image, convertBse64ToFormData } from "../../helpers";

export const AddBrands = (props) => {
  const theme = useTheme();
  const { api, urls, isLoading } = useAPI();
  const { logout } = useAuth();
  const { showToast } = useToast();

  const { open, onClose, onSubmit, isEdit, data } = props;
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    status: true,
    image: "",
  });

  const handleCancelClick = () => {
    onClose();
  };

  const createBrand = (url) => {
    let params = { ...formData };
    if (url) {
      params.image = url;
    }
    api
      .post(urls.addBrand, params)
      .then((res) => {
        onClose();
        onSubmit();
        showToast(res.data.message, "success");
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const editBrand = (url) => {
    let params = { ...formData };
    if (url) {
      params.image = url;
    }
    api
      .put(urls.updateBrand + data.id, params)
      .then((res) => {
        onClose();
        onSubmit();
        showToast(res.data.message, "success");
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleConfirmClick = () => {
    if (isBase64Image(formData.image)) {
      let blob = convertBse64ToFormData(formData.image);
      let form = new FormData();
      let timestamp = new Date().getTime();
      form.append("file", blob, `brand-logo-${timestamp}.png`);
      api
        .post(urls.fileUpload, form)
        .then((res) => {
          if (isEdit) {
            editBrand(res.data.data.file_url);
          } else {
            createBrand(res.data.data.file_url);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      if (isEdit) {
        editBrand();
      } else {
        createBrand();
      }
    }
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    if (data) {
      setFormData((prev) => ({
        ...prev,
        name: data.name,
        description: data.description,
        status: data.status,
        image: data.image,
      }));
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: theme.palette.black.main,
      //     opacity: 0.7,
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {`${isEdit ? "Edit" : "Add"} Brand`}
        </Typography>
        <Stack sx={{ mt: 4, ml: 4, mr: 4 }}>
          <CoverUploader
            onImageChange={(image) => {
              setFormData((prev) => ({
                ...prev,
                image: image,
              }));
            }}
            image={formData.image}
          />
        </Stack>
        <Stack sx={{ mt: 4, ml: 4, mr: 4 }}>
          <TextField
            id="outlined-basic"
            label="Brand Name"
            variant="outlined"
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                name: e.target.value,
                description: e.target.value,
              }));
            }}
            value={formData.name}
          />
        </Stack>
        <Stack sx={{ mt: 4, ml: 4, mr: 4 }}>
          <Grid container>
            <Grid item xs>
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={handleCancelClick}
                  variant="contained"
                  disableElevation
                  color="lightTheme"
                  sx={{
                    width: "100%",
                    height: 45,
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmClick}
                  variant="contained"
                  disableElevation
                  color="theme"
                  sx={{
                    width: "100%",
                    height: 45,
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                  disabled={
                    !formData.name.trim() || !formData.image.trim() || isLoading
                  }
                >
                  {isEdit ? "Update" : "Save"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Dialog>
  );
};

AddBrands.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  data: PropTypes.object,
};
