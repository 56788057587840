import { Stack, Tab, Tabs, useTheme } from "@mui/material";
import { Box1, Setting, User } from "iconsax-react";

export const SettingsTab = (props) => {
  const theme = useTheme();
  const { settingsTab, handleSettingsTabChange } = props;

  return (
    <Stack
      direction={"row"}
      sx={{
        width: "100%",
        height: 70,
        bgcolor: theme.palette.tabLight.main,
        borderRadius: 2,
        alignItems: "center",
      }}
    >
      <Tabs
        value={settingsTab}
        onChange={handleSettingsTabChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.theme.main,
          },
        }}
        sx={{ ml: 3 }}
      >
        {/* <Tab
          label="User Settings"
          icon={<User />}
          iconPosition="start"
          sx={{
            color: theme.palette.tertiary.main,
            fontSize: 18,
            fontWeight: 500,
            textTransform: "none",
            "&.Mui-selected": {
              color: theme.palette.theme.main, // Color for the selected text
            },
          }}
        /> */}
        <Tab
          label="Product Settings"
          icon={<Box1 />}
          iconPosition="start"
          sx={{
            ml: 2,
            mr: 2,
            color: theme.palette.tertiary.main,
            fontSize: 18,
            fontWeight: 500,
            textTransform: "none",
            "&.Mui-selected": {
              color: theme.palette.theme.main, // Color for the selected text
            },
          }}
        />
        <Tab
          label="Other Settings"
          icon={<Setting />}
          iconPosition="start"
          sx={{
            color: theme.palette.tertiary.main,
            fontSize: 18,
            fontWeight: 500,
            textTransform: "none",
            "&.Mui-selected": {
              color: theme.palette.theme.main, // Color for the selected text
            },
          }}
        />
      </Tabs>
    </Stack>
  );
};
