import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Crown1 } from "iconsax-react";
import { currencyFormatter } from "../../utilities/extensions";

export const PriceCard = (props) => {
  const { data, rank } = props;
  const theme = useTheme();

  return (
    <Stack>
      <Divider />
      <Stack
        direction={"row"}
        sx={{
          height: 60,
          alignItems: "center",
          pl: 3,
          pr: 3,
          justifyContent: "space-between",
        }}
      >
        <Stack direction={"row"}>
          <Typography
            sx={{
              color:
                rank === 1
                  ? theme.palette.rank.main
                  : theme.palette.altThemeTwo.main,
              fontSize: 16,
              fontWeight: 500,
              mr: "4px",
            }}
          >
            {rank}
          </Typography>
          {rank === 1 && <Crown1 size={18} color={theme.palette.rank.main} />}
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 16,
              fontWeight: 500,
              mr: "12px",
              ml: "12px",
              // width: "100%",
            }}
          >
            {data.name}
          </Typography>
        </Stack>
        <Typography
          sx={{
            bgcolor: theme.palette.lightTheme.main,
            color: theme.palette.theme.main,
            fontSize: 14,
            fontWeight: 600,
            pt: "6px",
            pb: "6px",
            pl: 1,
            pr: 1,
            borderRadius: 4,
            minWidth: 70,
            textAlign: "center",
          }}
        >
          {currencyFormatter(data.price, data.currency)}
        </Typography>
      </Stack>
    </Stack>
  );
};
