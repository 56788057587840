import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { Fragment, useState } from "react";
import { PurchaseHeader } from "../../components/headers/PurchaseHeader";
import { PurchaseTable } from "../../components/table/PurchaseTable";
import { usePurchaseStore } from "../../store/store";

export const PurchaseScreen = () => {
  const theme = useTheme();

  // const [status, setStatus] = useState("");
  // const [date, setDate] = useState("");
  // const [search, setSearch] = useState("");

  const {
    status,
    setStatus,
    search,
    setSearch,
    date,
    setDate,
    setShouldReload,
    setPage,
  } = usePurchaseStore((state) => state);

  return (
    <Fragment>
      <Stack direction={"column"} sx={{ pr: 2 }}>
        <PurchaseHeader
          date={date}
          onSetDate={(value) => {
            setDate(value);
            setPage(0);
            setShouldReload(true);
          }}
          onSetStatus={(value) => {
            setStatus(value);
            setPage(0);
            setShouldReload(true);
          }}
          onSetSearch={(value) => {
            setSearch(value);
            setPage(0);
            setShouldReload(true);
          }}
          status={status}
        />
        <Divider sx={{ mt: 2 }} />
        <Typography
          sx={{
            mt: 3,
            mb: 3,
            color: theme.palette.tertiary.main,
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          Showing All Results
        </Typography>
        <PurchaseTable status={status} date={date} search={search} />
      </Stack>
    </Fragment>
  );
};
