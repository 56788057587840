import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import PurchaseScan from "../alert/PurchaseScan";
import SearchField from "../search/SearchField";
import { SimpleDropDown } from "../dropdown/SimpleDropDown";
import { EditUser } from "../alert/EditUser";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import PropTypes from "prop-types";
import { useUsersStore } from "../../store/store";

export const UsersHeader = (props) => {
  const { showDropdown, onDropdownChange, onSearchChange, catID } = props;
  const theme = useTheme();
  const [open, setOpen] = useState();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  // const [tradeCategories, setTradeCategories] = useState([]);
  const { tradeCategories, setTradeCategories } = useUsersStore(
    (state) => state
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getTradeCategories = () => {
    api
      .get(urls.tradeCategoriesList)
      .then((res) => {
        setTradeCategories(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getTradeCategories();
  }, []);

  return (
    <Fragment>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "600" }}
            // onClick={handleClickOpen}
          >
            Users
          </Typography>
        </Box>
        <Stack direction={"row"} alignItems={"center"}>
          {showDropdown && (
            <Fragment>
              {catID && (
                <Typography
                  sx={{
                    mr: 1,
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "gray",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onDropdownChange("");
                  }}
                >
                  Clear Filter
                </Typography>
              )}
              <Stack
                container
                direction={"row"}
                sx={{
                  mr: "12px",
                  height: 45,
                  bgcolor: theme.palette.menuAlt.main,
                  borderRadius: "2px",
                }}
              >
                <SimpleDropDown
                  placeholderText={"Select Category"}
                  options={tradeCategories}
                  onChange={(id) => onDropdownChange(id)}
                  value={catID}
                />
              </Stack>
            </Fragment>
          )}
          <SearchField
            onSearchTextChange={(text) => {
              onSearchChange(text);
            }}
          />
        </Stack>
      </Stack>
    </Fragment>
  );
};

UsersHeader.propTypes = {
  showDropdown: PropTypes.bool,
  onDropdownChange: PropTypes.func,
};
