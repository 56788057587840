import { Box, Button, Menu, MenuItem, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { dropDownArrow } from "../../assets";
import { sampleSelectItems } from "../../utilities";
import PropTypes from "prop-types";

export const SimpleDropDown = (props) => {
  const { placeholderText, options, onChange, value } = props;
  const [simpleMenuAnchorEl, setSimpleMenuAnchorEl] = useState(null);
  const [simpleSelectedMenu, setSimpleSelectedMenu] = useState("");

  const handleSimpleMenuClick = (event) => {
    setSimpleMenuAnchorEl(event.currentTarget);
  };

  const handleSimpleMenuClose = () => {
    setSimpleMenuAnchorEl(null);
  };

  const handleSimpleMenuItemClick = (value, id) => {
    setSimpleSelectedMenu(value);
    handleSimpleMenuClose();
    onChange(id);
  };

  useEffect(() => {
    if (value) {
      setSimpleSelectedMenu(options.find((x) => x.id === value).name);
    } else {
      setSimpleSelectedMenu("");
    }
  }, [value]);

  return (
    <Box>
      <Button
        variant="contained"
        disableElevation
        color="transparent"
        onClick={handleSimpleMenuClick}
        endIcon={
          <img
            src={dropDownArrow}
            style={{
              width: "14px",
              height: "10px",
            }}
          />
        }
        sx={{ height: "100%", textTransform: "none" }}
      >
        {simpleSelectedMenu || placeholderText}
      </Button>
      <SimpleMenuDropDown
        simpleMenuAnchorEl={simpleMenuAnchorEl}
        handleSimpleMenuClose={handleSimpleMenuClose}
        handleSimpleMenuItemClick={handleSimpleMenuItemClick}
        options={options}
      />
    </Box>
  );
};

const SimpleMenuDropDown = (props) => {
  const {
    simpleMenuAnchorEl,
    handleSimpleMenuClose,
    handleSimpleMenuItemClick,
    options,
  } = props;

  return (
    <Menu
      anchorEl={simpleMenuAnchorEl}
      open={Boolean(simpleMenuAnchorEl)}
      onClose={handleSimpleMenuClose}
    >
      {options.map((item) => (
        <MenuItem
          key={item.id}
          onClick={() => handleSimpleMenuItemClick(item.name, item.id)}
        >
          {item.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

SimpleDropDown.propTypes = {
  placeholderText: PropTypes.string,
  options: PropTypes.array,
  selectedText: PropTypes.string,
  onChange: PropTypes.func,
};

SimpleDropDown.defaultProps = {
  options: [],
};

SimpleMenuDropDown.propTypes = {
  simpleMenuAnchorEl: PropTypes.object,
  handleSimpleMenuClose: PropTypes.func,
  handleSimpleMenuItemClick: PropTypes.func,
  options: PropTypes.array,
};
