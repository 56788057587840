import { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  useTheme,
  TextField,
  Paper,
  Button,
  Divider,
  InputAdornment,
  Stack,
  MenuItem,
  Menu,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { logo } from "../../assets";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { countries } from "../../utilities/other";
import { useToast } from "../../context/ToastContext";
import api from "../../service/api";
import urls from "../../service/urls";

export const LoginScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { showToast } = useToast();

  const [countryMenuAnchorEl, setCountryMenuAnchorEl] = useState(null);
  const [countrySelectedMenu, setCountrySelectedMenu] = useState("");

  const [formData, setFormData] = useState({
    phone_code: "+971",
    phone_number: "",
    user_type: 1,
    errors: { phone_number: false },
  });

  const handleCountryMenuClick = (event) => {
    setCountryMenuAnchorEl(event.currentTarget);
  };

  const handleCountryMenuClose = () => {
    setCountryMenuAnchorEl(null);
  };

  const handleCountryMenuItemClick = (value) => {
    setCountrySelectedMenu(value);
    handleCountryMenuClose();
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      curr.errors[key] = false;
      return curr;
    });
  };

  const handleOtp = () => {
    let invalid = false;
    if (!formData.phone_number) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.phone_number = true;
        return curr;
      });
      invalid = true;
    }

    if (!invalid) {
      const params = { ...formData };
      delete params.errors;

      api
        .post(urls.sendOtp, params)
        .then((res) => {
          showToast(res.data.message, "success");
          navigate("/otpverify", {
            replace: true,
            state: {
              phone_code: formData.phone_code,
              phone_number: formData.phone_number,
            },
          });
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  useEffect(() => {
    updateFormData(
      "phone_code",
      countrySelectedMenu.phone === undefined
        ? "+971"
        : `+${countrySelectedMenu.phone}`
    );
  }, [countrySelectedMenu]);

  return (
    <Fragment>
      <Grid
        container
        sx={{
          backgroundColor: theme.palette.theme.main,
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Paper
          elevation={5}
          sx={{
            width: "25%",
            borderRadius: 2,
            backgroundColor: theme.palette.white.main,
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              pt: 4,
              pb: 4,
            }}
            direction="column"
            alignItems={"center"}
          >
            <Grid item>
              <img
                src={logo}
                style={{
                  width: 76,
                  height: 35,
                }}
              />
            </Grid>
            {/* <Grid item>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 18,
                  fontWeight: 500,
                }}
              >
                Trading Company
              </Typography>
            </Grid> */}
            <Divider sx={{ width: "100%", mt: 2, mb: 2 }} />
            <Grid item>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 24,
                  fontWeight: 700,
                }}
              >
                Sign In
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Please login to your account
              </Typography>
            </Grid>
            <Grid item sx={{ pt: 1, pb: 1, pl: 0, pr: 0 }}>
              {/* <TextField
                id="outlined-required"
                type="email"
                label="Email ID"
                sx={{ width: "100%" }}
              /> */}
              <TextField
                label="Whatsapp Number"
                name="mobile"
                value={formData.phone_number}
                error={formData.errors.phone_number}
                helperText={
                  formData.errors.phone_number
                    ? "Enter a valid mobile number"
                    : null
                }
                onChange={(e) => {
                  const value = e.target.value;
                  const onlyNumbers = /^[0-9]*$/; // Regular expression to match only numbers

                  if (onlyNumbers.test(value)) {
                    updateFormData("phone_number", value);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Stack
                        direction={"row"}
                        onClick={handleCountryMenuClick}
                        sx={{ cursor: "pointer", width: "100%" }}
                      >
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: theme.palette.primary.main,
                          }}
                        >
                          +{countrySelectedMenu.phone || 971}
                        </Typography>
                        <ArrowDropDownIcon />
                        <Divider orientation="vertical" flexItem />
                      </Stack>
                      <CountryMenuDropDown
                        countryMenuAnchorEl={countryMenuAnchorEl}
                        handleCountryMenuClose={handleCountryMenuClose}
                        handleCountryMenuItemClick={handleCountryMenuItemClick}
                      />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  sx: {
                    fontSize: 16,
                    fontWeight: 500,
                    color: theme.palette.primary.main,
                  },
                }}
                sx={{
                  mt: 3,
                  ml: 4,
                  mr: 4,
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "& input[type=number]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item sx={{ width: "100%", pl: 4, pr: 4, pb: 3, pt: 3 }}>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: theme.palette.tabgrey.main,
                  textAlign: "center",
                }}
              >
                OTP will be sent to this number
              </Typography>
            </Grid>

            <Grid item sx={{ width: "100%", pl: 4, pr: 4 }}>
              <Button
                variant="contained"
                disableElevation
                color="theme"
                sx={{
                  marginTop: 1,
                  width: "100%",
                  height: 45,
                  textTransform: "none",
                }}
                // onClick={() => {
                //   navigate("/dashboard");
                // }}

                onClick={handleOtp}
              >
                Get OTP
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Fragment>
  );
};
const CountryMenuDropDown = (props) => {
  const {
    countryMenuAnchorEl,
    handleCountryMenuClose,
    handleCountryMenuItemClick,
  } = props;

  return (
    <Menu
      anchorEl={countryMenuAnchorEl}
      open={Boolean(countryMenuAnchorEl)}
      onClose={handleCountryMenuClose}
    >
      {countries.map((item) => (
        <MenuItem
          key={item.phone}
          onClick={() => handleCountryMenuItemClick(item)}
        >
          {`+${item.phone} ${item.label}`}
        </MenuItem>
      ))}
    </Menu>
  );
};
