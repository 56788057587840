import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { DocumentCard } from "../cards/DocumentCard";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

export const Documents = (props) => {
  const { data, userID, onChange, userType, isDeleted, showButton } = props;
  const theme = useTheme();
  const [list, setList] = useState([
    {
      id: 0,
      // certificate_type:
      //   userType === "Buyer" ? "business_certificate" : "trade_license",
      certificate_type: "trade_license",
      url: "",
      certificate_status_id: 0,
      certificate_status: "",
      createdAt: "",
      expiry_date: null,
      message: "",
    },
    {
      id: 0,
      certificate_type: "trn_certificate",
      url: "",
      certificate_status_id: 0,
      certificate_status: "",
      createdAt: "",
      expiry_date: null,
      message: null,
    },
    {
      id: 0,
      certificate_type: "passport",
      url: "",
      certificate_status_id: 0,
      certificate_status: "",
      createdAt: "",
      expiry_date: "",
      message: "",
    },
    {
      id: 0,
      certificate_type: "emirates_id",
      url: "",
      certificate_status_id: 0,
      certificate_status: "",
      createdAt: "",
      expiry_date: null,
      message: null,
    },
  ]);

  useEffect(() => {
    setList([...list, ...data]);
    setList((prev) => {
      return [
        ...new Map(
          prev.map((item) => [item["certificate_type"], item])
        ).values(),
      ];
    });
  }, [data]);

  return (
    <Stack
      direction={"column"}
      sx={{ border: 1, borderColor: theme.palette.line.main, borderRadius: 2 }}
    >
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontSize: 18,
          fontWeight: 500,
          pl: 2,
          pr: 2,
          pt: "14px",
          pb: "14px",
        }}
      >
        Documents
      </Typography>
      <Divider />
      <Box sx={{ p: 2 }}>
        {list.map((document, index) => {
          return (
            <DocumentCard
              key={index}
              document={document}
              showDivider={index !== list.length - 1}
              userID={userID}
              onChange={onChange}
              userType={userType}
              isDeleted={isDeleted}
              showButton={showButton}
            />
          );
        })}
        {/* <DocumentCard
          status={1}
          header={"Business Certificate"}
          description={"Expires on 01/01/2024"}
        />
        <DocumentCard
          status={2}
          header={"TRN Certificate"}
          description={"Expires in 2 days"}
        />
        <DocumentCard
          status={2}
          header={"Owner Passport"}
          description={"Expires on 02/01/2024"}
        />
        <DocumentCard
          status={3}
          header={"Emerites ID"}
          description={"Rejected"}
          showDivider={false}
        /> */}
      </Box>
    </Stack>
  );
};

Documents.propTypes = {
  data: PropTypes.array,
  userID: PropTypes.number,
  onChange: PropTypes.func,
  userType: PropTypes.string,
};
