import { useState, Fragment, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  TablePagination,
  useTheme,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { usePopup, useModal } from "../../hooks";
import { EditSellerDialog } from "../dialogs/EditSellerDialog";
import { ReminderDialog } from "../dialogs/ReminderDialog";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import { EditDeletePopover } from "../popup/EditDeletePopover";
import { useParams } from "react-router-dom";
import { LoaderCard } from "../loaders/LoaderCard";
import { EmptyCard } from "../emptyCard/EmptyCard";

export const SellersUsersTable = (props) => {
  const { showButton, isNewUserCreated, onNewUserHandled } = props;
  const theme = useTheme();
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const [selectedUser, setSelectedUser] = useState(null);
  const [menuPopup, openMenuPopup, closeMenuPopup] = usePopup();
  const [isEditUserModal, openEditUserModal, closeEditUserModal] = useModal();
  const [isDeleteUserModal, openDeleteUserModal, closeDeleteUserModal] =
    useModal();
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const showEditDeleteMenu = (e, user) => {
    setSelectedUser(user);
    openMenuPopup(e.currentTarget);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
    height: 45,
  };

  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
  };

  const deleteUser = () => {
    closeDeleteUserModal(false);
    api
      .delete(`${urls.deleteSeller}${id}/${selectedUser.id}`)
      .then((res) => {
        showToast(res.data.message, "success");
        getUsers();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const onEditUser = () => {
    closeEditUserModal();
    getUsers();
  };

  const getUsers = () => {
    setLoadingUsers(true);
    let params = {
      page: page + 1,
      limit: rowsPerPage,
    };
    api
      .get(`${urls.sellerUsersList}${id}`, { params: params })
      .then((res) => {
        setLoadingUsers(false);
        let data = res.data;
        setUsers(data.data.data);
        setTotalCount(data.data.total_count);
      })
      .catch((err) => {
        setLoadingUsers(false);
        if (err.response?.status === 401) {
          logout();
        }
        showToast(err.response?.data?.message, "error");
      });
  };

  useEffect(() => {
    getUsers();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (isNewUserCreated) {
      // Notify parent that the new admin creation has been handled
      onNewUserHandled();
      getUsers();
    }
  }, [isNewUserCreated, onNewUserHandled]);

  return (
    <Fragment>
      {loadingUsers && <LoaderCard />}
      {!isLoading && users.length === 0 && <EmptyCard text={"No Users"} />}
      {isEditUserModal && (
        <EditSellerDialog
          open={isEditUserModal}
          user={selectedUser}
          onClose={closeEditUserModal}
          onSubmit={onEditUser}
        />
      )}
      <EditDeletePopover
        open={Boolean(menuPopup)}
        anchorEl={menuPopup}
        onClose={closeMenuPopup}
        onEdit={openEditUserModal}
        onDelete={openDeleteUserModal}
      />
      {isDeleteUserModal && (
        <ReminderDialog
          open={isDeleteUserModal}
          onClose={() => {
            closeDeleteUserModal(false);
          }}
          onConfirm={deleteUser}
          header={"Delete User"}
          reminder={
            "Are you sure you want to delete this user? This action cannot be undone."
          }
          confirm={"Delete"}
        />
      )}
      {!loadingUsers && users.length > 0 && (
        <Box
          sx={{
            border: 1,
            borderColor: theme.palette.line.main,
            borderRadius: 2,
            pl: 2,
            pr: 2,
          }}
        >
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerStyle}>No.</TableCell>
                  <TableCell sx={headerStyle}>Name</TableCell>
                  <TableCell sx={headerStyle}>Email</TableCell>
                  <TableCell sx={headerStyle}>Whatsapp</TableCell>
                  <TableCell sx={headerStyle}>Role</TableCell>
                  {showButton && <TableCell sx={headerStyle}></TableCell>}
                </TableRow>
              </TableHead>

              <TableBody>
                {users.map((data, index) => (
                  <TableRow>
                    <TableCell sx={itemStyle}>
                      {rowsPerPage * page + index + 1}
                    </TableCell>
                    <TableCell sx={itemStyle}>{data.name}</TableCell>
                    <TableCell sx={itemStyle}>{data.email}</TableCell>
                    <TableCell sx={itemStyle}>
                      {data.phone_code} {data.phone_number}
                    </TableCell>
                    <TableCell sx={itemStyle}>{data.role_name}</TableCell>
                    {showButton && (
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          size="medium"
                          onClick={(e) => showEditDeleteMenu(e, data)}
                        >
                          <MoreHorizIcon color="black" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>{" "}
          <TablePagination
            component="div"
            align="right"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ mt: "12px", mb: "12px" }}
          />
        </Box>
      )}
    </Fragment>
  );
};
