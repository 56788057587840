import { Grid, Typography, useTheme } from "@mui/material";
import { Fragment } from "react";

export const HeadSubData = (props) => {
  const { header, subHeader } = props;
  const theme = useTheme();
  return (
    <Fragment>
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        {header}
      </Typography>
      <Typography
        sx={{
          color: theme.palette.tertiary.main,
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {subHeader}
      </Typography>
    </Fragment>
  );
};
