import { TablePagination } from "@mui/material";
import PropTypes from "prop-types";

export const PaginationTable = (props) => {
  const { page, onSetPage, rowsPerPage, onSetRowsPerPage, totalCount } = props;

  return (
    <TablePagination
      component="div"
      sx={{ mt: "10px", mb: "10px" }}
      count={totalCount}
      page={page}
      onPageChange={(event, newPage) => onSetPage(newPage)}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onRowsPerPageChange={(event) => {
        onSetRowsPerPage(parseInt(event.target.value, 10));
        onSetPage(0);
      }}
    />
  );
};

PaginationTable.propTypes = {
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onSetPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onSetRowsPerPage: PropTypes.func.isRequired,
};
