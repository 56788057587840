import {
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";

export const TradingCategoryCard = (props) => {
  const { showDivider, name, onRemoveClick, onEditClick } = props;
  const theme = useTheme();

  return (
    <Stack direction={"column"} sx={{ pl: 2, pr: 2 }}>
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ height: 60 }}
      >
        <Grid item>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item>
          <Stack direction={"row"}>
            <Button
              onClick={onEditClick}
              variant="text"
              color="theme"
              sx={{ fontSize: 16, fontWeight: 500 }}
            >
              Edit
            </Button>
            <Button
              variant="text"
              color="error"
              sx={{ fontSize: 16, fontWeight: 500, textTransform: "none" }}
              onClick={onRemoveClick}
            >
              Remove
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {showDivider && <Divider />}
    </Stack>
  );
};

TradingCategoryCard.propTypes = {
  showDivider: PropTypes.bool,
  name: PropTypes.string,
  onRemoveClick: PropTypes.func,
  onEditClick: PropTypes.func,
};
