import { useTheme } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";

export const AddPaymentRadio = (props) => {
  const { value, onChange, disabled } = props;
  const theme = useTheme();

  return (
    <FormControl>
      <RadioGroup
        row
        name="row-radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        <FormControlLabel
          disabled={disabled}
          value="debit"
          control={
            <Radio
              color="theme"
              sx={{
                color: theme.palette.theme.main,
                "&.Mui-checked": {
                  color: theme.palette.theme.main,
                },
              }}
            />
          }
          label={
            <span
              style={{
                fontSize: "16px",
                fontWeight: 500,
                color: theme.palette.primary.main,
              }}
            >
              Debit
            </span>
          }
        />
        <FormControlLabel
          disabled={disabled}
          value="credit"
          control={
            <Radio
              sx={{
                color: theme.palette.theme.main,
                "&.Mui-checked": {
                  color: theme.palette.theme.main,
                },
              }}
            />
          }
          label={
            <span
              style={{
                fontSize: "16px",
                fontWeight: 500,
                color: theme.palette.primary.main,
              }}
            >
              Credit
            </span>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

AddPaymentRadio.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
