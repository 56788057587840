import {
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
  useTheme,
  Backdrop,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../service/api";
import urls from "../../service/urls";
import { useToast } from "../../context/ToastContext";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

export const AddAttributes = (props) => {
  const theme = useTheme();
  const { openAttributeDialog, onClose, fetchData } = props;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const ReloadData = () => {
    fetchData();
  };
  const [attributeType, setAttributeType] = useState({
    id: null,
    name: "",
    description: "",
    status: true,
    errors: { name: false },
  });
  const handleCancelClick = () => {
    onClose();
  };
  const handleAttribute = (e) => {
    setAttributeType((prev) => ({
      ...prev,
      name: e.target.value,
      description: e.target.value,
      errors: { name: false },
    }));
  };

  const { showToast } = useToast();
  const handleConfirmClick = () => {
    let invalid = false;
    if (
      !attributeType.id &&
      !attributeType.name &&
      !attributeType.description
    ) {
      setAttributeType((prev) => {
        const curr = { ...prev };
        prev.errors = true;
        return curr;
      });
      invalid = true;
    }

    if (!invalid) {
      const params = { ...attributeType };
      delete params.errors;
      api
        .post(urls.createAttribute, params)
        .then((res) => {
          showToast(res.data.message, "success");
          onClose();
          ReloadData();
        })
        .catch((err) => {
          showToast(err.response.data.message, "error");
        });
    }
  };

  useEffect(() => {
    console.log(attributeType);
  }, [attributeType]);

  return (
    <Dialog
      open={openAttributeDialog}
      onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: "rgba(0, 0, 0, 0.2)",
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Add Attributes
        </Typography>
        <TextField
          label="Attribute Name"
          onChange={handleAttribute}
          variant="outlined"
          error={attributeType.errors.name}
          value={attributeType.name}
          inputProps={{
            sx: {
              fontSize: 16,
              fontWeight: 500,
              color: theme.palette.primary.main,
            },
          }}
          sx={{ mt: 4, ml: 4, mr: 4 }}
          helperText={attributeType.errors.name ? "text is required" : null}
          required
        />
        <Stack direction={"row"} sx={{ mt: 3, ml: 4, mr: 4, gap: 2 }}>
          <Button
            onClick={() => onClose()}
            variant="contained"
            disableElevation
            color="lightTheme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClick}
            anchorEl={anchorEl}
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
            disabled={!attributeType.name.trim()}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

// AddAttributes.propTypes = {
//   openAttributeDialog: PropTypes.bool,
//   onClose: PropTypes.func,
//   onConfirm: PropTypes.string,
// };
