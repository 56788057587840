import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Typography,
  useTheme,
  FormGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment } from "react";
import { CoverUploader } from "../uploaders/CoverUploader";
import { Add } from "iconsax-react";
import MainCategorySlab from "../slabs/MainCategorySlab";
import { CheckBox } from "@mui/icons-material";
import MainCategoryMatrix from "../slabs/MainCategoryMatrix";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAPI } from "../../hooks/useAPI";
import { useAuth, useToast } from "../../context";
import { LoaderCard } from "../../components/loaders/LoaderCard";

export const AddMainCategory = (props) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const theme = useTheme();
  const { openMainDialog, onClose, onSubmit, brandID, isEdit, data } = props;
  const { api, urls, isLoading } = useAPI();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const [tradeCategories, setTradeCategories] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [settings, setSettings] = useState(null);
  const [catDetails, setCatDetails] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    minimum_drop: 0,
    brand_id: brandID,
    attributes: [],
    quantity_slab: [],
    commission_matrices: [],
  });

  //only for edit category with products
  const [commissionMatrices, setCommissionMatrices] = useState([]);
  const [alreadySavedAttributes, setAlreadySavedAttributes] = useState([]);

  const isValid = () => {
    if (
      formData.name === "" ||
      // formData.description === "" ||
      formData.minimum_drop === 0 ||
      // formData.attributes.length === 0 ||
      formData.quantity_slab.length === 0 ||
      formData.commission_matrices.length === 0 ||
      formData.quantity_slab.find((slab) => slab.min_value === 0)
    ) {
      return false;
    }
    return true;
  };

  const handleCancelClick = () => {
    onClose();
  };

  const handleSaveClick = () => {
    onClose();
  };

  const getTradeCategories = () => {
    api
      .get(urls.tradeCategoriesList)
      .then((res) => {
        setTradeCategories(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getAttributes = () => {
    api
      .get(urls.attributes)
      .then((res) => {
        setAttributes(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getSettings = () => {
    api
      .get(urls.settingsList)
      .then((res) => {
        setSettings(res.data.data.settings);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getCategoryDetails = () => {
    api
      .get(urls.mainCategoryDetails + data.id)
      .then((res) => {
        setCatDetails(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    if (openMainDialog) {
      getTradeCategories();
      getAttributes();
      getSettings();
      if (isEdit) {
        getCategoryDetails();
      }
    }
  }, [openMainDialog]);

  useEffect(() => {
    if (catDetails) {
      let attributes = catDetails.attributes.map((x) => x.attribute_type.uuid);
      let slabs = [];
      catDetails.quantity_slab.map((slab) => {
        slabs.push({
          id: slab.id,
          min_value: slab.min_value,
          display_name: `${+slab.min_value}`,
        });
      });
      let matrixes = [];
      catDetails.commission_matrices.map((mat) => {
        matrixes.push({
          trade_category_id: mat.trade_category.uuid,
          quantity_slab_id: mat.quantity_slab_id,
          commission_percentage: mat.commission_percentage,
          uuid: mat.uuid,
        });
      });

      matrixes = matrixes.map((mat) => ({
        ...mat,
        quantity_slab_id:
          slabs.findIndex((slab) => slab.id === mat.quantity_slab_id) + 1,
      }));

      slabs = slabs.map((slab, index) => ({
        ...slab,
        id: index + 1,
      }));

      setFormData({
        ...formData,
        attributes: attributes,
        quantity_slab: slabs,
        commission_matrices: matrixes,
      });

      //only for catrgory with no products
      // setCommissionMatrices(catDetails.commission_matrices);
      setAlreadySavedAttributes(attributes);
    }
  }, [catDetails, tradeCategories]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    if (data) {
      console.log(data);
      setFormData((prev) => ({
        ...prev,
        name: data.name,
        description: data.description,
        minimum_drop: data.minimum_drop,
      }));
    }
  }, [data]);

  const handleSlabAdd = () => {
    setFormData((prev) => ({
      ...prev,
      quantity_slab: [
        ...prev.quantity_slab,
        {
          id: prev.quantity_slab.length + 1,
          min_value: 0,
          display_name: "0",
        },
      ],
      commission_matrices: [
        ...prev.commission_matrices,
        ...tradeCategories.map((cat) => ({
          trade_category_id: cat.id,
          quantity_slab_id: prev.quantity_slab.length + 1,
          commission_percentage: 0,
        })),
      ],
    }));
  };

  const handleSlabChange = (value, slabID) => {
    let list = [...formData.quantity_slab];
    list = list.map((slab) => {
      if (slab.id === slabID) {
        slab.min_value = +value;
        slab.display_name = `${+value}`;
      }
      return slab;
    });
    setFormData((prev) => ({ ...prev, quantity_slab: list }));
  };

  const handleSlabDelete = (slabID) => {
    let list = [...formData.quantity_slab];
    list = list.filter((slab) => slab.id !== slabID);
    let list2 = [...formData.commission_matrices];
    list2 = list2.filter((mat) => mat.quantity_slab_id !== slabID);

    list2 = list2.map((mat) => ({
      ...mat,
      quantity_slab_id:
        list.findIndex((slab) => slab.id === mat.quantity_slab_id) + 1,
    }));

    list = list.map((slab, index) => ({
      ...slab,
      id: index + 1,
      // display_name: `${index + 1}`,
    }));

    setFormData((prev) => ({
      ...prev,
      quantity_slab: list,
      commission_matrices: list2,
    }));
  };

  const handleMatrixChange = (value, slabID, catID, uuid) => {
    let list = [...formData.commission_matrices];
    list = list.map((mat) => {
      if (mat.trade_category_id === catID && mat.quantity_slab_id === slabID) {
        mat.commission_percentage = value;
      }
      return mat;
    });
    setFormData((prev) => ({ ...prev, commission_matrices: list }));

    //only for category with products
    setCommissionMatrices((prevMatrices) => {
      const index = prevMatrices.findIndex((matrix) => matrix.uuid === uuid);

      if (index !== -1) {
        // Copy the previous array and update the commission percentage of the found matrix
        const newMatricesArray = [...prevMatrices];
        newMatricesArray[index] = {
          ...newMatricesArray[index],
          commission_percentage: Number(value),
        };
        return newMatricesArray;
      } else {
        // Add a new matrix to the array
        return [
          ...prevMatrices,
          { uuid, commission_percentage: Number(value) },
        ];
      }
    });
  };

  const handleAttributeChange = (attrID) => {
    let list = [...formData.attributes];
    if (list.includes(attrID)) {
      list = list.filter((attr) => attr !== attrID);
    } else {
      list.push(attrID);
    }
    setFormData((prev) => ({ ...prev, attributes: list }));
  };

  const handleUpdateCommissionMatrix = () => {
    let params = {
      commission_matrices: commissionMatrices.map(
        ({ uuid, commission_percentage }) => ({
          commission_matrices_id: uuid,
          commission_percentage,
        })
      ),
    };
    api
      .put(urls.editCommissionMatrices + data.id, params)
      .then((res) => {
        // onClose();
        onSubmit();
        showToast(res.data.message, "success");
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleSubmit = () => {
    let params = { ...formData };
    if (isEdit) {
      api
        .put(urls.editMainCategory + data.id, params)
        .then((res) => {
          // onClose();
          onSubmit();
          showToast(res.data.message, "success");
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            logout();
          } else {
            showToast(err.response?.data?.message, "error");
          }
        });
    } else {
      api
        .post(urls.addMainCategory, params)
        .then((res) => {
          onClose();
          onSubmit();
          showToast(res.data.message, "success");
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            logout();
          } else {
            showToast(err.response?.data?.message, "error");
          }
        });
    }
  };

  return (
    <Dialog
      fullScreen
      open={openMainDialog}
      // onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: theme.palette.black.main,
      //     opacity: 0.7,
      //   },
      // }}
      // sx={{
      //   "& .MuiPaper-root": {
      //     width: "100%",
      //     maxWidth: "750px", // Set your width here
      //   },
      // }}
    >
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: theme.palette.white.main,
          color: theme.palette.black.main,
          fontWeight: 600,
          fontSize: 24,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Divider orientation="vertical" sx={{ paddingLeft: "10px" }} />
          <Typography
            sx={{ ml: 2, flex: 1, fontSize: 24, fontWeight: 600 }}
            variant="h6"
            component="div"
          >
            {`${isEdit ? "Edit" : "Add"} Main Category`}
          </Typography>
          {(!isEdit || (isEdit && !catDetails?.category_has_products)) && (
            <Button
              onClick={handleSubmit}
              variant="contained"
              disableElevation
              color="theme"
              sx={{
                textTransform: "none",
              }}
              disabled={!isValid() || isLoading}
            >
              {isEdit ? "Update" : "Submit"}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {isLoading && <LoaderCard />}
      {!isLoading && (
        <Grid container direction={"row"}>
          <Grid
            item
            xs={4}
            sx={{
              backgroundColor: theme.palette.lightgrey.main,
              p: 5,
              height: { sm: "auto", md: "94dvh" },
            }}
          >
            <Stack direction={"column"} spacing={3}>
              <Box>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Category Name"
                  variant="outlined"
                  sx={{ color: theme.palette.black.main, fontWeight: 500 }}
                  value={formData.name}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      name: e.target.value,
                      description: e.target.value,
                    }))
                  }
                  disabled={isEdit && catDetails?.category_has_products}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Minimum Drop from best price"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {settings?.currency}
                      </InputAdornment>
                    ),
                  }}
                  value={formData.minimum_drop}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
                      setFormData((prev) => ({
                        ...prev,
                        minimum_drop: +e.target.value,
                      }));
                    }
                  }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: 20, fontWeight: 500, mb: -1 }}>
                  Attributes
                </Typography>
              </Box>
              <Box>
                <FormGroup>
                  {attributes?.map((attr, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: theme.palette.popbuttonselected.main,
                            },
                          }}
                        />
                      }
                      label={attr.name}
                      checked={formData.attributes.includes(attr.id)}
                      onChange={() => handleAttributeChange(attr.id)}
                      disabled={alreadySavedAttributes.includes(attr.id)}
                    />
                  ))}
                </FormGroup>
              </Box>
              {isEdit && catDetails?.category_has_products && (
                <Box>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disableElevation
                    color="theme"
                    sx={{
                      textTransform: "none",
                      width: "100px",
                    }}
                    disabled={!isValid() || isLoading}
                  >
                    Update
                  </Button>
                </Box>
              )}
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <Stack sx={{ p: 5 }}>
              <Box>
                <Typography
                  sx={{
                    color: theme.palette.black.main,
                    fontSize: "20px",
                    fontWeight: 500,
                  }}
                >
                  Slabs
                </Typography>
              </Box>
              <Box sx={{ padding: "20px 0px" }}>
                <MainCategorySlab
                  slabs={formData.quantity_slab}
                  onSlabAdd={handleSlabAdd}
                  onSlabChange={handleSlabChange}
                  onSlabDelete={handleSlabDelete}
                  isReadOnly={isEdit && catDetails?.category_has_products}
                />
              </Box>
              {formData.quantity_slab.length > 0 && (
                <Fragment>
                  <Box sx={{ pb: 2 }}>
                    <Typography
                      sx={{
                        color: theme.palette.black.main,
                        fontWeight: 500,
                        fontSize: "20px",
                      }}
                    >
                      Commission Matrix
                    </Typography>
                  </Box>
                  {tradeCategories.map((cat, i) => (
                    <Fragment key={i}>
                      <Box sx={{ pb: 3 }}>
                        <Typography
                          sx={{
                            color: theme.palette.black.main,
                            fontWeight: 500,
                          }}
                        >
                          {cat.name}
                        </Typography>
                      </Box>
                      <Box sx={{ pb: 3 }}>
                        <MainCategoryMatrix
                          matrices={formData.commission_matrices.filter(
                            (x) => x.trade_category_id === cat.id
                          )}
                          onMatrixChange={handleMatrixChange}
                        />
                      </Box>
                    </Fragment>
                  ))}
                  {isEdit && catDetails?.category_has_products && (
                    <Box>
                      <Button
                        onClick={handleUpdateCommissionMatrix}
                        variant="contained"
                        disableElevation
                        color="theme"
                        sx={{
                          textTransform: "none",
                          width: "100px",
                        }}
                        disabled={isLoading}
                      >
                        Update
                      </Button>
                    </Box>
                  )}
                </Fragment>
              )}
            </Stack>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

AddMainCategory.propTypes = {
  openMainDialog: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  brandID: PropTypes.string,
  isEdit: PropTypes.bool,
  data: PropTypes.object,
};
