import { useTheme } from "@emotion/react";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";

export default function EditQuantity(props) {
  const { openEditQuantityDialog, onClose, orderID, oldQuantity, onSubmit } =
    props;
  const theme = useTheme();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [quantityField, setQuantityField] = useState(0);

  const handleCancelClick = () => {
    onClose();
  };
  const handleConfirmClick = () => {
    onClose();
  };

  const updateQuantity = () => {
    let params = {
      seller_order_details_id: orderID,
      quantity: quantityField,
    };
    api
      .post(urls.updateOrderQuantity, params)
      .then((res) => {
        showToast(res.data.message, "success");
        onSubmit();
        onClose();
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  return (
    <Dialog
      open={openEditQuantityDialog}
      onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: theme.palette.black.main,
      //     opacity: 0.2,
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 350, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Edit Quantity
        </Typography>
        <Divider sx={{ mt: 2 }} />

        <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
          {" "}
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            sx={{
              bgcolor: theme.palette.lightgrey.main,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      "&.MuiTypography-root": {
                        color: theme.palette.black.main,
                        fontWeight: 700,
                        fontSize: 24,
                        borderRight: "1px solid black",
                        paddingRight: "10px",
                      },
                    }}
                  >
                    {oldQuantity}
                  </Typography>
                </InputAdornment>
              ),
            }}
            value={quantityField}
            onChange={(e) => {
              let inputValue = e.target.value;
              if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
                // if (+inputValue > oldQuantity) {
                //   return;
                // }
                setQuantityField(+inputValue);
              }
            }}
          />
        </Box>
        <Stack sx={{ mt: 4, ml: 4, mr: 4 }}>
          <Grid container>
            <Grid item xs>
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={handleCancelClick}
                  variant="contained"
                  disableElevation
                  color="lightTheme"
                  sx={{
                    width: "100%",
                    height: 45,
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => updateQuantity()}
                  variant="contained"
                  disableElevation
                  color="theme"
                  sx={{
                    width: "100%",
                    height: 45,
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                  // disabled={quantityField === 0}
                  disabled={isLoading}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Dialog>
  );
}

EditQuantity.propTypes = {
  openEditQuantityDialog: PropTypes.bool,
  onClose: PropTypes.func,
  orderID: PropTypes.string,
  onSubmit: PropTypes.func,
  oldQuantity: PropTypes.number,
};
