import { useTheme } from "@emotion/react";
import { Box, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

export default function MainCategoryMatrix(props) {
  const { matrices, onMatrixChange } = props;

  return (
    <Box>
      <Grid container direction={"row"} spacing={2}>
        {matrices?.map((mat, i) => (
          <Grid item key={i} xs={4} sm={3} md={2} lg={1.5} xl={1.5}>
            <TextField
              id="outlined-basic"
              label={`Slab ${mat.quantity_slab_id}`}
              variant="outlined"
              defaultValue="0"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              value={mat.commission_percentage}
              onChange={(e) => {
                let inputValue = e.target.value;

                // Regex to allow up to two decimal places and handle initial dot input
                if (
                  inputValue === "" ||
                  /^\d{1,3}(\.\d{0,2})?$/.test(inputValue) ||
                  inputValue === "."
                ) {
                  if (parseFloat(inputValue) > 100) {
                    return;
                  }
                  console.log(parseFloat(inputValue));
                  onMatrixChange(
                    inputValue === "" ? 0 : inputValue,
                    mat.quantity_slab_id,
                    mat.trade_category_id,
                    mat.uuid
                  );
                }
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

MainCategoryMatrix.propTypes = {
  matrices: PropTypes.array,
  onMatrixChange: PropTypes.func,
};
