import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { CoverUploader } from "../uploaders/CoverUploader";
import { StockDropDown } from "../dropdown/StockDropDown";
import { sampleSelectItems } from "../../utilities";
import { dropDownArrow, userPlaceholder } from "../../assets";
import { countries } from "../../utilities/other";
import PropTypes from "prop-types";
import { useAPI } from "../../hooks/useAPI";
import { useToast, useAuth } from "../../context";
import { LoaderCard } from "../loaders/LoaderCard";

export const AddProducts = (props) => {
  const theme = useTheme();
  const { openProductDialog, onClose, catID, mainCatID, onSubmit } = props;
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const [countryMenuAnchorEl, setCountryMenuAnchorEl] = useState(null);
  const [countrySelectedMenu, setCountrySelectedMenu] = useState("");
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const [settings, setSettings] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const [formData, setFormData] = useState({
    model_number: "",
    name: "",
    upc: "",
    category_id: catID,
    attribute: [],
    min_price: 0,
    max_price: 0,
  });

  const isValid = () => {
    if (
      formData.model_number === "" ||
      formData.name === "" ||
      formData.upc === "" ||
      formData.min_price === 0 ||
      formData.max_price === 0 ||
      formData.min_price >= formData.max_price ||
      formData.attribute.filter((attr) => attr === "").length > 0
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  console.log(mainCatID);

  const getCategoryAttributes = () => {
    api
      .get(urls.categoryAttributes + mainCatID)
      .then((res) => {
        setCategoryAttributes(res.data.data);
        let list = Array(res.data.data.length).fill("");
        setFormData((prev) => ({
          ...prev,
          attribute: list,
        }));
        setIsReady(true);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
        setIsReady(true);
      });
  };

  const getSettings = () => {
    api
      .get(urls.settingsList)
      .then((res) => {
        setSettings(res.data.data.settings);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    if (openProductDialog) {
      getCategoryAttributes();
      getSettings();
    }
  }, [openProductDialog]);

  const handleCancelClick = () => {
    onClose();
  };

  const handleConfirmClick = () => {
    let params = { ...formData };
    api
      .post(urls.addProduct, params)
      .then((res) => {
        onClose();
        onSubmit();
        showToast(res.data.message, "success");
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };
  const handleCountryMenuClick = (event) => {
    setCountryMenuAnchorEl(event.currentTarget);
  };
  const handleCountryMenuItemClick = (value) => {
    setCountrySelectedMenu(value);
    handleCountryMenuClose();
  };

  const handleCountryMenuClose = () => {
    setCountryMenuAnchorEl(null);
  };

  const handleAttributeChange = (attrID, index) => {
    let list = [...formData.attribute];
    list[index] = attrID;
    setFormData((prev) => ({ ...prev, attribute: list }));
  };

  return (
    <Dialog
      maxWidth="md"
      open={openProductDialog}
      // onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: theme.palette.black.main,
      //     opacity: 0.7,
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 800, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Add Product
        </Typography>
        <Divider sx={{ mt: 2 }} />
        {!isReady ? (
          <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
            <LoaderCard />
          </Box>
        ) : (
          <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Product Name"
                  variant="outlined"
                  value={formData.name}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }));
                  }}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Model Number"
                  variant="outlined"
                  value={formData.model_number}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      model_number: e.target.value,
                    }));
                  }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  // type="number"
                  label="Min price"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {settings?.currency}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                    "& input[type=number]::-webkit-outer-spin-button": {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& input[type=number]::-webkit-inner-spin-button": {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                  }}
                  value={formData.min_price}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
                      setFormData((prev) => ({
                        ...prev,
                        min_price: +e.target.value,
                      }));
                    }
                  }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  // type="number"
                  id="outlined-basic"
                  label="Max price"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {settings?.currency}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                    "& input[type=number]::-webkit-outer-spin-button": {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& input[type=number]::-webkit-inner-spin-button": {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                  }}
                  value={formData.max_price}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
                      setFormData((prev) => ({
                        ...prev,
                        max_price: +e.target.value,
                      }));
                    }
                  }}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="UPC"
                  variant="outlined"
                  value={formData.upc}
                  onChange={(e) => {
                    setFormData((prev) => ({ ...prev, upc: e.target.value }));
                  }}
                  required
                />
              </Grid>
              {categoryAttributes.map((attr, i) => (
                <Grid item xs={6} key={i}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">
                      {attr?.attribute_type?.name}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={colour}
                      label={attr?.attribute_type?.name}
                      onChange={(e) => handleAttributeChange(e.target.value, i)}
                    >
                      {attr?.attribute_type?.attribute_description.map(
                        (desc, j) => (
                          <MenuItem key={j} value={desc.id}>
                            {desc.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Grid container sx={{ pt: 1 }}>
                  <Grid item xs={6}>
                    <Stack direction="row" spacing={2}>
                      <Button
                        onClick={handleCancelClick}
                        variant="contained"
                        disableElevation
                        color="lightTheme"
                        sx={{
                          width: "100%",
                          height: 45,
                          fontSize: 16,
                          fontWeight: 500,
                          textTransform: "none",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleConfirmClick}
                        variant="contained"
                        disableElevation
                        color="theme"
                        sx={{
                          width: "100%",
                          height: 45,
                          fontSize: 16,
                          fontWeight: 500,
                          textTransform: "none",
                        }}
                        disabled={!isValid() || isLoading}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Stack>
    </Dialog>
  );
};

AddProducts.propTypes = {
  openProductDialog: PropTypes.bool,
  onClose: PropTypes.func,
  catID: PropTypes.string,
  mainCatID: PropTypes.string,
  onSubmit: PropTypes.func,
};
