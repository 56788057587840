import {
  Box,
  Divider,
  Stack,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { TickCircle, ArrowDown2 } from "iconsax-react";
import { OrderProgressItemCard } from "./OrderProgressItemCard";
import { documentStatuses } from "../../utilities";
import PropTypes from "prop-types";
import { ExpandMore } from "@mui/icons-material";

export const OrderProgressCard = (props) => {
  const { logs } = props;
  const theme = useTheme();

  return (
    <Stack direction={"column"}>
      <Box
        sx={{
          bgcolor: theme.palette.buyerAccessBg.main,
          p: 2,
          borderRadius: 2,
        }}
      >
        <Accordion
          defaultExpanded
          elevation={0}
          sx={{ bgcolor: theme.palette.buyerAccessBg.main }}
          disableGutters
        >
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ p: 0 }}>
            <Stack sx={{ width: "100%" }} spacing={1}>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 18,
                  fontWeight: 500,
                }}
              >
                Status
              </Typography>
              <Divider />
            </Stack>
          </AccordionSummary >
          <AccordionDetails sx={{ p: 0 }}>
            <Stack
              direction={"column"}
              sx={{
                bgcolor: theme.palette.orderProgressBg.main,
                borderRadius: 2,
                p: 2,
                mt: 2,
              }}
            >
              {logs.map((log) => (
                <OrderProgressItemCard documentItem={log} />
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
        {/* <Stack direction={"column"}>
          <Stack direction={"column"}>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: 18,
                fontWeight: 500,
              }}
            >
              Status
            </Typography>
            <Divider />
            <Stack
              direction={"column"}
              sx={{
                bgcolor: theme.palette.orderProgressBg.main,
                borderRadius: 2,
                p: 2,
                mt: 2,
              }}
            >
              {logs.map((log) => (
                <OrderProgressItemCard documentItem={log} />
              ))}
            </Stack>
          </Stack>
        </Stack> */}
      </Box>
    </Stack>
  );
};

OrderProgressCard.propTypes = {
  logs: PropTypes.array,
};
