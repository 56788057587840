import { Grid, useTheme } from "@mui/material";
import { Fragment, useState } from "react";
import OrdersHeader from "../../components/headers/OrdersHeader";
import SellersOrdersTab from "../../components/tabs/SellersOrdersTab";
import { useLocation } from "react-router-dom";

export const SellersOrders = () => {
  const [orderData, setOrderData] = useState(null);
  const location = useLocation();

  return (
    <Fragment>
      <Grid container direction={"column"}>
        <Grid item sx={{ marginRight: 2 }}>
          <OrdersHeader
            title={`Sellers / ${location.state?.name ?? ""}`}
            companytitle={location.state?.name ?? ""}
            companyid={
              location.state?.code ? ` (${location.state?.code ?? ""})` : ""
            }
            orderData={orderData}
          />
          <SellersOrdersTab
            onOrdersChange={(data) => setOrderData({ ...orderData, ...data })}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
