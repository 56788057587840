import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
} from "@mui/material";

export const NotificationSettingsRadio = ({ value, onChange }) => {
  const theme = useTheme();

  return (
    <FormControl>
      <RadioGroup row name="row-radio-buttons-group" value={value} onChange={onChange}>
        <FormControlLabel
          value="all"
          control={
            <Radio
              sx={{
                color: theme.palette.theme.main,
                "&.Mui-checked": {
                  color: theme.palette.theme.main,
                },
              }}
            />
          }
          label={
            <span
              style={{
                fontSize: "16px",
                fontWeight: 500,
                color: theme.palette.primary.main,
              }}
            >
              All
            </span>
          }
        />
        <FormControlLabel
          value="3"
          control={
            <Radio
              color="theme"
              sx={{
                color: theme.palette.theme.main,
                "&.Mui-checked": {
                  color: theme.palette.theme.main,
                },
              }}
            />
          }
          label={
            <span
              style={{
                fontSize: "16px",
                fontWeight: 500,
                color: theme.palette.primary.main,
              }}
            >
              Buyers
            </span>
          }
        />
        <FormControlLabel
          value="2"
          control={
            <Radio
              color="theme"
              sx={{
                color: theme.palette.theme.main,
                "&.Mui-checked": {
                  color: theme.palette.theme.main,
                },
              }}
            />
          }
          label={
            <span
              style={{
                fontSize: "16px",
                fontWeight: 500,
                color: theme.palette.primary.main,
              }}
            >
              Sellers
            </span>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};
