import { Grid, useTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { currencyFormatter } from "../../utilities/extensions";

export const MultiLineChart = (props) => {
  const { type, data } = props;
  const theme = useTheme();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currency, setCurrency] = useState("");

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Event listener to update windowWidth on resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrency(data[0].currency ?? "");
  }, [data]);

  return (
    <Grid container xs>
      <LineChart
        xAxis={[
          {
            scaleType: "point",
            data:
              type === 0
                ? data.map((item) =>
                    moment(item.date, "YYYY-MM-DD").format("ddd")
                  )
                : type === 1
                ? data.map((item) =>
                    moment(item.date, "YYYY-MM-DD").format("D MMM")
                  )
                : data.map((item) =>
                    moment(item.date, "YYYY-MM-DD").format("MMM YY")
                  ),
            tickLabelInterval: (value, index) =>
              type === 1 ? index % 2 === 0 : true,
          },
        ]}
        series={[
          {
            curve: "linear",
            // data:
            //   type === 0
            //     ? data.map((item) => item.total_sales)
            //     : type === 1
            //     ? data.map((item) => item.total_sales)
            //     : data.map((item) => item.total_sales),
            // label: "iPhone 15",
            color: theme.palette.secondary.main,
            data: data.map((item) => item.total_sales),
            valueFormatter: (value) => currencyFormatter(value, currency),
          },
          // {
          //   curve: "linear",
          //   data:
          //     type === 0
          //       ? [
          //           0, 6, 4, 1, 3, 2, 3, 3, 6, 8, 5, 4, 2, 6, 3, 2, 1, 5, 3, 2,
          //           4, 4, 2, 3, 1,
          //         ]
          //       : type === 1
          //       ? [
          //           0, 2, 3, 3, 1, 2, 4, 5, 6, 8, 3, 2, 3, 2, 5, 6, 1, 3, 4, 2,
          //           5, 3, 4, 1, 5, 8, 6, 2, 4, 3, 2, 1,
          //         ]
          //       : [0, 4, 1, 5, 6, 1, 2, 3, 2, 8, 4, 5, 1],
          //   //label: "iphone 15 Max",
          //   color: theme.palette.theme.main,
          // },
        ]}
        width={windowWidth - 160}
        px
        height={300}
      />
    </Grid>
  );
};

MultiLineChart.propTypes = {
  type: PropTypes.number,
  data: PropTypes.array,
};
