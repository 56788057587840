import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import placeHolder from "../../assets/placeholders/emp_data.svg";

export const EmptyCard = (props) => {
  const theme = useTheme();
  const { text, height } = props;
  return (
    <Box
      sx={{
        border: 1,
        borderColor: theme.palette.line.main,
        borderRadius: 2,
        p: 2,
      }}
    >
      <Stack
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ minHeight: height }}
      >
        <img src={placeHolder} width={"200px"} height={"200px"} />
        <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
          {text}
        </Typography>
      </Stack>
    </Box>
  );
};

EmptyCard.propTypes = {
  text: PropTypes.string,
  height: PropTypes.any,
};

EmptyCard.defaultProps = {
  text: "No data found",
  height: 250,
};
