import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  TextField,
  useTheme,
  Button,
} from "@mui/material";
import { CloseCircle } from "iconsax-react";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { Add } from "iconsax-react";

export default function MainCategorySlab(props) {
  const { slabs, onSlabAdd, onSlabChange, onSlabDelete, isReadOnly } = props;
  const theme = useTheme();

  return (
    <Box>
      <Grid container spacing={2}>
        {slabs.map((slab, index) => (
          <Grid item key={index} xs={4} sm={3} md={2} lg={1.5} xl={1.5}>
            <Box sx={{ position: "relative" }}>
              <TextField
                id="outlined-basic"
                label={`Slab ${slab.id}`}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Typography color={isReadOnly ? "#AAA2B6" : "#000000"}>
                        +
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                value={slab.min_value}
                onChange={(e) => {
                  let inputValue = e.target.value;
                  if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
                    if (+inputValue > 999999) {
                      return;
                    }
                    onSlabChange(inputValue, slab.id);
                  }
                }}
                disabled={isReadOnly}
              />
              {!isReadOnly && (
                <Box
                  sx={{
                    top: -5,
                    right: -5,
                    position: "absolute",
                    cursor: "pointer",
                    boxShadow: 1,
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // zIndex: 9999,
                    backgroundColor: theme.palette.white.main,
                  }}
                  onClick={() => onSlabDelete(slab.id)}
                >
                  <CloseIcon
                    sx={{
                      fontSize: "medium",
                      backgroundColor: theme.palette.white.main,
                      borderRadius: "50%",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          padding: "10px 0px 40px 0px",
          borderBottom: "1px solid #F0F0F0",
        }}
      >
        {!isReadOnly && (
          <Button
            sx={{
              mt: 1,
              width: 120,
              padding: "10px 15px",
              borderRadius: "4px",
              color: theme.palette.popbuttonselected.main,
              "&:hover": {
                backgroundColor: theme.palette.white.main, // Color for the selected text
              },
              textTransform: "none",
              "&.MuiButton-outlined": {
                borderColor: theme.palette.popbuttonselected.main,
              },
            }}
            variant="outlined"
            startIcon={
              <Add size="20" color={theme.palette.popbuttonselected.main} />
            }
            onClick={onSlabAdd}
          >
            Add Slab
          </Button>
        )}
      </Box>
    </Box>
  );
}

MainCategorySlab.propTypes = {
  slabs: PropTypes.array,
  onSlabAdd: PropTypes.func,
  onSlabChange: PropTypes.func,
  onSlabDelete: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

MainCategorySlab.defaultProps = {
  isReadOnly: false,
};
