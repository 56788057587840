import {
  Backdrop,
  Button,
  Dialog,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";

export const ReminderDialog = (props) => {
  const { open, onClose, onConfirm, header, reminder, confirm } = props;

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: "rgba(0, 0, 0, 0.2)",
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {header}
        </Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 16,
            fontWeight: 400,
            textAlign: "center",
            pl: 4,
            pr: 4,
            pt: 2,
            pb: 2,
          }}
        >
          {reminder}
        </Typography>
        <Stack direction={"row"} sx={{ mt: 2, ml: 4, mr: 4, gap: 2 }}>
          <Button
            onClick={() => onClose()}
            variant="contained"
            disableElevation
            color="lightTheme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onConfirm()}
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            {confirm}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

ReminderDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  header: PropTypes.string,
  description: PropTypes.string,
};
