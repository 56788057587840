import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Fragment, useState } from "react";
import { sampleUserSettingsItems } from "../../utilities";
import { AddUser } from "../alert/AddUser";

export const UserSettingsTable = () => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAddUserDialog, setOpenAddUserDialog] = useState();

  const headerStyle = {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenAddUserDialog = () => {
    setOpenAddUserDialog(true);
  };

  const handleCloseAddUserDialog = () => {
    setOpenAddUserDialog(false);
  };

  return (
    <Fragment>
      <Box
        sx={{
          pl: 3,
          pr: 3,
          border: 1,
          borderColor: theme.palette.line.main,
          borderRadius: 2,
        }}
      >
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow sx={{ height: 70 }}>
                <TableCell sx={headerStyle}>User No.</TableCell>
                <TableCell sx={headerStyle}>Name</TableCell>
                <TableCell sx={headerStyle}>Username</TableCell>
                <TableCell sx={headerStyle}>Password</TableCell>
                <TableCell sx={headerStyle}>Role</TableCell>
                <TableCell align="right" sx={headerStyle}>
                  <Button
                    onClick={handleOpenAddUserDialog}
                    variant="contained"
                    disableElevation
                    color="theme"
                    style={{ textTransform: "none" }}
                    sx={{ fontSize: 14, fontWeight: 500 }}
                  >
                    Add User
                  </Button>
                  <AddUser
                    openAddUserDialog={openAddUserDialog}
                    onClose={handleCloseAddUserDialog}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sampleUserSettingsItems.map((item, index) => (
                <TableRow
                  key={item.id}
                  //sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{ verticalAlign: "center", textAlign: "left" }}
                    sx={{ width: 100 }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{ verticalAlign: "center", textAlign: "left" }}
                    sx={{ width: 220 }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {item.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{ verticalAlign: "center", textAlign: "left" }}
                    sx={{ width: 180 }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {item.username}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{ verticalAlign: "center", textAlign: "left" }}
                    sx={{ width: 180 }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {item.password}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{ verticalAlign: "center", textAlign: "left" }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {item.role}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{ verticalAlign: "center", textAlign: "right" }}
                    sx={{ width: 120 }}
                  >
                    <Button
                      variant="text"
                      color="error"
                      style={{ textTransform: "none" }}
                      sx={{ fontSize: 16, fontWeight: 500 }}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={50}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ mt: 1, mb: 1 }}
        />
      </Box>
    </Fragment>
  );
};
