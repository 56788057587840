import {
  Backdrop,
  Button,
  Dialog,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
  Box,
  InputAdornment,
} from "@mui/material";
import PropTypes from "prop-types";
import { AddPaymentRadio } from "../radio/AddPaymentRadio";
import { useState, useEffect } from "react";
import { useAPI } from "../../hooks/useAPI";
import { useAuth, useToast } from "../../context";

export const AddPayment = (props) => {
  const theme = useTheme();
  const {
    openAddPaymentDialog,
    onClose,
    type,
    onSubmit,
    buyerSellerID,
    currency,
  } = props;
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [formData, setFormData] = useState({
    seller_buyer: buyerSellerID ?? "",
    payment_type: "",
    amount: 0,
    description: "",
    credit: false,
  });

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    if (type === "admin") {
      setFormData({
        ...formData,
        credit: true,
        payment_type: "Capital",
      });
    }
  }, [type]);

  const dropdownMenu = [
    {
      id: "1",
      label: "Miscellaneous Charges",
    },
    {
      id: "2",
      label: "Penalty",
    },
    {
      id: "3",
      label: "Refund",
    },
    {
      id: "4",
      label: "Deposit",
    },
  ];

  const handleCancelClick = () => {
    onClose();
  };

  const handleConfirmClick = () => {
    let params = { ...formData };
    api
      .post(urls.addPayment, params)
      .then((res) => {
        onClose();
        onSubmit();
        showToast(res.data.message, "success");
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  return (
    <Dialog
      open={openAddPaymentDialog}
      onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: "rgba(0, 0, 0, 0.2)",
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Add Payment
        </Typography>
        <Divider sx={{ mt: 2 }} />
        <TextField
          select
          label="Payment Type"
          // defaultValue="1"
          value={formData.payment_type}
          onChange={(e) =>
            setFormData({ ...formData, payment_type: e.target.value })
          }
          inputProps={{
            sx: {
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.primary.main,
            },
          }}
          sx={{ mt: 4, ml: 4, mr: 4 }}
          disabled={type === "admin"}
        >
          {type === "admin" ? (
            <MenuItem key={"1"} value={"Capital"}>
              {"Capital"}
            </MenuItem>
          ) : (
            dropdownMenu.map((option) => (
              <MenuItem key={option.id} value={option.label}>
                {option.label}
              </MenuItem>
            ))
          )}
        </TextField>
        <Box sx={{ ml: 4, mt: 2 }}>
          <AddPaymentRadio
            value={formData.credit ? "credit" : "debit"}
            onChange={(e) =>
              setFormData({ ...formData, credit: e.target.value === "credit" })
            }
            // disabled={type === "admin"}
          />
        </Box>
        <TextField
          label="Amount"
          // type="text"
          variant="outlined"
          inputProps={{
            sx: {
              fontSize: 16,
              fontWeight: 500,
              color: theme.palette.primary.main,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{currency}</InputAdornment>
            ),
          }}
          sx={{ mt: 3, ml: 4, mr: 4 }}
          value={formData.amount}
          onChange={(e) => {
            let inputValue = e.target.value;
            if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
              setFormData((prev) => ({
                ...prev,
                amount: +e.target.value,
              }));
            }
          }}
        />
        <TextField
          label="Description"
          type="text"
          variant="outlined"
          inputProps={{
            sx: {
              fontSize: 16,
              fontWeight: 500,
              color: theme.palette.primary.main,
            },
          }}
          sx={{ mt: 3, ml: 4, mr: 4 }}
          value={formData.description}
          onChange={(e) => {
            setFormData((prev) => ({
              ...prev,
              description: e.target.value,
            }));
          }}
        />
        <Stack direction={"row"} sx={{ mt: 3, ml: 4, mr: 4, gap: 2 }}>
          <Button
            onClick={handleCancelClick}
            variant="contained"
            disableElevation
            color="lightTheme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClick}
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
            disabled={
              !formData.payment_type ||
              !formData.amount ||
              !formData.description ||
              isLoading
            }
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

AddPayment.propTypes = {
  openAddPaymentDialog: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string,
  onSubmit: PropTypes.func,
  buyerSellerID: PropTypes.string,
  currency: PropTypes.string,
};
