import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid, Stack } from "@mui/material";
import deleteIcon from "../../icons/deleteicon.svg";
import { useTheme } from "@emotion/react";

export default function ScannedItemsCard(props) {
  const { data, onDelete } = props;

  const theme = useTheme();

  return (
    <Box justifyContent={"center"} display={"flex"} width={"98%"}>
      <Stack
        width={"88%"}
        direction={"column"}
        sx={{ bgcolor: theme.palette.white.main, borderRadius: 2, p: 2 }}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography sx={{ fontWeight: 500, fontSize: 16 }}>
            {data.product_name.name}
          </Typography>
          <Box
            width={40}
            height={40}
            onClick={() => {
              onDelete(data.id);
            }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
          >
            <img src={deleteIcon} />
          </Box>
        </Stack>
        <Grid container direction={"row"} spacing={2}>
          <Grid item>
            <Typography
              sx={{
                fontSize: "12px",
                padding: "15px 0px 10px 0px",
                color: theme.palette.grey.main,
              }}
            >
              SERIAL NO.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: theme.palette.black.main,
              }}
            >
              {data.serial_no}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: "12px",
                padding: "15px 0px 10px 0px",
                color: theme.palette.grey.main,
              }}
            >
              UPC NO.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: theme.palette.black.main,
              }}
            >
              {data.upc}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
