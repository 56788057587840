import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { Add } from "iconsax-react";
import { SimpleDropDown } from "../dropdown/SimpleDropDown";
import { AddProducts } from "../alert/AddProducts";
import { EditProduct } from "../alert/EditProduct";
import { useAPI } from "../../hooks/useAPI";
import { useToast, useAuth } from "../../context";
import { ProductCard } from "../cards/ProductCard";
import { useModal } from "../../hooks";
import ProductHideUnhideMenu from "./ProductHideUnhideMenu";
import { ReminderDialog } from "../dialogs/ReminderDialog";

export const ProductMenu = (props) => {
  const {
    data,
    catID,
    mainCatID,
    onProductsChange,
    isParentHidden,
    onProductChange,
    showButton,
  } = props;
  const theme = useTheme();
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [openProductDialog, setOpenProductDialog] = useState(false);
  const [hideProduct, setHideProduct] = useState(null);
  const [isOpenHideDialog, openHideDialog, closeHideDialog] = useModal();
  const [isOpenReactivateDialog, openReactivateDialog, closeReactivateDialog] =
    useModal();
  const [editProduct, setEditProduct] = useState(null);
  const [isOpenEditDialog, openEditDialog, closeEditDialog] = useModal();

  useEffect(() => {
    getCategoryAttributes();
  }, []);

  const getCategoryAttributes = useCallback(() => {
    api
      .get(urls.categoryAttributes + mainCatID)
      .then((res) => {
        setCategoryAttributes(res.data.data);
        setFilterOptions(Array(res.data.data.length).fill(""));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  }, [api, urls.categoryAttributes, mainCatID, logout, showToast]);

  const handleOpenNewProduct = () => {
    setOpenProductDialog(true);
  };

  const handleCloseNewProduct = () => {
    setOpenProductDialog(false);
  };

  const handleCloseEditProduct = () => {
    closeEditDialog();
  };

  const handleHideProduct = () => {
    api
      .post(urls.hideProduct + hideProduct.id)
      .then((res) => {
        showToast(res.data.message, "success");
        onProductChange(true); // true to clear prodCategory
        closeHideDialog();
        setHideProduct(null);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleUnhideProduct = () => {
    api
      .put(urls.unHideProduct + hideProduct.id)
      .then((res) => {
        showToast(res.data.message, "success");
        onProductChange(true); // true to clear prodCategory
        closeReactivateDialog();
        setHideProduct(null);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const filteredData = useMemo(() => {
    let array = [...data];
    let options = filterOptions.filter((x) => x !== "");
    let list = [];
    if (options.length > 0) {
      options.forEach((opt, i) => {
        if (i === 0) {
          let items = array.filter((item) =>
            item.attribute_description.find((x) => x.id === opt)
          );
          list = [...items];
        } else {
          let items = list.filter((item) =>
            item.attribute_description.find((x) => x.id === opt)
          );
          list = [...items];
        }
      });
      return list;
    } else {
      return array;
    }
  }, [data, filterOptions]);

  return (
    <Stack
      direction={"column"}
      sx={{
        bgcolor: theme.palette.menuHeader.main,
        borderRadius: 2,
        minWidth: 300,
        height: "88vh",
      }}
    >
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontSize: 16,
          fontWeight: 600,
          p: "12px",
        }}
      >
        Products
      </Typography>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Stack
          direction={"row"}
          sx={{
            height: 40,
            bgcolor: theme.palette.menuSelDark.main,
            borderRadius: 2,
            mb: 1,
          }}
        >
          {isLoading && categoryAttributes.length === 0 && (
            <CircularProgress
              size={25}
              sx={{ ml: 2, mt: 1, color: "blue.main" }}
            />
          )}
          {categoryAttributes.map((attr, i) => (
            <Fragment key={i}>
              <SimpleDropDown
                placeholderText={attr.attribute_type?.name}
                options={attr.attribute_type?.attribute_description ?? []}
                onChange={(id) =>
                  setFilterOptions((prev) => {
                    const curr = [...prev];
                    curr[i] = id;
                    return curr;
                  })
                }
              />
              {i !== categoryAttributes.length - 1 && (
                <Divider orientation="vertical" />
              )}
            </Fragment>
          ))}
        </Stack>
      </Box>
      <List
        sx={{
          height: "100%",
          mt: "-12px",
          flexWrap: "inherit",
          overflowX: "scroll",
        }}
      >
        {filteredData.map((prod, i) => (
          <ListItem
            key={i}
            secondaryAction={
              isParentHidden || !showButton ? null : (
                <ProductHideUnhideMenu
                  onDeleteClick={() => {
                    setHideProduct(prod);
                    // if (isDeactivated === true) {
                    //   //Already deactivated, so reactivate
                    //   openReactivateDialog();
                    // } else {
                    //   openHideDialog();
                    // }
                    if (prod.status === true) {
                      //activated
                      openHideDialog();
                    } else {
                      //deactivated
                      openReactivateDialog();
                    }
                  }}
                  isHiddenItem={!prod.status}
                  onEditClick={() => {
                    setEditProduct(prod);
                    openEditDialog();
                  }}
                />
              )
            }
            sx={{ p: 0 }}
          >
            {/* Assuming ProductCard is optimized */}
            <Stack direction="column" sx={{ width: "100%", mr: 4 }}>
              <ProductCard
                name={`${i + 1}. ${prod.name}`}
                isDisabled={isParentHidden || !prod.status}
              />
              <Divider sx={{ mt: "12px" }} />
            </Stack>
          </ListItem>
        ))}
      </List>
      <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
        {!isParentHidden && showButton && (
          <Button
            variant="contained"
            disableElevation
            color="theme"
            startIcon={<Add />}
            style={{ textTransform: "none", width: "100%", height: 45 }}
            onClick={handleOpenNewProduct}
          >
            Add Product
          </Button>
        )}
        {openProductDialog && (
          <AddProducts
            openProductDialog={openProductDialog}
            onClose={handleCloseNewProduct}
            catID={catID}
            mainCatID={mainCatID}
            onSubmit={onProductsChange}
          />
        )}
      </Box>
      {isOpenHideDialog && (
        <ReminderDialog
          open={isOpenHideDialog}
          onClose={() => {
            closeHideDialog();
            setHideProduct(null);
          }}
          onConfirm={handleHideProduct}
          header={"Hide"}
          reminder={"Are you sure to hide this product?"}
          confirm={"Hide"}
        />
      )}
      {isOpenReactivateDialog && (
        <ReminderDialog
          open={isOpenReactivateDialog}
          onClose={closeReactivateDialog}
          onConfirm={handleUnhideProduct}
          header={"Unhide"}
          reminder={"Are you sure to unhide this product?"}
          confirm={"Unhide"}
        />
      )}
      {isOpenEditDialog && (
        <EditProduct
          openProductDialog={isOpenEditDialog}
          onClose={handleCloseEditProduct}
          catID={catID}
          product={editProduct}
          mainCatID={mainCatID}
          onSubmit={onProductsChange}
        />
      )}
    </Stack>
  );
};

ProductMenu.propTypes = {
  data: PropTypes.array,
  catID: PropTypes.string,
  mainCatID: PropTypes.string,
  onProductsChange: PropTypes.func,
  isParentHidden: PropTypes.bool,
  onProductChange: PropTypes.func,
};

ProductMenu.defaultProps = {
  isParentHidden: false,
};
