import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { SellHeader } from "../../components/headers/SellHeader";
import { SellTable } from "../../components/table/SellTable";
import api from "../../service/api";
import urls from "../../service/urls";
import { useAuth, useToast } from "../../context";
import { LoaderCard } from "../../components/loaders/LoaderCard";
import { EmptyCard } from "../../components/emptyCard/EmptyCard";
import { useSellStore } from "../../store/store";

export const SellScreen = () => {
  const theme = useTheme();

  const { logout } = useAuth();
  const { showToast } = useToast();

  // const [sellList, setSellList] = useState([]);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);

  // const [status, setStatus] = useState("");
  // const [date, setDate] = useState("");
  // const [search, setSearch] = useState("");
  const [currency, setCurrency] = useState("");

  const {
    sellList,
    setSellList,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    setTotalCount,
    status,
    setStatus,
    search,
    setSearch,
    date,
    setDate,
    isFirstLoad,
    setIsFirstLoad,
    shouldReload,
    setShouldReload,
  } = useSellStore((state) => state);

  const getsellList = () => {
    let params = {};

    if (date === "") {
      params = {
        page: page + 1,
        limit: rowsPerPage,
        search_keyword: search,
        status: status,
      };
    } else {
      params = {
        page: page + 1,
        limit: rowsPerPage,
        search_keyword: search,
        status: status,
        from_date: date.format("YYYY-MM-DD"),
        to_date: date.format("YYYY-MM-DD"),
      };
    }

    api
      .get(urls.sellTableList, {
        params: params,
      })
      .then((res) => {
        setSellList(res.data.data.serializer);
        setTotalCount(res.data.data.pagination.totalOrders);
        setCurrency(res.data.data.currency);
        setIsFirstLoad(false);
        setShouldReload(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isFirstLoad) {
      getsellList();
    }
  }, []);

  useEffect(() => {
    if (shouldReload) {
      setLoading(true);
      getsellList();
    }
  }, [shouldReload]);

  return (
    <Fragment>
      <Stack direction={"column"} sx={{ pr: 2 }}>
        <SellHeader
          date={date}
          onSetDate={(value) => {
            setDate(value);
            setPage(0);
            setShouldReload(true);
          }}
          onSetStatus={(value) => {
            setStatus(value);
            setPage(0);
            setShouldReload(true);
          }}
          onSetSearch={(value) => {
            setSearch(value);
            setPage(0);
            setShouldReload(true);
          }}
          status={status}
        />
        <Divider sx={{ mt: 2 }} />
        <Typography
          sx={{
            mt: 3,
            mb: 3,
            color: theme.palette.tertiary.main,
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          Showing All Results
        </Typography>
        {loading && sellList.length === 0 && <LoaderCard />}
        {!loading && sellList.length === 0 && (
          <EmptyCard text="No sell data found" height="80vh" />
        )}
        {sellList.length > 0 && (
          <SellTable
            sellList={sellList}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={totalCount}
            currency={currency}
          />
        )}
      </Stack>
    </Fragment>
  );
};
