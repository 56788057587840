import { Badge, Stack, Typography, useTheme } from "@mui/material";
import { Fragment } from "react";
import SearchField from "../search/SearchField";
import { SimpleDropDown } from "../dropdown/SimpleDropDown";
import PropTypes from "prop-types";
import { useRequestsStore } from "../../store/store";

export const RequestsHeader = (props) => {
  const theme = useTheme();
  const { badgeCount, onFilterChange, onSearchChange, status, searchText } =
    props;

  const filterOptions = [
    {
      id: 1,
      name: "Pending",
    },
    {
      id: 2,
      name: "Rejected",
    },
    {
      id: 3,
      name: "Approved",
    },
  ];

  return (
    <Fragment>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
            Requests
          </Typography>
          {badgeCount !== 0 && (
            <Badge
              badgeContent={badgeCount}
              color={"badgePrimary"}
              sx={{ ml: 3 }}
            />
          )}
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          {status && (
            <Typography
              sx={{
                mr: 1,
                fontSize: "14px",
                fontWeight: "500",
                color: "gray",
                cursor: "pointer",
              }}
              onClick={() => {
                onFilterChange("");
              }}
            >
              Clear Filter
            </Typography>
          )}
          <Stack
            container
            direction={"row"}
            sx={{
              mr: "12px",
              height: 45,
              bgcolor: theme.palette.menuAlt.main,
              borderRadius: "2px",
            }}
          >
            <SimpleDropDown
              placeholderText={"Filter By"}
              options={filterOptions}
              onChange={onFilterChange}
              value={status}
            />
          </Stack>
          <SearchField
            onSearchTextChange={onSearchChange}
            searchText={searchText}
          />
        </Stack>
      </Stack>
    </Fragment>
  );
};

RequestsHeader.propTypes = {
  badgeCount: PropTypes.number,
  onFilterChange: PropTypes.func,
  onSearchChange: PropTypes.func,
};
