import { Divider, Grid, Typography, useTheme } from "@mui/material";
import { Fragment } from "react";
import { HeadSubData } from "./HeadSubData";

export const MultiColumnData = (props) => {
  const {
    bgColor,
    headerColor,
    headerIcon,
    headerText,
    col1Header,
    col1SubHeader,
    col2Header,
    col2SubHeader,
    buyerheadertext,
    hideCol2,
  } = props;

  return (
    <Fragment>
      <Grid
        direction={"column"}
        sx={{
          width: "100%",
          p: 2,
          bgcolor: bgColor,
          borderRadius: 2,
        }}
      >
        <Grid container direction={"row"} alignItems={"center"}>
          <Grid item>{headerIcon}</Grid>
          <Grid item sx={{ ml: 1.5 }}>
            <Typography
              sx={{
                color: headerColor,
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              {headerText}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid container direction={"row"} alignItems={"center"}>
        <Grid item>{headerIcon}</Grid>
          <Grid item sx={{ ml: 1.5 }}>
            <Typography
              sx={{
                color: headerColor,
                fontSize: 15,
                fontWeight: 600,
              }}
            >
              {buyerheadertext}
            </Typography>
          </Grid>
        </Grid> */}
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          sx={{ mt: 1 }}
          spacing={2}
        >
          <Grid item xs={hideCol2 ? 12 : 6}>
            <HeadSubData header={col1Header} subHeader={col1SubHeader} />
          </Grid>
          {!hideCol2 && (
            <Divider orientation="vertical" flexItem sx={{ mt: 3, mb: 1 }} />
          )}
          {!hideCol2 && (
            <Grid item xs={5}>
              <HeadSubData header={col2Header} subHeader={col2SubHeader} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
