import {
  Backdrop,
  Button,
  Dialog,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";

export const EditCutoffTime = (props) => {
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const theme = useTheme();
  const { open, onClose, time, onSubmit } = props;
  const [timeInput, setTimeInput] = useState(time);

  const dropdownMenu = [
    {
      id: "1",
      label: "Option One",
    },
    {
      id: "2",
      label: "Option Two",
    },
    {
      id: "3",
      label: "Option Three",
    },
    {
      id: "4",
      label: "Option Four",
    },
  ];

  const handleCancelClick = () => {
    onClose();
  };

  const handleConfirmClick = () => {
    let params = {
      key: "cut_off",
      value: timeInput.length === 1 ? "0" + timeInput : timeInput,
    };
    api
      .post(urls.editSettings, params)
      .then((res) => {
        showToast(res.data.message, "success");
        onClose();
        onSubmit();
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: "rgba(0, 0, 0, 0.2)",
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Edit Order Cut-Off Time
        </Typography>
        <TextField
          label="Select Cut-Off Time"
          inputProps={{
            sx: {
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.primary.main,
            },
          }}
          sx={{ mt: 4, ml: 4, mr: 4 }}
          value={timeInput}
          onChange={(e) => {
            let inputValue = e.target.value;
            if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
              if (inputValue.length > 2) {
                return;
              }
              if (+inputValue <= 23) {
                setTimeInput(inputValue);
              }
            }
          }}
        />
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.primary.main,
              mt: 4,
              ml: 4,
              mr: 4,
            }}
            label="Select Cut-Off Time"
            format="HH:mm"
            value={dayjs(timeInput, "HH:mm")}
            onChange={(time) => setTimeInput(dayjs(time).format("HH:mm"))}
          />
        </LocalizationProvider> */}
        <Stack direction={"row"} sx={{ mt: 3, ml: 4, mr: 4, gap: 2 }}>
          <Button
            onClick={handleCancelClick}
            variant="contained"
            disableElevation
            color="lightTheme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClick}
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 45,
              fontSize: 16,
              fontWeight: 500,
              textTransform: "none",
            }}
            disabled={!timeInput}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

EditCutoffTime.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  time: PropTypes.string,
  onSubmit: PropTypes.func,
};
