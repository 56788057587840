import { useState, useEffect, Fragment } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
  useTheme,
  Typography,
} from "@mui/material";
import { StatusLabel } from "../../styles/status/StatusLabel";
import { makeStyles } from "@mui/styles";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { LoaderCard } from "../loaders/LoaderCard";
import { EmptyCard } from "../emptyCard/EmptyCard";
import { currencyFormatter } from "../../utilities/extensions";
import moment from "moment";

const useStyles = makeStyles(() => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  table: {
    minWidth: 650,
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    padding: "20px",
    justifyContent: "left",
  },
  border: {
    borderBottom: "0px !important",
  },
  headertable: {
    fontWeight: 500,
    color: "#000",
  },
  tableitem: {
    color: "#1F1F1F",
  },
  tableborder: {
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    justifyContent: "left",
    padding: "20px",
  },
}));

export const BuyersOrdersTable = (props) => {
  const { onOrdersChange } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { id } = useParams();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [orders, setOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currency, setCurrency] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function createData(id, date, orderId, quantity, total, status) {
    return { id, date, orderId, quantity, total, status };
  }

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
    height: 45,
  };

  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
  };

  const getOrders = () => {
    let params = {
      page: page + 1,
      limit: rowsPerPage,
    };
    api
      .get(urls.userOrdersList + id, { params: params })
      .then((res) => {
        setOrders(res.data.data?.serializer);
        setTotalCount(res.data.data?.pagination?.totalOrders);
        onOrdersChange({
          total_sales: res.data.data?.total_sales ?? 0,
          total_orders: res.data.data?.total_orders ?? 0,
          closing_balance: res.data.data?.closing_balance ?? 0,
          currency: res.data.data?.currency ?? "",
          user_deleted: res.data.data?.user_deleted ?? false,
        });
        setCurrency(res.data.data?.currency ?? "");
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        }
        showToast(err.response?.data?.message, "error");
      });
  };

  useEffect(() => {
    getOrders();
  }, [page, rowsPerPage]);

  const getSLNo = (i) => {
    return i + 1 + page * rowsPerPage;
  };

  return (
    <Fragment>
      {isLoading && orders.length === 0 && <LoaderCard />}
      {!isLoading && orders.length === 0 && <EmptyCard text={"No Orders"} />}
      {orders.length > 0 && (
        <Box
          sx={{
            border: 1,
            borderColor: theme.palette.line.main,
            borderRadius: 2,
            pl: 2,
            pr: 2,
          }}
        >
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerStyle}>No.</TableCell>
                  <TableCell sx={headerStyle}>Date & Time</TableCell>
                  <TableCell sx={headerStyle}>Order ID</TableCell>
                  <TableCell sx={headerStyle}>Quantity</TableCell>
                  <TableCell sx={headerStyle}>Total Amount</TableCell>
                  <TableCell sx={headerStyle} align="right">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order, index) => (
                  <TableRow key={index}>
                    <TableCell sx={itemStyle}>{getSLNo(index)}</TableCell>
                    <TableCell sx={itemStyle}>
                      {moment(order.time).format("DD MMM YYYY, hh:mm a")}
                    </TableCell>
                    <TableCell sx={itemStyle} component="th" scope="row">
                      {order.buyer_order_refrence_id}
                    </TableCell>
                    <TableCell sx={itemStyle}>{order.total_quantity}</TableCell>
                    <TableCell sx={itemStyle}>
                      {currencyFormatter(order.total_amount, currency)}
                    </TableCell>
                    <TableCell sx={itemStyle}>
                      <Box sx={{ float: "right" }}>
                        {order.status === "Live Orders" ? (
                          <StatusLabel type="live">Live Orders</StatusLabel>
                        ) : order.status === "Completed" ? (
                          <StatusLabel type="success">Completed</StatusLabel>
                        ) : order.status === "Pending" ? (
                          <StatusLabel type="pending">Pending</StatusLabel>
                        ) : (
                          <Typography></Typography>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            align="right"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ mt: "12px", mb: "12px" }}
          />
        </Box>
      )}
    </Fragment>
  );
};

BuyersOrdersTable.propTypes = {
  onOrdersChange: PropTypes.func,
};
