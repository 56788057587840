import {
  Box,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import { dropDownArrow, imageRoundSmallPlaceholder } from "../../assets";
import api from "../../service/api";
import urls from "../../service/urls";
import { useAuth, useToast } from "../../context";

export const AttributesDropDown = (props) => {
  const { attribute, selectedAttribute, onSelectedAttribute } = props;

  const theme = useTheme();

  const { logout } = useAuth();
  const { showToast } = useToast();

  const [attributeDescriptionList, setAttributeDescriptionList] = useState([]);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState({});

  useEffect(() => {
    if (selectedAttribute === undefined || selectedAttribute === null) {
      setSelectedMenu({});
    }
  }, [selectedAttribute]);

  const listOfAttributeDescription = () => {
    api
      .get(`${urls.attributeDescriptionList}/${attribute.name.uuid}`)
      .then((res) => {
        setAttributeDescriptionList(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleMenuClick = (event) => {
    listOfAttributeDescription();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    onSelectedAttribute(attribute.name.uuid, value.param_id);
    setSelectedMenu(value);
    handleMenuClose();
  };

  return (
    <Fragment>
      <Stack
        direction={"row"}
        alignItems={"center"}
        onClick={handleMenuClick}
        sx={{ gap: 1, cursor: "pointer", pl: 2, pr: 2 }}
      >
        {selectedMenu?.image && selectedMenu.image !== null && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mr: "6px",
            }}
          >
            <img
              src={
                selectedMenu.image !== ""
                  ? selectedMenu.image
                  : imageRoundSmallPlaceholder
              }
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "12px",
              }}
            />
          </Box>
        )}
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {selectedMenu.name || attribute.name.name}
        </Typography>
        <img
          src={dropDownArrow}
          style={{
            width: "14px",
            height: "10px",
          }}
        />
        {/* <Button
        variant="contained"
        color="transparent"
        onClick={handleMenuClick}
        style={{ textTransform: "none" }}
        disableElevation
        endIcon={
          <img
            src={dropDownArrow}
            style={{
              width: "14px",
              height: "10px",
            }}
          />
        }
        sx={{ height: "100%" }}
      >
        {selectedMenu.name || attribute.name.name}
      </Button> */}
      </Stack>
      {attributeDescriptionList.length > 0 && (
        <AttributesMenu
          menu={attributeDescriptionList}
          menuAnchorEl={menuAnchorEl}
          handleMenuClose={handleMenuClose}
          handleMenuItemClick={handleMenuItemClick}
        />
      )}
    </Fragment>
  );
};

const AttributesMenu = (props) => {
  const { menu, menuAnchorEl, handleMenuClose, handleMenuItemClick } = props;

  return (
    <Menu
      anchorEl={menuAnchorEl}
      open={Boolean(menuAnchorEl)}
      onClose={handleMenuClose}
    >
      {menu.map((item) => (
        <MenuItem key={item.id} onClick={() => handleMenuItemClick(item)}>
          {item.name}
        </MenuItem>
      ))}
    </Menu>
  );
};
