import {
  Stack,
  Typography,
  Box,
  useTheme,
  Dialog,
  Backdrop,
  IconButton,
} from "@mui/material";
import { PriceCard } from "../cards/PriceCard";
import CloseIcon from "@mui/icons-material/Close";
import { useAPI } from "../../hooks/useAPI";
import { useAuth, useToast } from "../../context";
import { LoaderCard } from "../loaders/LoaderCard";
import { useEffect, useState } from "react";

export const PriceAlert = (props) => {
  const theme = useTheme();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const { openPriceDialog, onClose, slab } = props;
  const [sellers, setSellers] = useState([]);

  const handleClosedialog = () => {
    onClose();
  };

  const getSellers = () => {
    api
      .get(`${urls.stockSellersList}/${slab.slabId}/${slab.productID}`)
      .then((res) => {
        let data = res.data.data;
        setSellers(data.sellers);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getSellers();
  }, []);

  return (
    <Dialog
      open={openPriceDialog}
      onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: "rgba(0, 0, 0, 0.2)",
      //   },
      // }}
    >
      <Stack
        direction={"row"}
        textAlign={"right"}
        justifyContent={"right"}
        pr={"4px"}
        pt={"4px"}
      >
        <IconButton onClick={handleClosedialog}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Stack direction={"column"} spacing={2} sx={{ width: 450 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 16,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {slab.name}
        </Typography>
        <Stack direction={"row"} justifyContent={"center"}>
          <Box>
            <Stack direction={"row"} alignItems={"center"}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  mb: "4px",
                  width: "30px",
                  height: "30px",
                  bgcolor: stringToColor(`${slab.slabText}+`),
                  borderRadius: 2,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.white.main,
                    fontSize: 10,
                    fontWeight: 500,
                  }}
                >
                  {slab.slabText}+
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 18,
                  fontWeight: 500,
                  ml: 1,
                }}
              >
                Price List
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Stack>
          {isLoading && <LoaderCard />}
          {sellers.map((seller, index) => {
            return <PriceCard data={seller} key={index} rank={index + 1} />;
          })}
        </Stack>
      </Stack>
    </Dialog>
  );
};

const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let j = 0; j < 3; j++) {
    const value = (hash >> (j * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};
