import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Activity, DollarSquare, ShoppingCart } from "iconsax-react";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { IconColumnDataSmall } from "../data/IconColumDataSmall";
import PropTypes from "prop-types";
import { currencyFormatter } from "../../utilities/extensions";

const useStyles = makeStyles(() => ({
  orders: {
    fontSize: "24px",
  },
}));

export default function OrdersHeader(props) {
  const { title, companytitle, companyid, orderData } = props;
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = (event) => {
    navigate(-1);
  };

  return (
    <Stack direction={"column"}>
      <Grid
        container
        direction={"row"}
        sx={{
          alignItems: "center",
        }}
      >
        <Grid item>
          <ArrowBack
            onClick={handleClick}
            sx={{
              cursor: "pointer",
              borderRadius: "20px",
              padding: "5px",
              color: theme.palette.theme.main,
              boxShadow: "1px 1px 1px 1px lightblue",
            }}
          />
        </Grid>
        <Grid item sx={{ ml: 2 }}>
          <Typography>{title}</Typography>
        </Grid>
      </Grid>
      <Typography sx={{ fontSize: 24, fontWeight: 600, mt: 2, mb: 2 }}>
        {companytitle}
        <span style={{ color: theme.palette.tabgrey.main }}>{companyid}</span>
      </Typography>
      <Grid
        container
        spacing={2}
        direction={"row"}
        sx={{ height: 100, gap: 2, pr: 2 }}
      >
        <Grid item xs>
          <IconColumnDataSmall
            icon={
              <Activity
                variant="Outline"
                size={22}
                color={theme.palette.theme.main}
              />
            }
            bgColor={theme.palette.vlightTheme.main}
            headerColor={theme.palette.smallIconTextOne.main}
            header={"Total Sales"}
            valueColor={theme.palette.primary.main}
            value={currencyFormatter(
              orderData?.total_sales ?? 0,
              orderData?.currency
            )}
          />
        </Grid>
        <Grid item xs>
          <IconColumnDataSmall
            icon={
              <ShoppingCart
                variant="Outline"
                size={22}
                color={theme.palette.altThemeOne.main}
              />
            }
            bgColor={theme.palette.altThemeOneLight.main}
            headerColor={theme.palette.smallIconTextOne.main}
            header={"Total Orders"}
            valueColor={theme.palette.primary.main}
            value={orderData?.total_orders ?? 0}
          />
        </Grid>
        <Grid item xs>
          <IconColumnDataSmall
            icon={
              <DollarSquare
                variant="Outline"
                size={22}
                color={theme.palette.altThemeThree.main}
              />
            }
            bgColor={theme.palette.altThemeThreeLight.main}
            headerColor={theme.palette.smallIconTextOne.main}
            header={"Closing Balance"}
            valueColor={theme.palette.primary.main}
            value={currencyFormatter(
              orderData?.closing_balance ?? 0,
              orderData?.currency
            )}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

OrdersHeader.propTypes = {
  title: PropTypes.string,
  companytitle: PropTypes.string,
  companyid: PropTypes.string,
  orderData: PropTypes.object,
};
