import {
  AppBar,
  Button,
  Dialog,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import PurchaseSerialItem from "../items/PurchaseSerialItem";
import { PurchaseSerialTable } from "../table/PurchaseSerialTable";
import SearchSerial from "../search/SearchSerial";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import { LoaderCard } from "../loaders/LoaderCard";
import { EmptyCard } from "../emptyCard/EmptyCard";

export default function FindSerial(props) {
  const { open, onClose, Transition } = props;
  const theme = useTheme();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [searchField, setSearchField] = useState("");
  const [orders, setOrders] = useState([]);

  const handleClickOpen = () => {
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const searchOrders = () => {
    setOrders([]);
    let params = {
      search: searchField,
    };
    api
      .get(urls.serialNumberSearch, {
        params: params,
      })
      .then((res) => {
        setOrders(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response.data.message, "error");
        }
      });
  };

  useEffect(() => {
    if (searchField) {
      searchOrders();
    } else {
      setOrders([]);
    }
  }, [searchField]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: theme.palette.white.main,
          color: theme.palette.black.main,
          fontWeight: 600,
          fontSize: 24,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClickOpen}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Divider orientation="vertical" sx={{ paddingLeft: "10px" }} />
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Search Result
          </Typography>
          <Stack>
            <SearchSerial onSearchTextChange={setSearchField} focus={open} />
          </Stack>
        </Toolbar>
      </AppBar>
      {isLoading && <LoaderCard />}
      {!isLoading && orders.length === 0 && <EmptyCard />}
      {orders.length > 0 && (
        <Stack sx={{ p: 4 }}>
          <Stack>
            <PurchaseSerialItem
              serial={searchField}
              product={orders[0]?.product}
            />
          </Stack>
          <Stack>
            <PurchaseSerialTable data={orders} />
          </Stack>
        </Stack>
      )}
    </Dialog>
  );
}
