export const convertUtcToLocalTime = (
  utcDateString,
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  const moment = require("moment");
  return moment.utc(utcDateString).local().format(format);
};

export const amountChecker = (price) => {
  let amount = price.toString();

  if (amount.includes("+")) {
    return `+$${amount.replace("+", "")}`;
  } else if (amount.includes("-")) {
    return `-$${amount.replace("-", "")}`;
  } else {
    return `+$${amount}`;
  }
};

export const currencyFormatter = (amount, currency) => {
  const refAmount = (amount ?? 0)
    .toString()
    .replace((currency ?? "").toString(), "")
    .replace("-", "")
    .trim();
  if ((amount ?? 0).toString().includes("-"))
    return `-${currency ?? ""} ${refAmount}`;
  else return `${currency ?? ""} ${refAmount}`;
};
