import { Menu, MenuItem } from "@mui/material";
import { countries } from "../../utilities/other";

export const CountryMenuDropDown = (props) => {
  const {
    countryMenuAnchorEl,
    handleCountryMenuClose,
    handleCountryMenuItemClick,
  } = props;

  return (
    <Menu
      anchorEl={countryMenuAnchorEl}
      open={Boolean(countryMenuAnchorEl)}
      onClose={handleCountryMenuClose}
    >
      {countries.map((item) => (
        <MenuItem
          key={item.phone}
          onClick={() => handleCountryMenuItemClick(item)}
        >
          {`${item.label} (+${item.phone})`}
        </MenuItem>
      ))}
    </Menu>
  );
};
