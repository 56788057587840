import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { TickSquare } from "iconsax-react";
import { amountChecker } from "../../utilities/extensions";
import { ReminderDialog } from "../../components/dialogs/ReminderDialog";
import { useModal } from "../../hooks";
import { useState } from "react";
import PropTypes from "prop-types";
import { currencyFormatter } from "../../utilities/extensions";

export const PurchasePaymentSummary = (props) => {
  const {
    totalprice,
    grandtotal,
    orderStatement,
    totalcommission,
    onAddChargeDialog,
    onAddDiscountDialog,
    onDeletePayment,
    currency,
  } = props;

  const theme = useTheme();
  const [isOpenConfirmModal, openConfirmModal, closeConfirmModal] = useModal();
  const [paymentID, setPaymentID] = useState("");

  return (
    <Stack
      direction={"column"}
      sx={{
        p: 2,
        gap: 2,
        border: 1,
        borderRadius: 2,
        borderColor: theme.palette.line.main,
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: 20,
          color: theme.palette.primary.main,
        }}
      >
        Payment Summary
      </Typography>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 16,
            color: theme.palette.primary.main,
          }}
        >
          Total Amount
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 16,
            color: theme.palette.primary.main,
          }}
        >
          {currencyFormatter(totalprice, currency)}
        </Typography>
      </Stack>
      {orderStatement?.map((item) => (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
              color: theme.palette.primary.main,
            }}
          >
            {item.description}
          </Typography>
          <Stack direction={"column"} alignItems={"flex-end"}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color:
                  item.amount < 0
                    ? theme.palette.red.main
                    : theme.palette.primary.main,
              }}
            >
              {currencyFormatter(item.amount, currency)}
            </Typography>
            <Typography
              sx={{
                cursor: "pointer",
                fontWeight: 600,
                fontSize: 12,
                color: theme.palette.amountPenalty.main,
                textAlign: "right",
              }}
              onClick={() => {
                setPaymentID(item.uuid);
                openConfirmModal();
              }}
            >
              Remove
            </Typography>
          </Stack>
        </Stack>
      ))}
      <Divider />
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 16,
            color: theme.palette.primary.main,
          }}
        >
          Grand Total
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 16,
            color: theme.palette.primary.main,
          }}
        >
          {currencyFormatter(grandtotal, currency)}
        </Typography>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 14,
            color: theme.palette.tertiary.main,
          }}
        >
          Commission Fee
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 16,
            color: theme.palette.tertiary.main,
          }}
        >
          {currencyFormatter(totalcommission, currency)}
        </Typography>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"} sx={{ gap: 2 }}>
          <Box>
            <Button
              variant="outlined"
              color="theme"
              sx={{ height: 45, textTransform: "none" }}
              onClick={onAddChargeDialog}
            >
              Add Charge
            </Button>
          </Box>
          <Box>
            <Button
              variant="outlined"
              color="theme"
              sx={{ height: 45, textTransform: "none" }}
              onClick={onAddDiscountDialog}
            >
              Add Rebate
            </Button>
          </Box>
        </Stack>
        <Stack direction={"row"} sx={{ gap: 2 }}>
          {/* <Button
            startIcon={
              <TickSquare
                size="18"
                color={theme.palette.statusActive.main}
                variant="Bold"
              />
            }
            variant="outlined"
            color="secondaryBtn"
            style={{ color: theme.palette.primary.main }}
            sx={{ height: 45, textTransform: "none" }}
          >
            Seller Payment Settled
          </Button> */}
        </Stack>
      </Stack>
      <ReminderDialog
        open={isOpenConfirmModal}
        onClose={closeConfirmModal}
        onConfirm={() => {
          onDeletePayment(paymentID);
          closeConfirmModal();
        }}
        header={"Delete Payment"}
        reminder={"Are you sure you want to delete this payment?"}
        confirm={"Delete"}
      />
    </Stack>
  );
};

PurchasePaymentSummary.propTypes = {
  totalprice: PropTypes.string,
  grandtotal: PropTypes.string,
  orderStatement: PropTypes.array,
  totalcommission: PropTypes.string,
  onAddChargeDialog: PropTypes.func,
  onAddDiscountDialog: PropTypes.func,
  onDeletePayment: PropTypes.func,
  currency: PropTypes.string,
};
