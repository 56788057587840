import { createTheme } from "@mui/material/styles";

const generateTheme = (mode) => {
  const isDarkMode = mode === "dark";

  const palette = {
    mode,
    transparent: {
      main: isDarkMode ? "#00000000" : "#00000000",
    },
    primary: {
      main: isDarkMode ? "#040404" : "#040404",
    },
    secondary: {
      main: isDarkMode ? "#FF80AB" : "#FF4081",
    },
    tertiary: {
      main: isDarkMode ? "#5D5D5D" : "#5D5D5D",
    },
    background: {
      main: isDarkMode ? "#F7FAFF" : "#F7FAFF",
    },
    white: {
      main: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    black: {
      main: isDarkMode ? "#000000" : "#000000",
    },
    tablecolor: {
      main: isDarkMode ? "#1F1F1F" : "#1F1F1F",
    },
    grey: {
      main: isDarkMode ? "#666" : "#666",
    },
    lightgrey: {
      main: isDarkMode ? "#F7F7F7" : "#F7F7F7",
    },
    // ordergrey:{
    //   main: isDarkMode ? "##888888" :"##888888",
    // },
    otpcolor: {
      main: isDarkMode ? "#F8F6F6" : "#F8F6F6",
    },
    otpcounter: {
      main: isDarkMode ? "#3A3A3A" : "#3A3A3A",
    },
    otpText: {
      main: isDarkMode ? "#656565" : "#656565",
    },
    tabgrey: {
      main: isDarkMode ? "#7A7A7A" : "#7A7A7A",
    },
    blue: {
      main: isDarkMode ? "#2C74FF" : "#2C74FF",
    },
    lightblue: {
      main: isDarkMode ? "#E0F2FF" : "#E0F2FF",
    },
    red: {
      main: isDarkMode ? "#FF1818" : "#FF1818",
    },
    purple: {
      main: isDarkMode ? "#DD7BF4" : "#DD7BF4",
    },
    lightpurple: {
      main: isDarkMode ? "#FAEDFF" : "#FAEDFF",
    },
    theme: {
      main: isDarkMode ? "#2C74FFE5" : "#2C74FFE5",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    popbutton: {
      main: isDarkMode ? "#E3F2FF" : "#E3F2FF",
    },
    popbuttonselected: {
      main: isDarkMode ? "#0F8EFF" : "#0F8EFF",
    },
    vlightTheme: {
      main: isDarkMode ? "#EDFAFF" : "#EDFAFF",
      contrastText: isDarkMode ? "#2C74FFE5" : "#2C74FFE5",
    },
    lightTheme: {
      main: isDarkMode ? "#D0F2FF" : "#D0F2FF",
      contrastText: isDarkMode ? "#2C74FFE5" : "#2C74FFE5",
    },
    altThemeOne: {
      main: isDarkMode ? "#FF8D06" : "#FF8D06",
    },
    altThemeOneLight: {
      main: isDarkMode ? "#FFF8E7" : "#FFF8E7",
    },
    altThemeTwo: {
      main: isDarkMode ? "#FF4242" : "#FF4242",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    altThemeTwoLight: {
      main: isDarkMode ? "#FFEAEA" : "#FFEAEA",
    },
    altThemeThree: {
      main: isDarkMode ? "#12B322" : "#12B322",
    },
    altThemeThreeLight: {
      main: isDarkMode ? "#E9FFEB" : "#E9FFEB",
    },
    approveDoc: {
      main: isDarkMode ? " #05BE18" : "#05BE18",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    amountPenalty: {
      main: isDarkMode ? "#FF1818" : "#FF1818",
    },
    amountPaid: {
      main: isDarkMode ? "#189224" : "#189224",
    },
    tabLight: {
      main: isDarkMode ? "#F5F5F5" : "#F5F5F5",
    },
    menu: {
      main: isDarkMode ? "#FAFAFA" : "#FAFAFA",
    },
    menuAlt: {
      main: isDarkMode ? "#F8F8F8" : "#F8F8F8",
    },
    menuHeader: {
      main: isDarkMode ? "#F5F5F5" : "#F5F5F5",
    },
    menuHeaderDark: {
      main: isDarkMode ? "#C4C4C4" : "#C4C4C4",
    },
    menuSel: {
      main: isDarkMode ? "#323232" : "#323232",
    },
    menuSelLight: {
      main: isDarkMode ? "#F0EAEA" : "#F0EAEA",
    },
    menuSelDark: {
      main: isDarkMode ? "#DDDDDD" : "#DDDDDD",
    },
    badgeTheme: {
      main: isDarkMode ? "#2C74FFE5" : "#2C74FFE5",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    badgePrimary: {
      main: isDarkMode ? "#FF4141" : "#FF4141",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    badgeSuccess: {
      main: isDarkMode ? "#1ACA2C" : "#1ACA2C",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    badgePending: {
      main: isDarkMode ? "#FF4C4C" : "#FF4C4C",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    badgeCompleted: {
      main: isDarkMode ? "#959595" : "#959595",
    },
    badgeliveOrder: {
      main: isDarkMode ? "#24A1FF" : "#24A1FF",
    },

    badgeSecondary: {
      main: isDarkMode ? "#1ACA2C" : "#1ACA2C",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    search: {
      main: isDarkMode ? "#EEEEEE" : "#EEEEEE",
    },
    searchDark: {
      main: isDarkMode ? "#929292" : "#929292",
    },
    line: {
      main: isDarkMode ? "#E0E0E0" : "#E0E0E0",
    },
    rank: {
      main: isDarkMode ? "#01E217" : "#01E217",
    },
    placeholder: {
      main: isDarkMode ? "#929292" : "#929292",
    },
    smallIconOne: {
      main: isDarkMode ? "#008D51" : "#008D51",
    },
    smallIconTextOne: {
      main: isDarkMode ? "#303030" : "#303030",
    },
    orange: {
      main: isDarkMode ? "#FF8D06" : "#FF8D06",
    },
    lightOrange: {
      main: isDarkMode ? "#FFF4D8" : "#FFF4D8",
    },
    lightRed: {
      main: isDarkMode ? "#DEFFE0" : "#DEFFE0",
    },
    menuBtn: {
      main: isDarkMode ? "#EEEEEE" : "#EEEEEE",
      contrastText: isDarkMode ? "#040404" : "#040404",
    },
    secondaryBtn: {
      main: isDarkMode ? "#5D5D5D" : "#5D5D5D",
      contrastText: isDarkMode ? "#040404" : "#040404",
    },
    statusActive: {
      main: isDarkMode ? "#1ACA2C" : "#1ACA2C",
    },
    statusDeactive: {
      main: isDarkMode ? "#9A9A9A" : "#9A9A9A",
    },
    buyerAccessBg: {
      main: isDarkMode ? "#F7F7F7" : "#F7F7F7",
    },
    orderProgressBg: {
      main: isDarkMode ? "#EFEFEF" : "#EFEFEF",
    },
    tickGreen: {
      main: isDarkMode ? "#1ACA2C" : "#1ACA2C",
    },
    tickGray: {
      main: isDarkMode ? "#9A9A9A" : "#9A9A9A",
    },
    hideButtonOrange: {
      main: isDarkMode ? "#f59042" : "#f59042",
    },
    disabledText: {
      main: isDarkMode ? "#848484" : "#848484",
    },
  };

  return createTheme({
    palette: palette,
    typography: {
      fontFamily: "Inter, sans-serif",
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightSemiBold: 600,
      fontWeightBold: 700,
      fontWeightExtraBold: 800,
    },
  });
};

export { generateTheme };
