import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  useTheme,
  TableBody,
  Typography,
  Stack,
  TablePagination,
  Tooltip,
  Box,
  Grid,
  tooltipClasses,
  Divider,
} from "@mui/material";
import { samplePaymentItems } from "../../utilities";
import { Fragment, useState, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SerialNumberView from "../alert/SerialNumberView";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import moment from "moment";
import { currencyFormatter } from "../../utilities/extensions";

export const PaymentTable = (props) => {
  const { totalCount, data, currPage, limit, onPageChange, currency } = props;
  const theme = useTheme();
  const [page, setPage] = useState(currPage);
  const [rowsPerPage, setRowsPerPage] = useState(limit);
  const [scroll, setScroll] = useState("paper");
  const [openserial, setOpenserial] = useState();

  const headerStyle = {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
  };
  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleNumberOpen = (scrollType) => {
    setScroll(scrollType);
    setOpenserial(true);
  };
  const handleNumberClose = (e) => {
    setOpenserial(false);
  };
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      minWidth: 200,
    },
  });

  useEffect(() => {
    onPageChange({
      page: page,
      limit: rowsPerPage,
    });
  }, [page, rowsPerPage]);

  const getSLNo = (i) => {
    return i + 1 + page * rowsPerPage;
  };

  return (
    <Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ height: 70 }}>
              <TableCell sx={headerStyle}>No.</TableCell>
              <TableCell sx={headerStyle}>Date</TableCell>
              <TableCell sx={headerStyle}>Transaction ID</TableCell>
              <TableCell sx={headerStyle}>Description</TableCell>
              <TableCell align="right" sx={headerStyle}>
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell
                  style={{ verticalAlign: "center", textAlign: "left" }}
                  sx={{ width: 80 }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {getSLNo(index)}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "center", textAlign: "left" }}
                  sx={{ width: 250 }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {moment(item.created_at).format("DD MMM YYYY, hh:mm a")}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "center", textAlign: "left" }}
                  sx={{ width: 200 }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {item.transaction_id}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "center", textAlign: "left" }}
                >
                  <Stack direction={"column"}>
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {item.description}
                    </Typography>
                    <Stack direction={"row"}>
                      <Box>
                        <CustomWidthTooltip
                          placement="top-end"
                          slotProps={{
                            popper: {
                              sx: {
                                [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                  {
                                    marginBottom: "35px",
                                    borderRadius: "14px",
                                  },
                              },
                            },
                          }}
                          title={
                            <Stack
                              direction={"column"}
                              sx={{ padding: "10px 10px" }}
                            >
                              <Grid
                                container
                                direction={"row"}
                                justifyContent={"space-between"}
                                sx={{
                                  paddingBottom: "10px ",
                                }}
                              >
                                <Grid item>
                                  <Typography
                                    sx={{
                                      color: theme.palette.primary.main,
                                      fontWeight: 500,
                                      fontSize: 14,
                                      cursor: "pointer",
                                    }}
                                  >
                                    Cost
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    sx={{
                                      color: theme.palette.primary.main,
                                      fontWeight: 500,
                                      fontSize: 14,
                                      cursor: "pointer",
                                    }}
                                  >
                                    2150
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Divider />
                              <Grid
                                container
                                direction={"row"}
                                sx={{
                                  paddingTop: "10px ",
                                }}
                                justifyContent={"space-between"}
                              >
                                <Grid item>
                                  <Typography
                                    sx={{
                                      color: theme.palette.primary.main,
                                      fontWeight: 500,
                                      fontSize: 14,
                                      cursor: "pointer",
                                    }}
                                  >
                                    Commission
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    sx={{
                                      color: theme.palette.primary.main,
                                      fontWeight: 500,
                                      fontSize: 14,
                                      cursor: "pointer",
                                    }}
                                  >
                                    150
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Stack>
                          }
                          color={theme.palette.primary.main}
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: theme.palette.white.main,
                                boxShadow: "1px 0px 1px  0px grey",
                                boxShadow:
                                  "0px 4px 16px 0px rgba(0, 0, 0, 0.10)",

                                "& .MuiTooltip-arrow": {
                                  color: theme.palette.white.main,
                                },
                                "& .MuiTooltip-Popper": {
                                  margin: "20px 0px",
                                },
                              },
                            },
                          }}
                        >
                          {/* <Typography sx={itemStyle}>
                            {item.description}
                          </Typography> */}
                        </CustomWidthTooltip>
                      </Box>
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "center", textAlign: "right" }}
                  sx={{ width: 150 }}
                >
                  <Typography
                    sx={{
                      color: item.credit_amount
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {currencyFormatter(
                      item.credit_amount
                        ? item.credit_amount
                        : item.debit_amount,
                      currency
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        align="right"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: "12px", mb: "-4px" }}
      />
    </Fragment>
  );
};

PaymentTable.propTypes = {
  totalCount: PropTypes.number,
  currPage: PropTypes.number,
  limit: PropTypes.number,
  onPageChange: PropTypes.func,
  data: PropTypes.array,
};
