import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Crown1 } from "iconsax-react";

export const SerialCard = ({ serialNo, index }) => {
  const theme = useTheme();

  return (
    <Stack>
      <Divider />
      <Stack
        direction={"row"}
        sx={{ height: 60, alignItems: "center", pl: 3, pr: 3 }}
      >
        <Typography
          sx={{
            color: theme.palette.black.main,
            fontSize: 16,
            fontWeight: 500,
            mr: "4px",
          }}
        >
          {index + 1}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.black.main,
            fontSize: 16,
            fontWeight: 500,
            mr: "12px",
            ml: "12px",
            width: "100%",
          }}
        >
          {serialNo}
        </Typography>
      </Stack>
    </Stack>
  );
};
