import React from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Box,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

export const LoaderCard = (props) => {
  const theme = useTheme();
  const { text } = props;
  return (
    <Box
      sx={{
        border: 1,
        borderColor: theme.palette.line.main,
        borderRadius: 2,
        p: 2,
      }}
    >
      <Stack
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ minHeight: 250 }}
      >
        <CircularProgress sx={{ color: "blue.main" }} />
        <Typography>{text}</Typography>
      </Stack>
    </Box>
  );
};

LoaderCard.propTypes = {
  text: PropTypes.string,
};

LoaderCard.defaultProps = {
  text: "Loading...",
};
