import { Badge, Box, Stack, Typography, useTheme } from "@mui/material";
import { actionMenuHorizontal, imageRoundSmallPlaceholder } from "../../assets";
import EditDel from "../popup/EditDel";
import { useState } from "react";
import EditDeleteMenu from "../menu/EditDeleteMenu";
import EditRemoveMenu from "../menu/EditRemoveMenu";

export const BrandCard = (props) => {
  const theme = useTheme();
  const { node } = props;

  return (
    <Stack
      direction={"row"}
      spacing={2}
      sx={{
        p: "12px",
        alignItems: "center",
        height: 20,
      }}
    >
      <img
        src={node.image ? node.image : imageRoundSmallPlaceholder}
        width="24px"
        style={{
          height: "24px",
          borderRadius: "12px",
        }}
      />
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontSize: 14,
          fontWeight: 500,
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          width: "100%",
        }}
      >
        {node.name}
      </Typography>
      <Stack
        direction={"row"}
        sx={{
          width: 50,
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <Badge
          badgeContent={node.badge}
          color="badgeSecondary"
          sx={{ mr: "20px", width: "20px" }}
        />
        {/* <Box textAlign={"right"}>
          <EditRemoveMenu alignItems={"center"} />
        </Box> */}
      </Stack>
    </Stack>
  );
};
