import { Box, Button, Stack, Tab, Tabs, useTheme } from "@mui/material";
import React, { useState, Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { BuyersOrdersTable } from "../table/BuyersOrdersTable";
import { BuyersPaymentTable } from "../table/BuyersPaymentTable";
import { BuyersUsersTable } from "../table/BuyersUsersTable";
import { Add } from "iconsax-react";
import { CompanyDetailsBuyerScreen } from "../../pages/company_details/CompanyDetailsBuyerScreen";
import { AddPayment } from "../alert/AddPayment";
import { AddBuyerDialog } from "../dialogs/AddBuyerDialog";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context";
import { userRoles } from "../../utilities";

const useStyles = makeStyles(() => ({
  tabItems: {
    fontSize: "18px",
    borderRadius: "9px",
    background: "#F5F5F5",
  },
}));

export default function BuyersOrdersTab(props) {
  const { onOrdersChange } = props;
  const { userRole } = useAuth();
  const theme = useTheme();
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [openAddPaymentDialog, setAddPaymentDialog] = useState(false);
  const [openAddUserDialog, setAddUserDialog] = useState(false);
  const { id } = useParams();
  const [date, setDate] = useState(new Date());
  const [currency, setCurrency] = useState("");
  const [isDeleted, setIsDeleted] = useState(true);

  // Step 1: Add state to track when a new admin is created
  const [isNewUserCreated, setIsNewUserCreated] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onOrderDataUpdate = (data) => {
    setIsDeleted(data.user_deleted);
    onOrdersChange(data);
  };

  const handleClosePayment = () => {
    setAddPaymentDialog(false);
  };
  const handleOpenPayment = () => {
    setAddPaymentDialog(true);
  };
  const handleOpenUser = () => {
    setAddUserDialog(true);
  };
  const handleCloseUser = () => {
    setAddUserDialog(false);
  };
  const onCreatedNewUser = () => {
    setAddUserDialog(false);
    setIsNewUserCreated(true);
  };

  return (
    <Fragment>
      {openAddUserDialog && (
        <AddBuyerDialog
          open={openAddUserDialog}
          onClose={handleCloseUser}
          onSubmit={onCreatedNewUser}
        />
      )}

      <Box sx={{ pt: 2, pb: 2 }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            width: "100%",
            height: 70,
            bgcolor: theme.palette.tabLight.main,
            borderRadius: 2,
            alignItems: "center",
            display: "flex",
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{ height: "100%", ml: 2 }}
            TabIndicatorProps={{
              style: {
                // fontSize: 18,
                // borderRadius: "8px",
                height: 3,
                backgroundColor: theme.palette.theme.main,
              },
            }}
          >
            <Tab
              label="Orders"
              sx={{
                height: 70,
                color: theme.palette.black.main,
                fontSize: 18,
                fontWeight: 500,
                textTransform: "none",
                "&.Mui-selected": {
                  color: theme.palette.theme.main, // Color for the selected text
                },
              }}
            />
            <Tab
              label="Transactions"
              sx={{
                ml: 2,
                mr: 2,
                height: 70,
                color: theme.palette.black.main,
                fontSize: 18,
                fontWeight: 500,
                textTransform: "none",
                "&.Mui-selected": {
                  color: theme.palette.theme.main, // Color for the selected text
                },
              }}
            />
            <Tab
              label="Company Details"
              sx={{
                mr: 2,
                height: 70,
                color: theme.palette.black.main,
                fontSize: 18,
                fontWeight: 500,
                textTransform: "none",
                "&.Mui-selected": {
                  color: theme.palette.theme.main, // Color for the selected text
                },
              }}
            />
            {(userRole === userRoles.superAdmin ||
              userRole === userRoles.master) && (
              <Tab
                label="Users"
                sx={{
                  height: 70,
                  color: theme.palette.black.main,
                  fontSize: 18,
                  fontWeight: 500,
                  textTransform: "none",
                  "&.Mui-selected": {
                    color: theme.palette.theme.main, // Color for the selected text
                  },
                }}
              />
            )}
          </Tabs>
          <Box sx={{ pr: 2 }}>
            {tabValue === 1 && !isDeleted && (
              <Box>
                <Button
                  color="theme"
                  variant="contained"
                  disableElevation
                  startIcon={<Add color={theme.palette.white.main} />}
                  sx={{ height: 40, textTransform: "none" }}
                  onClick={handleOpenPayment}
                >
                  Add Payment
                </Button>
                {openAddPaymentDialog && (
                  <AddPayment
                    onClose={handleClosePayment}
                    openAddPaymentDialog={openAddPaymentDialog}
                    buyerSellerID={id}
                    onSubmit={() => {
                      setDate(new Date());
                    }}
                    currency={currency}
                  />
                )}
              </Box>
            )}
            {tabValue === 3 &&
              userRole === userRoles.superAdmin &&
              !isDeleted && (
                <Box>
                  <Button
                    color="theme"
                    variant="contained"
                    disableElevation
                    startIcon={<Add color={theme.palette.white.main} />}
                    sx={{ height: 40, textTransform: "none" }}
                    onClick={handleOpenUser}
                  >
                    Add User
                  </Button>
                </Box>
              )}
          </Box>
        </Stack>
        <Box sx={{ mt: 2 }}>
          {tabValue === 0 ? (
            <BuyersOrdersTable onOrdersChange={onOrderDataUpdate} />
          ) : tabValue === 1 ? (
            <BuyersPaymentTable
              key={date}
              onCurrencyChange={setCurrency}
              onTransactionsChange={onOrdersChange}
            />
          ) : tabValue === 2 ? (
            <CompanyDetailsBuyerScreen
              isDeleted={isDeleted}
              showButton={userRole === userRoles.superAdmin}
            />
          ) : (
            <BuyersUsersTable
              showButton={userRole === userRoles.superAdmin && !isDeleted}
              isNewUserCreated={isNewUserCreated}
              onNewUserHandled={() => setIsNewUserCreated(false)}
            />
          )}
        </Box>
      </Box>
    </Fragment>
  );
}

BuyersOrdersTab.propTypes = {
  onOrdersChange: PropTypes.func,
};

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;
//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ pt: 0 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };
