import { useState, useEffect, Fragment } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
  useTheme,
} from "@mui/material";

import { PendingStatus, StatusLabel } from "../../styles/status/StatusLabel";
import { makeStyles } from "@mui/styles";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { LoaderCard } from "../loaders/LoaderCard";
import { EmptyCard } from "../emptyCard/EmptyCard";
import moment from "moment";
import { currencyFormatter } from "../../utilities/extensions";

const useStyles = makeStyles(() => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  table: {
    minWidth: 650,
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    padding: "20px",
    justifyContent: "left",
  },
  border: {
    borderBottom: "0px !important",
  },
  headertable: {
    fontWeight: 500,
    color: "#000",
  },
  tableitem: {
    color: "#1F1F1F",
  },
  tableborder: {
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    justifyContent: "left",
    padding: "20px",
  },
}));

export const SellersPaymentTable = ({
  onCurrencyChange,
  onTransactionsChange,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { id } = useParams();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [transactions, setTransactions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currency, setCurrency] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function createData(id, date, orderId, description, total, status) {
    return { id, date, orderId, description, total, status };
  }

  const data = [
    createData(
      "1",
      "15 Oct 2023,  03:00 PM",
      "1312",
      "Order 76554",
      "$ 10000.00"
    ),
    createData(
      "2",
      "15 Oct 2023,  03:00 PM",
      "1312",
      "Order 13954",
      "$ 10000.00"
    ),
    createData(
      "3",
      "15 Oct 2023,  03:00 PM",
      "1312",
      "Penalty charges",
      "$ 500.00"
    ),
    createData(
      "4",
      "15 Oct 2023,  03:00 PM",
      "1312",
      "Payment from buyer",
      "$ 10000.00"
    ),
  ];

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
    height: 45,
  };

  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
  };

  const getTransactions = () => {
    let params = {
      page: page + 1,
      limit: rowsPerPage,
      id: id,
    };
    api
      .get(urls.transactionsList, { params: params })
      .then((res) => {
        setTransactions(res.data.data?.data);
        setTotalCount(res.data.data?.pagination?.total_count);
        setCurrency(res.data.data?.currency ?? "");
        onCurrencyChange(res.data.data?.currency ?? "");
        onTransactionsChange({
          total_sales: res.data.data?.total_sales ?? 0,
          total_orders: res.data.data?.total_orders ?? 0,
          closing_balance: res.data.data?.closing_balance ?? 0,
          currency: res.data.data?.currency ?? "",
        });
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        }
        showToast(err.response?.data?.message, "error");
      });
  };

  useEffect(() => {
    getTransactions();
  }, [page, rowsPerPage]);

  const getSLNo = (i) => {
    return i + 1 + page * rowsPerPage;
  };

  return (
    <Fragment>
      {isLoading && transactions.length === 0 && <LoaderCard />}
      {!isLoading && transactions.length === 0 && (
        <EmptyCard text={"No Transactions"} />
      )}
      {transactions.length > 0 && (
        <Box
          sx={{
            border: 1,
            borderColor: theme.palette.line.main,
            borderRadius: 2,
            pl: 2,
            pr: 2,
          }}
        >
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerStyle}>No.</TableCell>
                  <TableCell sx={headerStyle}>Date</TableCell>
                  <TableCell sx={headerStyle}>Transaction ID</TableCell>
                  <TableCell sx={headerStyle}>Description</TableCell>
                  <TableCell sx={headerStyle} align="right">
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((trans, index) => (
                  <TableRow>
                    <TableCell sx={itemStyle}>{getSLNo(index)}</TableCell>
                    <TableCell sx={itemStyle}>
                      {moment(trans.created_at).format("DD MMM YYYY, hh:mm a")}
                    </TableCell>
                    <TableCell sx={itemStyle} component="th" scope="row">
                      {trans.transaction_id}
                    </TableCell>
                    <TableCell sx={itemStyle}>{trans.description}</TableCell>
                    <TableCell>
                      <Box sx={{ float: "right" }}>
                        {/* {trans.total === "$ 500.00" ? (
                      <StatusLabel type="penalty">{trans.total}</StatusLabel>
                    ) : (
                      <StatusLabel type="paid">{trans.total}</StatusLabel>
                    )} */}
                        <StatusLabel
                          type={trans.credit_amount ? "paid" : "penalty"}
                        >
                          {currencyFormatter(
                            trans.credit_amount
                              ? trans.credit_amount
                              : trans.debit_amount,
                            currency
                          )}
                        </StatusLabel>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            align="right"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ mt: "12px", mb: "12px" }}
          />
        </Box>
      )}
    </Fragment>
  );
};
