import React from "react";
import { Box, List, ListItem } from "@mui/material";
import ScannedItemsCard from "../cards/ScannedItemsCard";

function PurchaseScannedItems() {
  const gap = {
    justifyContent: "center",
    paddingLeft: "0",
    paddingRight: "0",
  };

  return (
    <Box>
      {/* <CustomScroll style={{ color: "blue", backgroundColor: "blue" }}> */}
      <List
        sx={{
          height: "100%",
          flexWrap: "inherit",
          // overflowX: "scroll",
        }}
      >
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
        <ListItem sx={gap}>
          <ScannedItemsCard />
        </ListItem>
      </List>
      {/* </CustomScroll> */}
    </Box>
  );
}

export default PurchaseScannedItems;
