import { useState, useRef, Fragment, useEffect } from "react";
import { Stack, Button, useTheme, Input } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import coverplaceholder from "../../assets/images/cover-placeholder.svg";
import { ImageEasyCropper } from "../croppers/ImageEasyCropper";
import PropTypes from "prop-types";

export const CoverUploader = (props) => {
  const { onImageChange, image } = props;
  const theme = useTheme();
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (image) {
      setSelectedImage(image);
    }
  }, [image]);
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  //image selection logic
  const [src, setSrc] = useState(null);
  const userImagePicker = useRef(null);

  const selectImage = (file) => {
    setSrc(URL.createObjectURL(file));
  };

  const closeCropper = () => {
    userImagePicker.current.value = ""; //clear file selection
    setSrc(null); //remove file
  };

  const cropCompleted = (img) => {
    userImagePicker.current.value = ""; //clear file selection
    setSelectedImage(img);
    setSrc(null); //remove file
    onImageChange(img);
  };

  return (
    <Fragment>
      <Stack
        sx={{
          height: 230,
          border: 1,
          borderRadius: 2,
          borderColor: theme.palette.line.main,
          background: theme.palette.white.main,
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Input
          id="image-input"
          type="file"
          accept="image/*"
          onChange={(e) => {
            selectImage(e.target.files[0]);
            e.target.value = "";
          }}
          style={{ display: "none" }}
          ref={userImagePicker}
        />
        <img
          style={{
            objectFit: "cover",
            width: 100,
            height: 100,
            borderRadius: selectedImage === null ? 0 : 50,
          }}
          alt="img"
          src={selectedImage === null ? coverplaceholder : selectedImage}
        />
        <label htmlFor="image-input">
          <Button
            variant="contained"
            disableElevation
            component="span"
            color="theme"
            startIcon={<CloudUpload />}
            sx={{
              height: 40,
              width: 200,
              fontSize: 16,
              fontWeight: 600,
              textTransform: "none",
            }}
          >
            {selectedImage === null ? "Upload Image" : "Change Image"}
          </Button>
        </label>
      </Stack>
      {src !== null && (
        <ImageEasyCropper
          selectedImage={src}
          aspectRatio={1 / 1}
          closeCropper={closeCropper}
          cropCompleted={cropCompleted}
        />
      )}
    </Fragment>
  );
};

CoverUploader.propTypes = {
  onImageChange: PropTypes.func,
  image: PropTypes.string,
};
