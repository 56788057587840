// import { TextField } from "@mui/material";

import { useTheme } from "@emotion/react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";

export default function BasicDatePicker(props) {
  const { label, onChange, date } = props;

  const theme = useTheme();
  const picker = {
    width: "100%",
    "& .MuiSvgIcon-root": {
      color: theme.palette.popbuttonselected.main,
    },
    "& .MuiButtonBase-root-MuiIconButton-root:hover": {
      backgroundColor: "blue",
    },
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        slots={{
          openPickerIcon: CalendarMonthIcon,
        }}
        // <CalendarMonthIcon color="secondary"/>
        id="normal-field"
        sx={picker}
        label={label}
        onChange={onChange}
        variant="outlined"
        format="DD MMM YYYY"
        disablePast
        value={date ? dayjs(date) : null}
        slotProps={{ field: { readOnly: true } }}
      />
    </LocalizationProvider>
  );
}

BasicDatePicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  date: PropTypes.string,
};
