import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Breadcrumbs,
  Link,
  useTheme,
} from "@mui/material";
import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  orders: {
    fontSize: "24px",
  },
}));

export default function RequestDetailHeader(props) {
  const { title } = props;
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = (event) => {
    navigate(-1);
  };

  return (
    <Stack direction={"column"}>
      <Grid
        container
        direction={"row"}
        sx={{
          alignItems: "center",
        }}
      >
        <Grid item>
          <ArrowBack
            onClick={handleClick}
            sx={{
              mr: 2,
              cursor: "pointer",
              borderRadius: "20px",
              padding: "5px",
              color: "#0085FB",
              boxShadow: "1px 1px 1px 1px lightblue",
            }}
          />
        </Grid>
        <Grid item sx={{ p: 0 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="none" color="inherit" onClick={handleClick}>
              Requests
            </Link>
            <Typography color="text.primary">{title}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Box sx={{ pt: 5 }}>
        <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
          {title}
        </Typography>
      </Box>
    </Stack>
  );
}
