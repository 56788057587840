import { Fragment, useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  useTheme,
  TablePagination,
  Stack,
} from "@mui/material";

import { StatusLabel } from "../../styles/status/StatusLabel";
import { makeStyles } from "@mui/styles";
import { sampleSellItems } from "../../utilities";
import { useNavigate } from "react-router-dom";
import urls from "../../service/urls";
import api from "../../service/api";
import { useAuth, useToast } from "../../context";
import { currencyFormatter } from "../../utilities/extensions";
import moment from "moment";
import { useSellStore } from "../../store/store";

const useStyles = makeStyles(() => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  table: {
    minWidth: 650,
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    padding: "16px",
    justifyContent: "left",
  },
  border: {
    borderBottom: "0px !important",
  },
}));

export const SellTable = (props) => {
  const {
    sellList,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    currency,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [totalCount, setTotalCount] = useState(0);

  const navigate = useNavigate();
  const { showToast } = useToast();
  const { logout } = useAuth();

  const { setShouldReload } = useSellStore((state) => state);

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
  };
  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
  };
  const tableborder = {
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    justifyContent: "left",
    padding: "20px",
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setShouldReload(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setShouldReload(true);
  };

  // const getsellList = () => {
  //   api
  //     .get(urls.sellTableList, {
  //       params: {
  //         page: page + 1,
  //         limit: rowsPerPage,
  //       },
  //     })
  //     .then((res) => {
  //       setSell(res.data.data.data);
  //       setTotalCount(res.data.data.pagination.pageSize);
  //     })
  //     .catch((err) => {
  //       if (err.response.status === 401) {
  //         logout();
  //       } else {
  //         showToast(err.response.data.message, "error");
  //       }
  //     });
  // };

  // useEffect(() => {
  //   getsellList();
  // }, [page, rowsPerPage]);

  const getSLNo = (i) => {
    return i + 1 + page * rowsPerPage;
  };

  return (
    <Fragment>
      <Box sx={tableborder}>
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow sx={{ height: 70 }}>
                <TableCell sx={headerStyle}>No.</TableCell>
                <TableCell sx={headerStyle}>Date & Time</TableCell>
                <TableCell sx={headerStyle}>Order ID</TableCell>
                <TableCell sx={headerStyle}>Buyer</TableCell>
                <TableCell sx={headerStyle}>Quantity</TableCell>
                <TableCell sx={headerStyle}>Total amount </TableCell>
                <TableCell sx={headerStyle}>Status</TableCell>
                <TableCell sx={headerStyle}></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sellList?.map((item, i) => (
                <TableRow
                  key={item.id}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={itemStyle}>{getSLNo(i)}</TableCell>
                  <TableCell sx={itemStyle}>
                    {moment(item.time).format("DD MMM YYYY, hh:mm a")}
                  </TableCell>
                  <TableCell sx={itemStyle}>
                    {item.buyer_order_refrence_id}
                  </TableCell>
                  <TableCell sx={itemStyle}>{item.buyer_name}</TableCell>
                  <TableCell sx={itemStyle}>{item.total_quantity}</TableCell>
                  <TableCell sx={itemStyle}>
                    {currencyFormatter(item.total_amount, currency)}
                  </TableCell>
                  <TableCell sx={itemStyle}>
                    <Stack>
                      {item.status.toLowerCase() === "waiting for delivery" ? (
                        <StatusLabel type="pending">{item.status}</StatusLabel>
                      ) : item.status === "Approved" ? (
                        <StatusLabel type="success">{item.status}</StatusLabel>
                      ) : item.status === "Completed" ? (
                        <StatusLabel type="success">{item.status}</StatusLabel>
                      ) : item.status === "Rejected" ? (
                        <StatusLabel type="rejected">{item.status}</StatusLabel>
                      ) : (
                        <StatusLabel type="pending">{item.status}</StatusLabel>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell align={"right"}>
                    <Button
                      onClick={() => {
                        navigate(`/sell_summary/${item.id}`);
                      }}
                      variant="contained"
                      disableElevation
                      color="theme"
                      style={{ textTransform: "none" }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          align="right"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ mt: "12px", mb: "-4px" }}
        />
      </Box>
    </Fragment>
  );
};
