import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Backdrop,
  Box,
  IconButton,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { SerialCard } from "../cards/SerialCard";
import CloseIcon from "@mui/icons-material/Close";
import { SerialProductCard } from "../cards/SerialProductCard";
import PropTypes from "prop-types";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";

export default function SerialNumberView(props) {
  const {
    openserial,
    onClose,
    scroll,
    orderID,
    productID,
    type,
    productName,
    itemID,
  } = props;
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [serialNumbers, setSerialNumbers] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosedialog = () => {
    onClose();
  };

  const getSerialNumbers = () => {
    let url = "";
    if (type === "purchase") {
      url = urls.purchaseSerialNumbers;
    } else {
      url = urls.sellSerialNumbers;
    }
    api
      .get(url + `${orderID}/${productID}/${itemID}`)
      .then((res) => {
        let data = res.data.data;
        setSerialNumbers(data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    getSerialNumbers();
  }, [open]);

  return (
    <Dialog
      open={openserial}
      onClose={onClose}
      scroll={scroll}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: theme.palette.black.main,
      //     opacity: 0.3,
      //   },
      // }}
    >
      <Stack direction={"row"} textAlign={"right"} justifyContent={"right"}>
        <IconButton onClick={handleClosedialog}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ width: 450, pt: 0, overflowY: "auto" }}
      >
        <Stack justifyContent={"center"}>
          <Typography
            sx={{
              color: theme.palette.black.main,
              fontSize: 24,
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            Serial Numbers
          </Typography>
        </Stack>
        <Stack>
          <SerialProductCard name={productName} />
          {isLoading && serialNumbers.length === 0 && (
            <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
              <CircularProgress sx={{ color: "blue.main" }} />
            </Box>
          )}
          {serialNumbers.map((serial, index) => (
            <SerialCard
              key={index}
              serialNo={serial.serial_number}
              index={index}
            />
          ))}
        </Stack>
      </Stack>
    </Dialog>
  );
}

SerialNumberView.propTypes = {
  openserial: PropTypes.bool,
  onClose: PropTypes.func,
  scroll: PropTypes.string,
  orderID: PropTypes.string,
  productID: PropTypes.string,
  type: PropTypes.string,
  productName: PropTypes.string,
  itemID: PropTypes.string,
};
