export const convertBse64ToFormData = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const isBase64Image = (str) => {
  // Regular expression to match base64 image patterns
  const base64Regex = /^data:image\/(png|jpeg|jpg|gif);base64,/;
  return base64Regex.test(str);
};

export const currencies = [
  { code: "USD", symbol: "$", name: "US Dollar" },
  { code: "EUR", symbol: "€", name: "Euro" },
  { code: "JPY", symbol: "¥", name: "Japanese Yen" },
  { code: "GBP", symbol: "£", name: "British Pound" },
  { code: "AUD", symbol: "A$", name: "Australian Dollar" },
  { code: "CAD", symbol: "C$", name: "Canadian Dollar" },
  { code: "CHF", symbol: "CHF", name: "Swiss Franc" },
  { code: "CNY", symbol: "¥", name: "Chinese Yuan" },
  { code: "SEK", symbol: "kr", name: "Swedish Krona" },
  { code: "NZD", symbol: "NZ$", name: "New Zealand Dollar" },
  { code: "MXN", symbol: "$", name: "Mexican Peso" },
  { code: "SGD", symbol: "S$", name: "Singapore Dollar" },
  { code: "HKD", symbol: "HK$", name: "Hong Kong Dollar" },
  { code: "NOK", symbol: "kr", name: "Norwegian Krone" },
  { code: "KRW", symbol: "₩", name: "South Korean Won" },
  { code: "TRY", symbol: "₺", name: "Turkish Lira" },
  { code: "RUB", symbol: "₽", name: "Russian Ruble" },
  { code: "INR", symbol: "₹", name: "Indian Rupee" },
  { code: "BRL", symbol: "R$", name: "Brazilian Real" },
  { code: "ZAR", symbol: "R", name: "South African Rand" },
  { code: "PHP", symbol: "₱", name: "Philippine Peso" },
  { code: "CZK", symbol: "Kč", name: "Czech Koruna" },
  { code: "PLN", symbol: "zł", name: "Polish Zloty" },
  { code: "DKK", symbol: "kr", name: "Danish Krone" },
  { code: "HUF", symbol: "Ft", name: "Hungarian Forint" },
  { code: "THB", symbol: "฿", name: "Thai Baht" },
  { code: "IDR", symbol: "Rp", name: "Indonesian Rupiah" },
  { code: "ILS", symbol: "₪", name: "Israeli New Shekel" },
  { code: "MYR", symbol: "RM", name: "Malaysian Ringgit" },
  { code: "AED", symbol: "د.إ", name: "United Arab Emirates Dirham" },
  { code: "SAR", symbol: "﷼", name: "Saudi Riyal" },
  { code: "BGN", symbol: "лв", name: "Bulgarian Lev" },
  { code: "RON", symbol: "lei", name: "Romanian Leu" },
  { code: "EGP", symbol: "£", name: "Egyptian Pound" },
  { code: "ARS", symbol: "$", name: "Argentine Peso" },
  { code: "CLP", symbol: "$", name: "Chilean Peso" },
  { code: "COP", symbol: "$", name: "Colombian Peso" },
  { code: "PEN", symbol: "S/", name: "Peruvian Sol" },
  { code: "UAH", symbol: "₴", name: "Ukrainian Hryvnia" },
  { code: "VND", symbol: "₫", name: "Vietnamese Dong" },
  // Additional currencies
  { code: "BDT", symbol: "৳", name: "Bangladeshi Taka" },
  { code: "PKR", symbol: "₨", name: "Pakistani Rupee" },
  { code: "QAR", symbol: "ق.ر", name: "Qatari Riyal" },
  { code: "KZT", symbol: "₸", name: "Kazakhstani Tenge" },
  { code: "MAD", symbol: "د.م.", name: "Moroccan Dirham" },
  { code: "JOD", symbol: "د.ا", name: "Jordanian Dinar" },
  { code: "OMR", symbol: "ر.ع.", name: "Omani Rial" },
  { code: "CUC", symbol: "$", name: "Cuban Convertible Peso" },
  { code: "BYN", symbol: "Br", name: "Belarusian Ruble" },
  { code: "AZN", symbol: "₼", name: "Azerbaijani Manat" },
  { code: "LKR", symbol: "₨", name: "Sri Lankan Rupee" },
  { code: "NGN", symbol: "₦", name: "Nigerian Naira" },
  { code: "TZS", symbol: "Sh", name: "Tanzanian Shilling" },
];
