import {
  Backdrop,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useToast, useAuth } from "../../context";
import { useAPI } from "../../hooks/useAPI";

export const AddSubCategory = (props) => {
  const theme = useTheme();
  const { open, onClose, catID, onSubmit, isEdit, data } = props;
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    status: true,
    category_id: catID,
  });

  const handleCancelClick = () => {
    onClose();
  };

  const handleConfirmClick = () => {
    if (isEdit) {
      let params = { ...formData };
      delete params.status;
      delete params.category_id;

      api
        .put(urls.updateSubCategory + data.id, params)
        .then((res) => {
          onClose();
          onSubmit();
          showToast(res.data.message, "success");
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            logout();
          } else {
            showToast(err.response?.data?.message, "error");
          }
        });
    } else {
      let params = { ...formData };
      api
        .post(urls.addSubCategory, params)
        .then((res) => {
          onClose();
          onSubmit();
          showToast(res.data.message, "success");
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            logout();
          } else {
            showToast(err.response?.data?.message, "error");
          }
        });
    }
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    if (data) {
      console.log(data);
      setFormData((prev) => ({
        ...prev,
        name: data.name,
        description: data.description,
      }));
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: theme.palette.black.main,
      //     opacity: 0.7,
      //   },
      // }}
    >
      <Stack direction={"column"} sx={{ width: 350, pt: 3, pb: 4 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {`${isEdit ? "Edit" : "Add"} Subcategory`}
        </Typography>
        <Divider sx={{ mt: 2 }} />
        <Stack>
          <TextField
            id="outlined-basic"
            label="Subcategory Name"
            variant="outlined"
            sx={{ mt: 4, ml: 4, mr: 4 }}
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                name: e.target.value,
                description: e.target.value,
              }));
            }}
            value={formData.name}
          />
        </Stack>
        <Stack sx={{ mt: 4, ml: 4, mr: 4 }}>
          <Grid container>
            <Grid item xs>
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={handleCancelClick}
                  variant="contained"
                  disableElevation
                  color="lightTheme"
                  sx={{
                    width: "100%",
                    height: 45,
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmClick}
                  variant="contained"
                  disableElevation
                  color="theme"
                  sx={{
                    width: "100%",
                    height: 45,
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                  disabled={!formData.name.trim() || isLoading}
                >
                  {isEdit ? "Update" : "Submit"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Dialog>
  );
};

AddSubCategory.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  catID: PropTypes.string,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  data: PropTypes.object,
};
