import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { PurchaseHeader } from "../../components/headers/PurchaseHeader";
import { PurchaseTable } from "../../components/table/PurchaseTable";
import { RequestsHeader } from "../../components/headers/RequestsHeader";
import { RequestsTable } from "../../components/table/RequestsTable";
import { useAPI } from "../../hooks/useAPI";
import { useAuth, useToast } from "../../context";
import { LoaderCard } from "../../components/loaders/LoaderCard";
import { useRequestsStore } from "../../store/store";

export const RequestsScreen = () => {
  const theme = useTheme();
  const { api, urls, isLoading } = useAPI();
  const { logout } = useAuth();
  const { showToast } = useToast();
  // const [requests, setRequests] = useState([]);
  const {
    requests,
    setRequests,
    isFirstLoad,
    setIsFirstLoad,
    shouldReload,
    setShouldReload,
    pagination,
    setPagination,
    status,
    setStatus,
    search,
    setSearch,
  } = useRequestsStore((state) => state);
  // const [pagination, setPagination] = useState({
  //   currentPage: "1",
  //   limit: "10",
  //   total_count: 0,
  // });
  const [pageParams, setPageParams] = useState({
    page: 1,
    limit: 10,
    certificate_status: status,
    company_name: "",
  });

  const getRequests = () => {
    api
      .get(urls.registrationRequests, { params: { ...pageParams } })
      .then((res) => {
        setRequests(res.data.data.data);
        setPagination(res.data.data.pagination);
        setIsFirstLoad(false);
        setShouldReload(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    if (isFirstLoad) {
      getRequests();
    }
  }, []);

  useEffect(() => {
    if (shouldReload) {
      getRequests();
    }
  }, [shouldReload]);

  return (
    <Fragment>
      <Stack direction={"column"} sx={{ pr: 2 }}>
        <RequestsHeader
          badgeCount={pagination.total_count}
          onFilterChange={(f) => {
            setPageParams({ ...pageParams, certificate_status: f });
            setStatus(f);
            setTimeout(() => {
              setShouldReload(true);
            }, 300);
          }}
          onSearchChange={(s) => {
            setPageParams({ ...pageParams, company_name: s });
            setSearch(s);
            setTimeout(() => {
              setShouldReload(true);
            }, 300);
          }}
          status={pageParams.certificate_status}
          searchText={search}
        />
        <Divider sx={{ mt: 2 }} />
        {/* <Typography
          sx={{
            mt: 3,
            mb: 3,
            color: theme.palette.tertiary.main,
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          Showing All Results
        </Typography> */}
        <Box sx={{ mt: 3 }}>
          {isLoading && requests.length === 0 && <LoaderCard />}
          {requests.length !== 0 && (
            <RequestsTable
              data={requests}
              pagination={pagination}
              onPageChange={(page) => setPageParams({ ...pageParams, ...page })}
            />
          )}
        </Box>
      </Stack>
    </Fragment>
  );
};
