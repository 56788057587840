import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { ReminderDialog } from "../dialogs/ReminderDialog";
import { useModal } from "../../hooks";
import { Fragment, useState } from "react";

export const BuyerCompanyRadio = (props) => {
  const { status, onStatusChange, isDeleted, showButton } = props;
  const theme = useTheme();
  const [isOpenConfirmModal, openConfirmModal, closeConfirmModal] = useModal();
  const [toStatus, setToStatus] = useState("");

  const handleConfirm = () => {
    closeConfirmModal();
    onStatusChange(toStatus);
  };

  return (
    <Fragment>
      <FormControl>
        <RadioGroup
          column
          name="row-radio-buttons-group"
          value={status}
          onChange={(e) => {
            openConfirmModal();
            setToStatus(e.target.value);
          }}
        >
          <FormControlLabel
            value="full_access"
            control={
              <Radio
                sx={{
                  color: theme.palette.theme.main,
                  "&.Mui-checked": {
                    color:
                      isDeleted || !showButton
                        ? "gray"
                        : theme.palette.theme.main,
                  },
                }}
                disabled={isDeleted || !showButton}
              />
            }
            label={
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: theme.palette.primary.main,
                }}
              >
                Full Access
              </span>
            }
          />
          <FormControlLabel
            value="view_only"
            control={
              <Radio
                sx={{
                  color: theme.palette.theme.main,
                  "&.Mui-checked": {
                    color:
                      isDeleted || !showButton
                        ? "gray"
                        : theme.palette.theme.main,
                  },
                }}
                disabled={isDeleted || !showButton}
              />
            }
            label={
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: theme.palette.primary.main,
                }}
              >
                View Only
              </span>
            }
          />
          <FormControlLabel
            value="deactivate"
            control={
              <Radio
                color="theme"
                sx={{
                  color: theme.palette.theme.main,
                  "&.Mui-checked": {
                    color:
                      isDeleted || !showButton
                        ? "gray"
                        : theme.palette.theme.main,
                  },
                }}
                disabled={isDeleted || !showButton}
              />
            }
            label={
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: theme.palette.primary.main,
                }}
              >
                Deactivate
              </span>
            }
          />
        </RadioGroup>
      </FormControl>
      <ReminderDialog
        open={isOpenConfirmModal}
        onClose={closeConfirmModal}
        onConfirm={handleConfirm}
        header={"Are you sure?"}
        reminder={"Are you sure you want to change status?"}
        confirm={"Confirm"}
      />
    </Fragment>
  );
};

BuyerCompanyRadio.propTypes = {
  status: PropTypes.string,
  onStatusChange: PropTypes.func,
};
