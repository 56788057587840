import { Button, Divider, Stack, Typography, useTheme } from "@mui/material";
import { NormalColumnData } from "./NormalColumnData";
import PropTypes from "prop-types";

export const AboutCompany = (props) => {
  const { data } = props;
  const theme = useTheme();

  return (
    <Stack
      direction={"column"}
      sx={{ border: 1, borderColor: theme.palette.line.main, borderRadius: 2 }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ pl: 2, pr: 2, pt: 2, pb: 2 }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          About Company
        </Typography>
        {/* <Button
          variant="text"
          color="theme"
          sx={{
            fontSize: 16,
            fontWeight: 500,
            textTransform: "none",
          }}
        >
          Edit
        </Button> */}
      </Stack>
      <Divider />
      <Stack direction={"row"} sx={{ gap: 2 }}>
        <Stack
          direction={"column"}
          sx={{ width: "100%", pt: 2, pl: 2, pb: 2, gap: 2 }}
        >
          <NormalColumnData
            header={"Company Name"}
            description={data.company_name}
          />
          <NormalColumnData
            header={"Whatsapp number"}
            description={`${data.phone_code ?? ""} ${data.phone_number ?? ""}`}
          />
          <NormalColumnData header={"Email"} description={data.email} />
          <NormalColumnData header={"City"} description={data.city} />
        </Stack>
        <Stack
          direction={"column"}
          sx={{ width: "100%", pt: 2, pr: 2, pb: 2, gap: 2 }}
        >
          <NormalColumnData
            header={"Company Admin"}
            description={data.owner_name}
          />
          <NormalColumnData
            header={"Alternate Contact Number"}
            description={`${data.office_landline_code ?? ""} ${
              data.landline ?? ""
            }`}
          />
          <NormalColumnData
            header={"Address"}
            description={data.office_address}
          />
          <NormalColumnData header={"Country"} description={data.country} />
        </Stack>
      </Stack>
      {/* <Divider />
      <Typography
        sx={{
          cursor: "pointer",
          color: theme.palette.error.main,
          fontSize: 16,
          fontWeight: 500,
          pl: 2,
          pr: 2,
          pt: "14px",
          pb: "14px",
        }}
      >
        Remove Company
      </Typography> */}
    </Stack>
  );
};

AboutCompany.propTypes = {
  data: PropTypes.object,
};
