import { Box, Divider, Grid, colors, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { LiveOrdersHeader } from "../../components/headers/LiveOrdersHeader";
import { LiveOrdersTable } from "../../components/table/LiveOrdersTable";
import { LiveOrdersRadio } from "../../components/radio/LiveOrdersRadio";
import SearchField from "../../components/search/SearchField";
import { useAPI } from "../../hooks/useAPI";
import { useDownloadAPI } from "../../hooks/useDownloadAPI";
import { useAuth, useToast } from "../../context";
import { LoaderCard } from "../../components/loaders/LoaderCard";
import fileDownload from "js-file-download";
import { EmptyCard } from "../../components/emptyCard/EmptyCard";

export const LiveOrdersScreen = () => {
  const theme = useTheme();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const { downloadApi } = useDownloadAPI();
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    pageSize: 0,
    totalOrders: 0,
  });
  const [requestData, setRequestData] = useState({
    itemsPerPage: 10,
    currentPage: 1,
  });
  const [timer, setTimer] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [headerData, setHeaderData] = useState({
    total_amount: 0,
    total_quantity: 0,
    currency: "",
  });

  const getLiveOrders = () => {
    setLoadingOrders(true);
    const { itemsPerPage, currentPage } = requestData;
    api
      .get(
        `${urls.liveOrders}?page=${currentPage}&limit=${itemsPerPage}&search=${searchText}`
      )
      .then((res) => {
        setLoadingOrders(false);
        let data = res.data.data;
        setOrders(data.orders);
        setPaginationData(data.pagination);
        setHeaderData({
          total_amount: data.total_amount,
          total_quantity: data.total_quantity,
          currency: data.currency,
        });
      })
      .catch((err) => {
        setLoadingOrders(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const onClickFileDownload = (type) => {
    downloadApi
      .get(`${urls.downloadLiveOrders}?file_type=${type}`)
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        let data = res.data;
        fileDownload(data, `live-orders.${type}`);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const onSearch = (text) => {
    if (timer) {
      clearTimeout(timer);
    }
    setRequestData({
      ...requestData,
      currentPage: 1,
    });
    const newTimer = setTimeout(() => {
      setSearchText(text);
    }, 500);

    setTimer(newTimer);
  };

  useEffect(() => {
    setTimeout(() => {
      getLiveOrders();
    }, 300);
  }, [requestData, searchText]);

  return (
    <Fragment>
      <Grid container direction={"column"} sx={{ pr: 2 }}>
        <Grid item>
          <LiveOrdersHeader
            data={headerData}
            onSearch={onSearch}
            isDownloading={isLoading}
            onClickDownload={onClickFileDownload}
            disableBtn={orders.length === 0}
          />
        </Grid>
        <Grid item>
          {loadingOrders && <LoaderCard />}
          {!loadingOrders && orders.length > 0 && (
            <Box
              sx={{
                border: 1,
                borderColor: theme.palette.line.main,
                borderRadius: 2,
                p: 2,
              }}
            >
              <Grid
                container
                direction={"row"}
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <Grid item>
                <LiveOrdersRadio />
              </Grid> */}
                {/* <Grid item>
                <SearchField onSearchTextChange={() => {}} />
              </Grid> */}
              </Grid>
              {/* <Divider sx={{ mt: 2, ml: -2, mr: -2 }} /> */}

              <LiveOrdersTable
                orders={orders}
                currency={headerData.currency}
                requestData={requestData}
                paginationData={paginationData}
                setRequestData={setRequestData}
              />
            </Box>
          )}
          {!loadingOrders && orders.length === 0 && (
            <EmptyCard text="No live orders found" height="70vh" />
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
