import { Popover } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PropTypes from "prop-types";

export const DatePickerPopover = (props) => {
  const { open, onClose, dateSelected, onSetDateSelected, anchorEl } = props;

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          value={dateSelected === "" ? null : dateSelected}
          onChange={(newValue) => {
            onSetDateSelected(newValue);
            onClose();
          }}
        />
      </LocalizationProvider>
    </Popover>
  );
};

DatePickerPopover.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dateSelected: PropTypes.any,
  onSetDateSelected: PropTypes.func,
  anchorEl: PropTypes.any,
};
