import { Fragment, useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  useTheme,
  TablePagination,
  Typography,
  Stack,
} from "@mui/material";

import { StatusLabel } from "../../styles/status/StatusLabel";
import { makeStyles } from "@mui/styles";
import { samplePurchaseItems } from "../../utilities";
import { useNavigate } from "react-router-dom";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import { LoaderCard } from "../loaders/LoaderCard";
import { EmptyCard } from "../emptyCard/EmptyCard";
import { currencyFormatter } from "../../utilities/extensions";
import moment from "moment";
import { usePurchaseStore } from "../../store/store";

const useStyles = makeStyles(() => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  table: {
    minWidth: 650,
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    padding: "16px",
    justifyContent: "left",
  },
  border: {
    borderBottom: "0px !important",
  },
}));

export const PurchaseTable = (props) => {
  const { status, date, search } = props;
  const {
    purchase,
    setPurchase,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    setTotalCount,
    isFirstLoad,
    setIsFirstLoad,
    shouldReload,
    setShouldReload,
  } = usePurchaseStore((state) => state);

  const navigate = useNavigate();
  // const [page, setPage] = useState(pg);
  // const [rowsPerPage, setRowsPerPage] = useState(rows);
  // const [totalCount, setTotalCount] = useState(total);
  // const [purchase, setPurchase] = useState(items);
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const [currency, setCurrency] = useState("");

  const getpurchaseList = () => {
    let params = {};

    if (date === "") {
      params = {
        page: page + 1,
        limit: rowsPerPage,
        search_keyword: search,
        status: status,
      };
    } else {
      params = {
        page: page + 1,
        limit: rowsPerPage,
        search_keyword: search,
        status: status,
        from_date: date.format("YYYY-MM-DD"),
        to_date: date.format("YYYY-MM-DD"),
      };
    }

    api
      .get(urls.purchaseTableList, {
        params: params,
      })
      .then((res) => {
        setPurchase(res.data.data.data);
        setTotalCount(res.data.data.pagination.totalOrders);
        setCurrency(res.data.data.currency);
        setIsFirstLoad(false);
        setShouldReload(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response.data.message, "error");
        }
      });
  };

  useEffect(() => {
    if (isFirstLoad) {
      getpurchaseList();
    }
  }, []);

  useEffect(() => {
    if (shouldReload) {
      getpurchaseList();
    }
  }, [shouldReload]);

  // useEffect(() => {
  //   if (!isFirstLoad) {
  //     setPurchase([]);
  //     getpurchaseList();
  //   }
  // }, [status, date, search]);

  const getSLNo = (i) => {
    return i + 1 + page * rowsPerPage;
  };

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
  };
  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
  };
  const tableborder = {
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    justifyContent: "left",
    padding: "20px",
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setShouldReload(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setShouldReload(true);
  };

  return (
    <Fragment>
      {isLoading && purchase.length === 0 && <LoaderCard />}
      {!isLoading && purchase.length === 0 && (
        <EmptyCard text="No purchases found" height="80vh" />
      )}
      {purchase.length > 0 && (
        <Box sx={tableborder}>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow sx={{ height: 70 }}>
                  <TableCell sx={headerStyle}>No.</TableCell>
                  <TableCell sx={headerStyle}>Date & Time</TableCell>
                  <TableCell sx={headerStyle}>Master Order ID</TableCell>
                  <TableCell sx={headerStyle}>Seller</TableCell>
                  <TableCell sx={headerStyle}>Quantity</TableCell>
                  <TableCell sx={headerStyle}>Total amount </TableCell>
                  <TableCell sx={headerStyle}>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchase?.map((item, i) => (
                  <TableRow
                    key={item.id}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={itemStyle}>{getSLNo(i)}</TableCell>
                    {/* <TableCell>{moment(item.order_at).format("DD/MM/YYYY")}</TableCell> */}
                    <TableCell sx={itemStyle}>
                      {moment(item.time).format("DD MMM YYYY, hh:mm a")}
                    </TableCell>
                    <TableCell sx={itemStyle}>
                      {item.seller_order_refrence_id}
                    </TableCell>
                    <TableCell sx={itemStyle}>{item.seller_name}</TableCell>
                    <TableCell sx={itemStyle}>{item.total_quantity}</TableCell>
                    <TableCell sx={itemStyle}>
                      {currencyFormatter(item.total_amount, currency)}
                    </TableCell>
                    <TableCell sx={itemStyle}>
                      <Stack>
                        {item.status.toLowerCase() ===
                        "waiting for delivery" ? (
                          <StatusLabel type="pending">
                            {item.status}
                          </StatusLabel>
                        ) : item.status === "Approved" ? (
                          <StatusLabel type="success">
                            {item.status}
                          </StatusLabel>
                        ) : item.status === "Completed" ? (
                          <StatusLabel type="success">
                            {item.status}
                          </StatusLabel>
                        ) : item.status === "Rejected" ? (
                          <StatusLabel type="rejected">
                            {item.status}
                          </StatusLabel>
                        ) : (
                          <StatusLabel type="pending">
                            {item.status}
                          </StatusLabel>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell align={"right"}>
                      <Button
                        onClick={() => {
                          navigate(`/purchase_summary/${item.id}`);
                        }}
                        variant="contained"
                        disableElevation
                        color="theme"
                        style={{ textTransform: "none" }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            align="right"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ mt: "12px", mb: "-4px" }}
          />
        </Box>
      )}
    </Fragment>
  );
};
