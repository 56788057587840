import { Fragment, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  useTheme,
  Typography,
  Stack,
  TablePagination,
} from "@mui/material";
import { sampleLiveOrderItems } from "../../utilities";
import { currencyFormatter } from "../../utilities/extensions";
import { Img } from "react-image";

export const LiveOrdersTable = (props) => {
  const { orders, currency, requestData, paginationData, setRequestData } =
    props;
  const theme = useTheme();
  const headerStyle = {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
  };
  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    setRequestData((prev) => ({ ...prev, currentPage: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    let count = parseInt(event.target.value, 10);

    // setPage(0);
    setRequestData((prev) => ({ ...prev, itemsPerPage: count }));
  };

  return (
    <Fragment>
      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow sx={{ height: 70 }}>
              <TableCell align="center" sx={headerStyle}>
                No.
              </TableCell>
              <TableCell sx={headerStyle}>Product</TableCell>
              <TableCell sx={headerStyle}>Seller</TableCell>
              <TableCell sx={headerStyle}>Buyer</TableCell>
              <TableCell sx={headerStyle}>Quantity</TableCell>
              <TableCell sx={headerStyle}>Price</TableCell>
              <TableCell align="right" sx={headerStyle}>
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((item, index) => (
              <TableRow
                key={item.id}
                // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  style={{ verticalAlign: "center", textAlign: "center" }}
                  sx={{ width: 40 }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {requestData.itemsPerPage *
                      (paginationData.currentPage - 1) +
                      index +
                      1}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "center", textAlign: "left" }}
                >
                  <Stack direction={"column"}>
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {item.product?.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.tertiary.main,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      {`UPC: ${item.product?.upc}, Model ID: ${item.product?.model_no}`}
                    </Typography>
                    <Stack spacing={1} direction={"row"}>
                      {item.product?.product_attributes?.map(
                        (attribute, attIndex) => {
                          return (
                            <Img
                              key={attIndex}
                              src={attribute.attribute_descriptions?.image}
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                borderWidth: "0.5px",
                                borderStyle: "solid",
                                borderColor: theme.palette.tertiary.main,
                              }}
                            />
                          );
                        }
                      )}
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "center", textAlign: "left" }}
                  sx={{ width: 150 }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {item.seller_name}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "center", textAlign: "left" }}
                  sx={{ width: 150 }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {item.buyer_name}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "center", textAlign: "left" }}
                  sx={{ width: 150 }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {item.quantity}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "center", textAlign: "left" }}
                  sx={{ width: 150 }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {currencyFormatter(item.unit_price, currency)}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "center", textAlign: "right" }}
                  sx={{ width: 150 }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {currencyFormatter(
                      item.quantity * item.unit_price,
                      currency
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        align="right"
        count={paginationData.totalOrders}
        rowsPerPage={requestData.itemsPerPage}
        page={paginationData.currentPage - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: "12px", mb: "-4px" }}
      />
    </Fragment>
  );
};
