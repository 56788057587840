import { Box, Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { dropDownArrow } from "../../assets";

const stocksMenu = [
  { id: 1, name: "All" },
  { id: 2, name: "In Stock" },
  { id: 3, name: "Out of Stock" },
];

export const StocksDropDown = (props) => {
  const { onSelectedStock } = props;

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState({
    id: 1,
    name: "All",
  });

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    onSelectedStock(value.id);
    setSelectedMenu(value);
    handleMenuClose();
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="transparent"
        onClick={handleMenuClick}
        style={{ textTransform: "none" }}
        disableElevation
        endIcon={
          <img
            src={dropDownArrow}
            style={{
              width: "14px",
              height: "10px",
            }}
          />
        }
        sx={{ height: "100%" }}
      >
        {selectedMenu.name}
      </Button>
      <StocksMenu
        menuAnchorEl={menuAnchorEl}
        handleMenuClose={handleMenuClose}
        handleMenuItemClick={handleMenuItemClick}
      />
    </Box>
  );
};

const StocksMenu = (props) => {
  const { menuAnchorEl, handleMenuClose, handleMenuItemClick } = props;

  return (
    <Menu
      anchorEl={menuAnchorEl}
      open={Boolean(menuAnchorEl)}
      onClose={handleMenuClose}
    >
      {stocksMenu.map((item) => (
        <MenuItem key={item.id} onClick={() => handleMenuItemClick(item)}>
          {item.name}
        </MenuItem>
      ))}
    </Menu>
  );
};
