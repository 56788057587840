import { Button, Grid, Typography, useTheme } from "@mui/material";

export const EmptyItem = (props) => {
  const theme = useTheme();
  const { empImage, empText, btnIcon, btnText, onClick } = props;

  return (
    <Grid container direction={"column"} sx={{ alignItems: "center" }}>
      <Grid item>
        <img src={empImage} style={{ width: 250, height: 199 }} />
      </Grid>
      <Grid item>
        <Typography
          sx={{
            color: theme.palette.tertiary.main,
            fontSize: 18,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {empText}
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 2 }}>
        <Button
          variant="contained"
          disableElevation
          color="theme"
          startIcon={btnIcon}
          style={{ textTransform: "none" }}
          onClick={onClick}
          sx={{
            width: 180,
            height: 45,
          }}
        >
          {btnText}
        </Button>
      </Grid>
    </Grid>
  );
};
