import { Fragment, useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  Stack,
  useTheme,
  Typography,
  TablePagination,
} from "@mui/material";

import { PendingStatus, StatusLabel } from "../../styles/status/StatusLabel";
import { makeStyles } from "@mui/styles";
import { AddBrands } from "../alert/AddBrands";
import { AddSubCategory } from "../alert/AddSubCategory";
import { AddMainCategory } from "../alert/AddMainCategory";
import {
  sampleLiveOrderItems,
  samplePurchaseItems,
  sampleRequestsItems,
} from "../../utilities";
import { type } from "@testing-library/user-event/dist/type";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { useRequestsStore } from "../../store/store";

const useStyles = makeStyles(() => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  table: {
    minWidth: 650,
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    padding: "20px",
    justifyContent: "left",
  },
  border: {
    borderBottom: "0px !important",
  },
  tableborder: {
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    justifyContent: "left",
    padding: "20px",
  },

  // headItem: {
  //     fontWeight : 700
  //  }
}));
export const RequestsTable = (props) => {
  const { data, pagination, onPageChange } = props;
  const { setShouldReload, page, setPage, rowsPerPage, setRowsPerPage } =
    useRequestsStore((state) => state);
  // const [page, setPage] = useState(+pagination.currentPage - 1);
  // const [rowsPerPage, setRowsPerPage] = useState(+pagination.limit);
  const theme = useTheme();
  const [openBrandDialog, setOpenBrandDialog] = useState();
  const [openSubDialog, setOpenSubDialog] = useState();
  const [openMainDialog, setOpenMainDialog] = useState();
  const classes = useStyles();
  const navigate = useNavigate();

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
    color: theme.palette.black.main,
  };

  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
    color: theme.palette.tablecolor.main,
  };

  const handleClick = (uid) => {
    navigate(`/requests_detail/${uid}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (
      page !== +pagination.currentPage - 1 ||
      rowsPerPage !== +pagination.limit
    ) {
      onPageChange({
        page: page + 1,
        limit: rowsPerPage,
      });
      setTimeout(() => {
        setShouldReload(true);
      }, 300);
    }
  }, [page, rowsPerPage]);

  const getSLNo = (i) => {
    return i + 1 + page * rowsPerPage;
  };

  return (
    <Box
      sx={{
        pl: 2,
        pr: 2,
        pt: "12px",
        pb: "12px",
        border: 1,
        borderColor: theme.palette.line.main,
        borderRadius: 2,
      }}
    >
      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={headerStyle}>No.</TableCell>
              <TableCell sx={headerStyle}>Company</TableCell>
              {/* <TableCell sx={headerStyle}>Request ID</TableCell> */}
              <TableCell sx={headerStyle}>Date</TableCell>
              <TableCell sx={headerStyle}>Type</TableCell>
              <TableCell align="right" sx={headerStyle}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, i) => (
              <TableRow
                key={i}
                hover
                sx={{ cursor: "pointer" }}
                onClick={() => handleClick(item.user_uuid)}
              >
                <TableCell sx={itemStyle}>{getSLNo(i)}</TableCell>
                <TableCell sx={itemStyle}>{item.user_company}</TableCell>
                {/* <TableCell sx={itemStyle} component="th" scope="row">
                  {`- -`}
                </TableCell> */}
                <TableCell sx={itemStyle}>
                  {moment(
                    item.updatedAt ? item.updatedAt : item.createdAt
                  ).format("DD-MM-YYYY, hh:mm a")}
                </TableCell>
                <TableCell sx={itemStyle}>{item.user_type}</TableCell>
                <TableCell sx={itemStyle}>
                  <Stack sx={{ float: "right" }}>
                    {item.certificate_status === "Pending" ? (
                      <StatusLabel type="pending">Pending</StatusLabel>
                    ) : item.certificate_status === "Approved" ? (
                      <StatusLabel type="success">Approved</StatusLabel>
                    ) : item.certificate_status === "Rejected" ? (
                      <StatusLabel type="rejected">Rejected</StatusLabel>
                    ) : (
                      "- -"
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
            {/* {sampleRequestsItems.map((item, i) => (
              <TableRow
                key={i}
                hover
                sx={{ cursor: "pointer" }}
                onClick={handleClick}
              >
                <TableCell sx={itemStyle}>{item.id}</TableCell>
                <TableCell sx={itemStyle}>{item.company}</TableCell>
                <TableCell sx={itemStyle} component="th" scope="row">
                  {item.requestId}
                </TableCell>
                <TableCell sx={itemStyle} align="center">
                  {item.date}
                </TableCell>
                <TableCell sx={itemStyle}>{item.type}</TableCell>

                <TableCell sx={itemStyle}>
                  <Stack sx={{ float: "right" }}>
                    {item.status === "Pending" ? (
                      <StatusLabel type="pending">Pending</StatusLabel>
                    ) : item.status === "Approved" ? (
                      <StatusLabel type="success">Approved</StatusLabel>
                    ) : item.status === "Rejected" ? (
                      <StatusLabel type="rejected">Rejected</StatusLabel>
                    ) : (
                      <Typography></Typography>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        align="right"
        count={pagination.total_count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: "12px" }}
      />
    </Box>
  );
};

RequestsTable.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object,
  onPageChange: PropTypes.func,
};
