import React, { Fragment, useEffect, useRef } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

export default function SellScanningItems(props) {
  const { serialNo, onSetSerialNo, onOutBoundScan } = props;

  const theme = useTheme();

  const serialRef = useRef(null);

  useEffect(() => {
    if (serialRef.current) {
      serialRef.current.focus();
    }
  }, [serialNo]);

  return (
    <Fragment>
      <Stack sx={{ px: 2, pb: 3 }}>
        <Box
          sx={{
            borderRadius: "10px",
            backgroundColor: theme.palette.white.main,
            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.10)",
            padding: "30px",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: theme.palette.black.main,
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              Scanning Items
            </Typography>
          </Box>

          <Box sx={{ mt: 2, mb: 1 }}>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 600,
                color: theme.palette.blue.main,
                display: "inline-block",
                p: 1,
                borderRadius: "4px",
                backgroundColor: theme.palette.popbutton.main,
              }}
            >
              Please Scan Serial No.
            </Typography>
          </Box>
          <Stack direction="row">
            <Box
              sx={{
                p: 2,
                width: "100%",
                border: 1,
                borderColor:
                  serialNo === ""
                    ? theme.palette.red.main
                    : theme.palette.white.main,
                borderRadius: 2,
              }}
            >
              <TextField
                fullWidth
                id="outlined-basic"
                value={serialNo}
                inputRef={serialRef}
                onChange={(e) => onSetSerialNo(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onOutBoundScan();
                  }
                }}
                label="Serial No."
                variant="outlined"
                sx={{
                  input: {
                    color: theme.palette.popbuttonselected.main,
                    fontSize: "20px",
                    fontWeight: 600,
                  },
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Fragment>
  );
}
