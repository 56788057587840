import React from "react";
import { empData } from "../../assets";
import { Box, Stack, Typography } from "@mui/material";
import { Scan } from "iconsax-react";
import { useTheme } from "@emotion/react";
import { EmptyItem } from "../empty_placeholder/EmptyItem";
import ScannedItemsCard from "../cards/ScannedItemsCard";

function PurchaseStartScan(props) {
  const { showScan, onSetShowScan, scannedProducts, onDelete } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        paddingLeft: "10px",
        height: "700px",
        overflowY: "auto",
        backgroundColor: theme.palette.lightgrey.main,
        borderRadius: "10px",
      }}
    >
      {showScan ? (
        <Box sx={{ padding: "20px 0px", alignItems: "center" }}>
          <Typography
            style={{ fontSize: 20, fontWeight: 500, paddingLeft: "20px" }}
            sx={{ mb: 2 }}
          >
            Scanned Items
          </Typography>
          <Stack direction="column" sx={{ gap: 2 }}>
            {scannedProducts?.map((item) => (
              <ScannedItemsCard data={item} onDelete={onDelete} />
            ))}
          </Stack>
        </Box>
      ) : (
        <Box sx={{ padding: "25% 0%" }}>
          <EmptyItem
            empImage={empData}
            empText="No Scanned Items"
            btnIcon={
              <Scan size="15" color={theme.palette.white.main} variant="Bold" />
            }
            btnText="Start Scan"
            onClick={onSetShowScan}
            style={{ width: "350 !important", height: "280 !important" }}
          />
        </Box>
      )}
    </Box>
  );
}

export default PurchaseStartScan;
