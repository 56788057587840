import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { TickCircle } from "iconsax-react";
import { convertUtcToLocalTime } from "../../utilities/extensions";
import moment from "moment";

export const Status = (props) => {
  const { data } = props;

  const theme = useTheme();

  return (
    <Stack direction={"column"}>
      {data?.map((item, index) => (
        <Stack direction={"row"} sx={{ gap: 1 }}>
          <Stack direction={"column"} sx={{ mt: "2px" }}>
            <TickCircle
              size="22"
              color={
                theme.palette.statusActive.main
                // color={
                //   item.check
                //     ? theme.palette.statusActive.main
                //     : theme.palette.statusDeactive.main
              }
              variant="Bold"
            />
            {data.length - 1 !== index && (
              <Box
                width={22}
                height={50}
                justifyContent={"center"}
                display={"flex"}
                sx={{ mt: "2px" }}
              >
                <Divider
                  orientation="vertical"
                  sx={{
                    backgroundColor: theme.palette.statusActive.main,
                    // backgroundColor: item.check
                    //   ? theme.palette.statusActive.main
                    //   : theme.palette.statusDeactive.main,
                  }}
                />
              </Box>
            )}
          </Stack>
          <Stack direction={"column"}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: theme.palette.primary.main,
              }}
            >
              {item.order_status}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 12,
                color: theme.palette.tertiary.main,
              }}
            >
              {/* {convertUtcToLocalTime(item.createdAt, "DD MMM YYYY,  hh:mm a")} */}
              {moment(item.createdAt).format("DD MMM YYYY, hh:mm a")}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
