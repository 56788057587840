import { Stack, TextField, Typography, useTheme } from "@mui/material";
import { Status } from "../../components/status/Status";
import { purchaseDropdownMenu, sellDropdownMenu } from "../../utilities";
import { MenuMatchDropDown } from "../../components/dropdown/MenuMatchDropDown";
import { useState } from "react";

export const PurchaseSummaryStatus = (props) => {
  const { reload, data, type, onSelected } = props;

  const theme = useTheme();

  const [customStatus, setCustomStatus] = useState("");

  return (
    <Stack
      direction={"column"}
      sx={{
        pt: 2,
        pl: "12px",
        pr: "12px",
        pb: 2,
        bgcolor: theme.palette.menuHeader.main,
        borderRadius: 2,
        gap: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: 20,
          color: theme.palette.primary.main,
        }}
      >
        Status
      </Typography>
      <MenuMatchDropDown
        reload={reload}
        placeholderText={"Select"}
        dropdownMenu={type === 1 ? purchaseDropdownMenu : sellDropdownMenu}
        onSelected={onSelected}
      />
      <TextField
        placeholder="Type Custom Status for this Order"
        variant="standard"
        type="text"
        value={customStatus}
        onChange={(e) => setCustomStatus(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && customStatus.trim() !== "") {
            onSelected(customStatus);
            setCustomStatus("");
          }
        }}
        inputProps={{
          sx: {
            "&::placeholder": {
              color: theme.palette.placeholder.main,
              opacity: 1, // otherwise firefox shows a lighter color
            },
            fontSize: 16,
            fontWeight: 500,
            color: theme.palette.primary.main,
          },
        }}
        InputProps={{ disableUnderline: true }}
        sx={{
          pt: "12px",
          pl: 2,
          pr: 2,
          pb: "12px",
          bgcolor: theme.palette.search.main,
          borderRadius: 2,
        }}
      />
      <Status data={data} />
    </Stack>
  );
};
