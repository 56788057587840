import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Crown1 } from "iconsax-react";
import { RequestDetailRadio } from "../radio/RequestDetailRadio";
import PropTypes from "prop-types";

export const BuyerAccessCard = (props) => {
  const { refCode, status, onStatusChange, disableRadio } = props;
  const theme = useTheme();
  return (
    <Box
      sx={{
        bgcolor: theme.palette.buyerAccessBg.main,
        p: 2,
        borderRadius: 2,
      }}
    >
      <Stack direction={"column"}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 16,
            fontWeight: 600,
            pb: 1,
          }}
        >
          Buyer Access
        </Typography>
        <RequestDetailRadio
          userType={"Buyer"}
          status={status}
          onStatusChange={onStatusChange}
          disabled={disableRadio}
        />
        <Divider sx={{ mb: 2, mt: 1, mr: 1, ml: 1 }} />
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 16,
              fontWeight: 500,
              pb: 1,
            }}
          >
            Referral Code
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 16,
              fontWeight: 500,
              pb: 1,
            }}
          >
            {refCode ?? "N/A"}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

BuyerAccessCard.propTypes = {
  refCode: PropTypes.string,
  status: PropTypes.string,
  onStatusChange: PropTypes.func,
  disableRadio: PropTypes.bool,
};
